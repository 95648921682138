import { ButtonSize, ButtonVariant, Card, Icon, IconButton, IconNames, useTw } from '@mea-menu/components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'
import { SmartImage } from '../components/core/SmartImage'
import { useMediaStore } from '../store/mediaStore'
import { Recipe } from '../types'
import { translateLocal } from '../utils'

export interface RecipeRowProps {
  recipe: Recipe
  onPress?: () => void
  onPressIcon?: IconNames
  onEditPress?: () => void
}

export function RecipeRow({ recipe, onPress, onPressIcon, onEditPress }: RecipeRowProps) {
  const { tw } = useTw()
  const { fetchCoverImage } = useMediaStore()
  const { i18n } = useTranslation()

  const [coverImage, setCoverImage] = useState<string>()

  useEffect(() => {
    const getCoverImage = async () => {
      if (!recipe.coverId) {
        setCoverImage(undefined)
        return
      }

      const image = await fetchCoverImage(recipe.coverId)
      setCoverImage(image)
    }
    getCoverImage()
  }, [recipe])

  return (
    <Card
      style={tw`flex-row items-center p-xs mt-sm`}
      onPress={() => {
        onPress ? onPress() : onEditPress?.()
      }}
    >
      <SmartImage source={{ uri: coverImage }} style={tw`w-10 h-10 rounded-xs`} />
      <Text style={tw`textMono title4 ml-sm flex-1`}>{translateLocal(recipe, 'name', i18n.language)}</Text>
      {recipe.system && (
        <View style={tw`px-sm`}>
          <Icon name="MeaMenu" size={20} />
        </View>
      )}

      {onPress && (
        <IconButton
          size={ButtonSize.Small}
          icon={onPressIcon ?? IconNames.Info}
          variant={ButtonVariant.Accent}
          onPress={onPress}
        />
      )}
      <View style={tw`w-sm`} />
      {onEditPress && (
        <IconButton
          size={ButtonSize.Small}
          icon={IconNames.Edit}
          variant={ButtonVariant.Accent}
          onPress={onEditPress}
        />
      )}
    </Card>
  )
}
