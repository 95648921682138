import { Button, ButtonSize, ButtonVariant, useTw } from '@mea-menu/components'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView } from 'react-native'
import { Screen } from '../components'
import { RecipeEditorFragment } from '../fragments/RecipeEditorFragment'
import { RecipeStackScreenProps } from '../navigation'
import { goRecipeList } from '../navigation/Navigator'
import { useMenuStore, useNotificationsStore } from '../store'
import { useRecipeStore } from '../store/recipeStore'
import { MeaNotificationType, NotAllowedCode, Recipe } from '../types'
import { showToast } from '../utils'

export interface RecipeEditorScreenProps {
  recipeId?: string
}

export function RecipeEditorScreen({ navigation, route }: RecipeStackScreenProps<'RecipeEditorScreen'>) {
  const { recipeId } = route.params ?? {}
  const { tw } = useTw()
  const { t } = useTranslation()

  const { recipes, deleteRecipe: apiDeleteRecipe, forceDeleteRecipe: apiForceDeleteRecipe } = useRecipeStore()
  const { fetchDishes } = useMenuStore()
  const { setNotification } = useNotificationsStore()

  const recipe: Partial<Recipe> = useMemo(
    () => recipes.find(recipe => recipe._id === recipeId) ?? {},
    [recipes, recipeId]
  )
  const [currentRecipeId, setCurrentRecipeId] = useState<string | undefined>(recipeId)

  const deleteRecipe = useCallback(async () => {
    if (!currentRecipeId) return
    const forceDeleteRecipe = async () => {
      const isRecipeForceDeleted = await apiForceDeleteRecipe(currentRecipeId)
      if (isRecipeForceDeleted) {
        showToast(t('l.recipeDeleted'), 'SUCCESS')
        await fetchDishes()
        navigation.navigate('RecipesScreen')
      } else {
        showToast(t('l.failToDeleteRecipe'), 'ERROR')
      }
    }

    const isRecipeDeleted = await apiDeleteRecipe(currentRecipeId)
    if (isRecipeDeleted === true) {
      showToast(t('l.recipeDeleted'), 'SUCCESS')
      navigation.navigate('RecipesScreen')
    } else if (isRecipeDeleted === false) {
      showToast(t('l.failToDeleteRecipe'), 'ERROR')
    } else {
      const notAllowedCode = isRecipeDeleted
      if (notAllowedCode === NotAllowedCode.RECIPE_HAS_DISHES) {
        setNotification({
          title: t('l.warning'),
          description: t('l.recipeHasDishesMessage'),
          type: MeaNotificationType.CONFIRM,
          onConfirm: forceDeleteRecipe,
        })
      } else {
        showToast(t('l.failToDeleteRecipe'), 'ERROR')
      }
    }
  }, [currentRecipeId])

  return (
    <Screen
      noRestaurantName
      padded
      showBackButton
      showInputLanguageChooser={currentRecipeId != undefined}
      overrideBackButtonPress={() => goRecipeList(navigation)}
    >
      <ScrollView showsVerticalScrollIndicator={false}>
        <RecipeEditorFragment recipe={recipe} onRecipeSaved={recipe => setCurrentRecipeId(recipe._id)} />
        {currentRecipeId && (
          <Button
            style={tw`my-lg`}
            size={ButtonSize.Small}
            label={t('l.deleteRecipe')}
            onPress={() =>
              setNotification({
                cancelText: t('l.cancel'),
                confirmText: t('l.deleteRecipe'),
                title: t('l.deleteRecipe'),
                type: MeaNotificationType.CONFIRM,
                description: t('l.sureToDeleteRecipeItWillBeLostForEver'),
                onConfirm: deleteRecipe,
              })
            }
            variant={ButtonVariant.DangerLight}
          />
        )}
      </ScrollView>
    </Screen>
  )
}
