import axios from 'axios'
import { t } from 'i18next'
import { Platform } from 'react-native'
import { Browser as SentryBrowser } from 'sentry-expo'
import { showToast } from '.'
import { MeaError } from '../types'

/**
 * To be used for non-blocking errors (this will not render an ErrorPage)
 * @param error The Error to handle
 * @param displayToast Set to true to show a Toast with original message, or other values to show user-friendly messages (default: false)
 * @param fatal Set to true to reset data (default: false)
 * @param duration The message duration in ms (default 5000)
 */
export const meaErrorHandler = async (
  error: Error | any,
  displayToast: boolean | 'FETCH' | 'UPDATE' | 'GENERIC' = false,
  fatal: boolean = false,
  duration?: number
) => {
  if (!error) return
  if (Platform.OS === 'web') {
    SentryBrowser.captureException(error)
  }
  if (axios.isAxiosError(error) && error.code && error.config.url) {
    error.message = `${error.message} (${error.code} in ${error.config.url}).`
  }
  if (displayToast !== false) {
    let message: string
    if (displayToast === true) {
      if (error instanceof MeaError && !!error.localizedMessage) {
        message = t(error.localizedMessage)
      } else {
        message = error.message ?? t('errors.genericError')
      }
    } else {
      message = t(
        displayToast === 'FETCH'
          ? 'errors.errorFetchingData'
          : displayToast === 'UPDATE'
          ? 'errors.errorUpdatingData'
          : 'errors.genericError'
      )
    }
    showToast(message, 'ERROR', duration)
  }
  if (fatal) {
    localStorage.clear()
  }
}
