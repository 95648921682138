import { create } from 'zustand'
import { MeaNotification } from '../types'

type Notification = MeaNotification
interface NotificationsState {
  notification?: Notification
}

interface NotificationsStore extends NotificationsState {
  setNotification: (notification: Notification) => void
  consumeNotification: () => void
}

const initialState: NotificationsState = {
  notification: undefined,
}

export const useNotificationsStore = create<NotificationsStore>()((set, _) => ({
  ...initialState,
  setNotification: (notification: Notification) => set({ notification }),
  consumeNotification: () => set({ ...initialState }),
}))
