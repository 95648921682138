import { Button, ButtonSize, ButtonState, ButtonVariant, Card, IconButton, Toggle, useTw } from '@mea-menu/components'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, Image, ScrollView, Text, View } from 'react-native'
import { DishEntityService } from '../api/employee/DishEntityService'
import { LoadingIndicator } from '../components'
import { ImageInput } from '../components/core/ImageInput'
import { MeaNumberInput } from '../components/core/MeaNumberInput'
import { MeaTextInput } from '../components/core/MeaTextInput'
import { MeaTooltip } from '../components/core/tooltip/MeaTooltip'
import { SelectIngredientModal } from '../components/modals/SelectIngredientModal'
import { useMenuStore, useRestaurantStore, useSessionStore } from '../store'
import { useMediaStore } from '../store/mediaStore'
import { useRecipeStore } from '../store/recipeStore'
import { Dish, MeaMedia, Recipe, TooltipOrderManageMenuScreenTypes, TooltipOrderRecipeTypes } from '../types'
import { getIngredientAllergens, getLocalizedField } from '../types/utils'
import { showToast, translateLocal } from '../utils'
import { deepEquals } from '../utils/commonHelpers'
import { meaErrorHandler } from '../utils/MeaErrorHandler'

export type EditingDish = Omit<Partial<Dish>, 'recipe'> & { recipe: Partial<Recipe> }

export interface DishAndRecipeEditorFragmentProps {
  dish: EditingDish
  children?: React.ReactNode
  onDishAndRecipeSaved?: (dish: Dish) => void
}

const CAPTION_MAX_LENGTH = 40

export const DishAndRecipeEditorFragment = ({
  dish,
  children,
  onDishAndRecipeSaved,
}: DishAndRecipeEditorFragmentProps) => {
  const { t, i18n } = useTranslation()
  const { tw } = useTw()

  const { restaurant } = useRestaurantStore()
  const { menu, fetchDishes, updateDish } = useMenuStore()
  const { updateRecipe, fetchRecipes, createRecipe } = useRecipeStore()
  const { inputLanguage, setInputLanguage, getAllOtherInputLanguages } = useSessionStore()
  const { coverImageMap } = useMediaStore()

  const scrollViewRef = useRef<ScrollView>(null)

  const [loading, setLoading] = useState<boolean>(false)
  const [originalDish, setOriginalDish] = useState<EditingDish>(dish)
  const [editingDish, setEditingDish] = useState<EditingDish>(originalDish)
  const [isIngredientModalVisible, setIsIngredientModalVisible] = useState<boolean>(false)
  const [uploadedCover, setUploadedCover] = useState<MeaMedia>()

  const recipeName = translateLocal(originalDish.recipe, 'name', inputLanguage)
  const hasPendingEdits = useMemo(
    () => !dish._id || !deepEquals(editingDish, originalDish),
    [editingDish, originalDish]
  )

  const saveDish = async () => {
    if (!restaurant || !menu || !menu.dishCategories || !editingDish.dishCategory) return
    if (!editingDish.price) {
      showToast(t('errors.missingDishPrice'), 'ERROR')
      return
    }
    setLoading(true)
    try {
      const trimmedName = (translateLocal(editingDish.recipe, 'name', inputLanguage) ?? '').trim()
      const trimmedCaption = (translateLocal(editingDish, 'caption', inputLanguage, false) ?? '').trim()
      const trimmedDescription = (translateLocal(editingDish, 'description', inputLanguage, false) ?? '').trim()

      let recipeSaved: Recipe | boolean
      const savingRecipe: Partial<Recipe> = {
        [getLocalizedField('name', inputLanguage)]: trimmedName,
        coverId: uploadedCover?._id ?? editingDish.recipe.coverId ?? null,
        ingredientBrandMapping: undefined,
        ingredientsId: (editingDish.recipe.ingredients ?? []).map(ingredient => ingredient._id),
        parentId: editingDish.recipe.parentId,
      }

      if (!editingDish.recipe.system && editingDish.recipe._id) {
        recipeSaved = await updateRecipe(editingDish.recipe._id, savingRecipe)
      } else {
        recipeSaved = await createRecipe(savingRecipe, inputLanguage, getAllOtherInputLanguages())
      }

      if (!recipeSaved) return showToast(t('l.failToSaveRecipe'), 'ERROR')

      recipeSaved = recipeSaved === true ? (editingDish.recipe as Recipe) : recipeSaved

      let newDish: Partial<Dish> = {
        ...editingDish,
        [getLocalizedField('caption', inputLanguage)]: trimmedCaption,
        [getLocalizedField('description', inputLanguage)]: trimmedDescription,
        menuId: menu._id,
        recipeId: recipeSaved._id,
        recipe: recipeSaved,
        restaurantId: restaurant._id,
        suggested: editingDish.suggested ?? false,
      }

      if (editingDish._id) {
        newDish._id = editingDish._id
        newDish = await DishEntityService.update(newDish._id, newDish)
      } else {
        newDish = await DishEntityService.create(newDish)
        if (trimmedCaption.length > 0) {
          const translations = await DishEntityService.translate(
            (newDish as Dish)._id,
            getLocalizedField('caption', inputLanguage),
            getAllOtherInputLanguages()
          )
          newDish = { ...newDish, ...translations }
        }
        if (trimmedDescription.length > 0) {
          const translations = await DishEntityService.translate(
            (newDish as Dish)._id,
            getLocalizedField('description', inputLanguage),
            getAllOtherInputLanguages()
          )
          newDish = { ...newDish, ...translations }
        }
      }
      newDish.recipe = recipeSaved

      await fetchRecipes()
      await fetchDishes()
      setOriginalDish(newDish as EditingDish)
      setEditingDish(newDish as EditingDish)
      onDishAndRecipeSaved?.(newDish as Dish)
      showToast(
        `${translateLocal(menu.dishCategories[editingDish.dishCategory], 'name', inputLanguage)}: ${t(
          'l.dishSavedSuccesfully'
        )}`,
        'SUCCESS'
      )
    } catch (e) {
      meaErrorHandler(e, 'UPDATE')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <ScrollView ref={scrollViewRef} style={tw`p-sm pb-[80px]`}>
        <View style={tw`flex-row mb-sm items-bottom`}>
          <MeaTextInput
            label={t('l.recipeName')}
            placeHolder={t('l.recipeName')}
            showInputLocaleFlag
            initialValue={translateLocal(originalDish.recipe, 'name', inputLanguage)}
            onChangeText={text =>
              setEditingDish({
                ...editingDish,
                recipe: { ...editingDish.recipe, [getLocalizedField('name', inputLanguage)]: text },
              })
            }
            style={tw`flex-1`}
          />
        </View>
        <MeaTextInput
          label={t('l.dishDescription')}
          placeHolder={t('l.dishDescription')}
          showInputLocaleFlag
          initialValue={
            translateLocal(originalDish, 'description', inputLanguage, false) ??
            translateLocal(originalDish.recipe, 'description', inputLanguage, false)
          }
          onChangeText={text =>
            setEditingDish({ ...editingDish, [getLocalizedField('description', inputLanguage)]: text })
          }
          style={tw`mt-xs`}
          numberOfLines={6}
        />
        <View style={tw`flex-row mt-md`}>
          <MeaTextInput
            label={`${t('l.caption')} (${t('l.maxChars', { maxChars: CAPTION_MAX_LENGTH })})`}
            showInputLocaleFlag
            style={tw`flex-2`}
            placeHolder={t('l.shortDescriptionOfDish')}
            initialValue={translateLocal(originalDish, 'caption', inputLanguage, false)}
            maxTextSize={CAPTION_MAX_LENGTH}
            onChangeText={text =>
              setEditingDish({ ...editingDish, [getLocalizedField('caption', inputLanguage)]: text })
            }
          />
          <View style={tw`flex-1 ml-sm`}>
            <MeaNumberInput
              label={t('l.price')}
              placeHolder={t('l.price')}
              minValue={0}
              maxValue={10000}
              initialValue={originalDish.price}
              onChangeValue={value => setEditingDish({ ...editingDish, price: value })}
            />
            <Text style={tw`absolute bottom-3 right-3 textMono title4`}>€</Text>
          </View>
        </View>
        <View>
          {/* TODO this field must be visible only if configured e.g. AYCE it may confuse the user it in this form */}
          {/* <MeaTextInput
              style={tw`mt-md`}
              label={t('l.idNumber')}
              placeHolder={t('l.idNumber')}
              initialValue={dishNumber}
              onChangeText={setDishNumber}
            /> */}
          <View style={tw`mt-md flex-row items-center`}>
            <Text style={tw`textMono label mr-sm`}>{t('l.putDishInEvidence')}</Text>
            <MeaTooltip
              uniqueId={TooltipOrderManageMenuScreenTypes.DISH_IS_SUGGESTED}
              text={t('tooltips.suggestDish')}
              position="bottom"
              scrollViewRef={scrollViewRef}
              removeWidthWrapper
            >
              <Toggle
                active={editingDish.suggested ?? false}
                onSwitch={newValue => setEditingDish({ ...editingDish, suggested: newValue })}
              />
            </MeaTooltip>
          </View>
        </View>
        <View>
          <Text style={tw`textMono label mt-lg mb-sm`}>{t('l.image')}</Text>
          <MeaTooltip
            uniqueId={TooltipOrderRecipeTypes.RECIPE_ADD_IMAGE}
            text={t('tooltips.recipeImage')}
            position="top"
            scrollViewRef={scrollViewRef}
            removeWidthWrapper
          >
            <ImageInput
              currentImageUri={
                editingDish.recipe.coverId ? coverImageMap[editingDish.recipe.coverId] : uploadedCover?._id
              }
              modalTitle={recipeName}
              imageId={editingDish.recipe.coverId ?? uploadedCover?._id}
              saveImage={async image => {
                setEditingDish({ ...editingDish, recipe: { ...editingDish.recipe, coverId: image._id } })
                setUploadedCover(image)
              }}
              onImageRemoved={() => {
                setEditingDish({ ...editingDish, recipe: { ...editingDish.recipe, coverId: null } })
                setUploadedCover(undefined)
              }}
            />
          </MeaTooltip>
        </View>
        <>
          <View style={tw`flex-row justify-between items-center mt-md`}>
            <Text style={tw`textMono title3`}>{t('l.ingredients')}</Text>
            <MeaTooltip
              uniqueId={TooltipOrderRecipeTypes.RECIPE_ADD_INGREDIENT}
              text={t('tooltips.recipeIngredients')}
              position="top"
              scrollViewRef={scrollViewRef}
              removeWidthWrapper
            >
              <Button
                label={t('l.ingredient')}
                icon="Plus"
                size={ButtonSize.Small}
                onPress={() => {
                  setInputLanguage(i18n.language)
                  setIsIngredientModalVisible(true)
                }}
                variant={ButtonVariant.SecondaryLight}
              />
            </MeaTooltip>
          </View>
          <FlatList
            data={editingDish.recipe.ingredients}
            renderItem={({ item }) => {
              const ingredientAllergens = getIngredientAllergens(item.allergens)
              return (
                <Card style={tw`flex-row mt-xs h-lg items-center`}>
                  <Text style={tw`textMono title4`}>{translateLocal(item, 'name', inputLanguage)}</Text>
                  {ingredientAllergens.length > 0 && (
                    <View style={tw`flex-row ml-sm`}>
                      {ingredientAllergens.map(allergen => (
                        <View key={allergen._id} style={tw`mr-xs`}>
                          <Image source={allergen.imageUri} style={tw`w-md h-md`} />
                        </View>
                      ))}
                    </View>
                  )}
                  <View style={tw`flex-1 items-end ml-sm`}>
                    <IconButton
                      icon="Minus"
                      onPress={() =>
                        setEditingDish({
                          ...editingDish,
                          recipe: {
                            ...editingDish.recipe,
                            ingredients: [
                              ...(editingDish.recipe.ingredients ?? []).filter(
                                ingredient => ingredient._id !== item._id
                              ),
                            ],
                            ingredientsId: [
                              ...(editingDish.recipe.ingredientsId ?? []).filter(
                                ingredientId => ingredientId !== item._id
                              ),
                            ],
                          },
                        })
                      }
                      key={item._id}
                      size={ButtonSize.XSmall}
                      state={ButtonState.Error}
                    />
                  </View>
                </Card>
              )
            }}
            ListFooterComponent={<View style={tw`h-10`}></View>}
          />
          {children}
        </>
      </ScrollView>
      {hasPendingEdits && (
        <View style={tw`absolute w-full bottom-0 items-center py-sm fillBackground`}>
          <Button
            size={ButtonSize.Small}
            label={t('l.save')}
            onPress={saveDish}
            variant={ButtonVariant.Secondary}
            style={tw`flex-1 w-[94%]`}
          />
        </View>
      )}
      <SelectIngredientModal
        onSelect={ingredient => {
          setIsIngredientModalVisible(false)
          setEditingDish({
            ...editingDish,
            recipe: {
              ...editingDish.recipe,
              ingredients: [ingredient, ...(editingDish.recipe.ingredients ?? [])],
              ingredientsId: [ingredient._id, ...(editingDish.recipe.ingredientsId ?? [])],
            },
          })
        }}
        isVisible={isIngredientModalVisible}
        onClose={() => setIsIngredientModalVisible(false)}
      />
      <LoadingIndicator visible={loading} />
    </>
  )
}
