import { Button, ButtonSize, ButtonVariant, useTw } from '@mea-menu/components'
import { t } from 'i18next'
import React from 'react'
import { View } from 'react-native'

export interface WizardProps {
  children: React.ReactNode
  totalSteps: number
  currentStep: number
  showBack: boolean
  showNext: boolean
  onGoBack: () => void
  onGoNext: () => void
}

const Wizard = ({ children, totalSteps, currentStep, showBack, showNext, onGoBack, onGoNext }: WizardProps) => {
  const { tw } = useTw()

  // Calculate the width of the wizard line based on the current step
  const wizardLineWidth = `${(currentStep / (totalSteps - 1)) * 100}%`

  return (
    <>
      <View style={tw`mt-4 relative`}>
        <View style={tw`h-2 bg-orange-700 rounded overflow-hidden`}>
          <View style={{ ...tw`h-2 bg-orange-300 rounded-full`, width: wizardLineWidth }} />
          <View style={tw`absolute w-full flex-row justify-between px-2`}></View>
        </View>
      </View>
      <View style={tw`p-4`}>
        {children}

        <View style={tw`flex-row justify-between mt-4`}>
          {showBack && (
            <Button
              label={t('l.back')}
              variant={ButtonVariant.Secondary}
              onPress={onGoBack}
              size={ButtonSize.Small}
              style={tw`mt-lg flex-1 m-md`}
            />
          )}

          {showNext && (
            <Button
              label={t('l.next')}
              variant={ButtonVariant.Primary}
              onPress={onGoNext}
              size={ButtonSize.Small}
              style={tw`mt-lg flex-1 m-md`}
            />
          )}
        </View>
      </View>
    </>
  )
}

export default Wizard
