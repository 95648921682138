import { produce } from 'immer'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { DishEntityService } from '../api/employee/DishEntityService'
import { Dish, Menu } from '../types'
import { useRestaurantStore } from './restaurantStore'

interface MenuState {
  menu?: Menu
}

export interface MenuStore extends MenuState {
  setMenu: (menu: Menu) => void
  fetchDishes: () => Promise<Dish[]>
  setUnavailableRecipeIds: (recipeIds: string[]) => void
  updateDish: (dishId: string, update: Partial<Dish>) => Promise<Dish | false>
  clearStore: () => void
}

const initialState: MenuState = {}

export const useMenuStore = create<MenuStore>()(
  persist(
    (set, get) => ({
      ...initialState,
      setMenu: menu => set({ menu }),
      fetchDishes: async () => {
        const currentMenu = get().menu
        if (!currentMenu) return []
        const dishes = await DishEntityService.advancedSearch({
          equal: { and: [{ field: 'menuId', value: currentMenu._id }] },
          sort: [{ field: 'defaultSort', direction: 1 }],
        })
        set({ menu: { ...currentMenu, dishes } })
        return dishes
      },
      updateDish: async (dishId: string, update: Partial<Dish>) => {
        const { restaurant } = useRestaurantStore.getState()
        try {
          const updatedDish = await DishEntityService.update(dishId, {
            ...update,
            restaurantId: restaurant?._id,
            _id: dishId,
          })
          set(
            produce((state: MenuState) => {
              if (!state.menu) return
              const dishIndex = state.menu.dishes.findIndex(dish => dish._id === dishId)
              state.menu.dishes[dishIndex] = { ...state.menu.dishes[dishIndex], ...updatedDish }
            })
          )
          return updatedDish
        } catch (error) {
          console.error(error)
          return false
        }
      },
      setUnavailableRecipeIds(recipeIds) {
        set(
          produce((store: MenuState) => {
            if (!store.menu) return
            store.menu.unavailableRecipeIds = recipeIds
          })
        )
      },
      clearStore: () => set(initialState),
    }),
    {
      name: 'meamenumanager.menu',
      storage: createJSONStorage(() => localStorage),
    }
  )
)
