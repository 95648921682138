import { useCallback, useMemo } from 'react'
import { DishEntityService } from '../api/employee/DishEntityService'
import { RecipeEntityService } from '../api/employee/RecipeEntityService'
import { useMenuStore, useRestaurantStore, useSessionStore } from '../store'
import { useMediaStore } from '../store/mediaStore'
import { useRecipeStore } from '../store/recipeStore'
import { Dish } from '../types'
import { translateLocal } from '../utils'
import { getDishesFromDishesIds, INTOLERANCES } from '../utils/dishHelpers'
import { meaErrorHandler } from '../utils/MeaErrorHandler'

type useDishExp = {
  dishName: string
  dishDescription?: string
  dishCaption?: string
  recipeLikesAmount: number
  ingredientNames?: { name: string; allergens: boolean }[]
  dishPrice?: string
  intolerancesNames: { [value: number]: string }
  dishIsAvailable: boolean
  dishIsSuggested: boolean
  toggleDishAvailability: () => void
  getImageUrl: () => Promise<string | undefined>
}

export const useDish = (dish: Dish): useDishExp => {
  const { restaurant } = useRestaurantStore()
  const { menu, setUnavailableRecipeIds } = useMenuStore()
  const { fetchCoverImage } = useMediaStore()
  const { inputLanguage } = useSessionStore()
  const { recipes } = useRecipeStore()
  const dishName = useMemo(() => translateLocal(dish.recipe, 'name', inputLanguage), [dish, inputLanguage])
  const dishDescription = useMemo(
    () => translateLocal(dish, 'description', inputLanguage, false),
    [dish, inputLanguage]
  )
  const dishCaption = useMemo(() => translateLocal(dish, 'caption', inputLanguage, false), [dish, inputLanguage])
  const { ingredients, _id: menuId, dishes: menuDishes, unavailableRecipeIds, recipeLikesMap } = menu ?? {}
  const recipeLikesAmount = useMemo(() => recipeLikesMap?.[dish.recipeId] ?? 0, [recipeLikesMap])
  const dishPrice = !!dish.price ? dish.price.toFixed(2) + '€' : undefined

  /**
   * Returns an array of translated dish ingredients, each one with a flag
   * indicating if that ingredient has any allergens (to
   * show in bold to comply with labeling legislation)
   */
  const ingredientNames: { name: string; allergens: boolean }[] = useMemo(() => {
    if (!ingredients) return []
    return dish.recipe.ingredientsId.map(ingrId => {
      const ingredient = ingredients[ingrId]
      let name = ''
      if (!ingredient) return { name, allergens: false }
      name = translateLocal(ingredient, 'name', inputLanguage)
      const brandName = dish.recipe.ingredientBrandMapping?.[ingrId]
      if (brandName) {
        if (brandName.match(new RegExp(name, 'i'))) {
          name = brandName
        }
        name = `${name} ${brandName}`
      }
      return { name: name, allergens: ingredient.allergens > 0 }
    })
  }, [dish, inputLanguage])

  const intolerancesNames = INTOLERANCES.reduce<string[]>((acc, intolerance) => {
    const dishHasAllergens = ((dish.recipe.allergens ?? 0) & intolerance.value) !== 0
    if (dishHasAllergens) acc.push(translateLocal(intolerance, 'name', inputLanguage))
    return acc
  }, [])

  const getImageUrl = useCallback(async () => {
    const coverId = dish.recipe.coverId ?? recipes.find(recipe => recipe._id === dish.recipe._id)?.coverId
    if (!coverId) return undefined
    try {
      const coverUrl = await fetchCoverImage(coverId)
      return coverUrl
    } catch (e) {
      return undefined
    }
  }, [recipes])

  const dishIsAvailable = useMemo(
    () => !(unavailableRecipeIds?.includes(dish.recipeId) ?? false),
    [unavailableRecipeIds]
  )

  const dishIsSuggested = dish.suggested ?? false

  const toggleDishAvailability = useCallback(async () => {
    if (!menuId) return
    try {
      await RecipeEntityService.setAvailability(restaurant!._id, dish.recipeId, !dishIsAvailable)
      const notAvailableDishIdsNew = await DishEntityService.getNotAvailableByMenuId(menuId)
      setUnavailableRecipeIds(
        getDishesFromDishesIds(notAvailableDishIdsNew, menuDishes ?? []).map(dish => dish.recipeId)
      )
    } catch (error) {
      meaErrorHandler(error, 'UPDATE')
    }
  }, [dishIsAvailable])

  return {
    dishName,
    dishDescription,
    dishCaption,
    recipeLikesAmount,
    ingredientNames,
    dishPrice,
    intolerancesNames,
    dishIsAvailable,
    dishIsSuggested,
    toggleDishAvailability,
    getImageUrl,
  }
}
