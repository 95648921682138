import NetInfo from '@react-native-community/netinfo'
import { useFonts } from 'expo-font'
import { useEffect } from 'react'
import { Browser as SentryBrowser } from 'sentry-expo'
import { config } from '../../config'
import { useSessionStore, useUserStore } from '../../store'
import { SplashPage } from '../SplashPage'

export function AppLoader({ children }: { children: JSX.Element }) {
  const { appInitialized, setAppInitialized } = useSessionStore()
  const { validateSession } = useUserStore()

  const [fontsLoaded] = useFonts({
    'Circular Std Medium': require('../../../assets/fonts/CircularStd-Medium.ttf'),
    'CircularStd-Black': require('../../../assets/fonts/CircularStd-Black.otf'),
    'CircularStd-Book': require('../../../assets/fonts/CircularStd-Book.otf'),
    'CircularStd-Medium': require('../../../assets/fonts/CircularStd-Medium.otf'),
    'CircularStd-Bold': require('../../../assets/fonts/CircularStd-Bold.otf'),
    'CircularStd-Light': require('../../../assets/fonts/CircularStd-Light.otf'),
  })

  const setAppInitializedWithTimeout = (value: boolean, timeout?: number) => {
    setTimeout(() => setAppInitialized(value), __DEV__ ? 500 : timeout ?? 2500)
  }

  const initializeApp = async () => {
    //  this method is safe, it will not throw an error
    await validateSession()
    return setAppInitializedWithTimeout(true)
  }

  useEffect(() => {
    // App entry point
    if (config.environment !== 'prod') {
      console.log(config.environment)
      console.log(config.version)
    }
    initializeApp()

    const unsubscribeNetInfo = NetInfo.addEventListener(netInfoState => {
      if (config.environment !== 'prod') {
        console.log('connection', netInfoState)
      }
      SentryBrowser.setContext('connection', netInfoState)
    })

    return () => {
      unsubscribeNetInfo()
    }
  }, [])

  if (!appInitialized || !fontsLoaded) {
    return <SplashPage />
  }

  return children
}
