import { Button, ButtonSize, ButtonVariant, Card, colors, useTw } from '@mea-menu/components'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Linking, ScrollView, Text, View } from 'react-native'
import { EmployeeAuthService } from '../api/employee/EmployeeAuthService'
import { backendClient } from '../api/HTTPClient'
import { PasswordManagementModal, Screen } from '../components'
import { SmartImage } from '../components/core/SmartImage'
import { MeaTooltip } from '../components/core/tooltip/MeaTooltip'
import { EmailManagementModal } from '../components/modals/EmailManagementModal'
import { RestaurantStackScreenProps } from '../navigation'
import { goToLogin } from '../navigation/Navigator'
import { useRestaurantStore, useSessionStore, useUserStore } from '../store'
import { useMediaStore } from '../store/mediaStore'
import { TooltipOrderRestaurantTypes } from '../types'
import { reinitializeStores } from '../types/utils'
import { translateLocal } from '../utils'
import { meaErrorHandler } from '../utils/MeaErrorHandler'

export function RestaurantScreen({ navigation, route }: RestaurantStackScreenProps<'RestaurantScreen'>) {
  const { tw } = useTw()
  const { restaurant } = useRestaurantStore()
  const { t } = useTranslation()
  const { fetchCoverImage } = useMediaStore()
  const { inputLanguage } = useSessionStore()
  const { employee } = useUserStore()

  const [changeEmailModalOpen, setChangeEmailModalOpen] = useState<boolean>(false)
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState<boolean>(false)
  const [coverImage, setCoverImage] = useState<string>()

  const scrollViewRef = useRef<ScrollView>(null)

  const getCoverImage = async () => {
    if (restaurant && restaurant.coverId) {
      const image = await fetchCoverImage(restaurant.coverId!)
      setCoverImage(image)
    }
  }

  useEffect(() => {
    getCoverImage()
  }, [restaurant])

  const ResturantBaseCard = ({ children }: { children: React.ReactNode }) => {
    return <Card style={tw`flex-none mb-[10px]`}>{children}</Card>
  }

  const ResturantTitledCard = ({ children, title }: { children: React.ReactNode; title: string }) => {
    return (
      <ResturantBaseCard>
        <Text style={tw`textMono title3 mb-xs text-center`}>{title}</Text>
        <View style={tw`border-b-2 mb-md`} />
        {children}
      </ResturantBaseCard>
    )
  }

  const SocialField = ({ fieldName, fieldValue }: { fieldName: string; fieldValue?: string }) => {
    if (!restaurant) return null
    return (
      <Text style={tw`font-bold mb-md textMono title4`}>
        {`${fieldName}: `}
        {fieldValue ? (
          <Text
            style={{ color: colors.orange[400] }}
            onPress={() => Linking.openURL(fieldValue.startsWith('http') ? fieldValue : 'https://' + fieldValue)}
          >
            {t('l.tryProvidedLink')}
          </Text>
        ) : (
          t('l.linkNotProvided')
        )}
      </Text>
    )
  }

  return (
    <Screen padded>
      {restaurant && (
        <ScrollView ref={scrollViewRef} style={tw`p-md`} showsVerticalScrollIndicator={false}>
          <ResturantBaseCard>
            <SmartImage source={{ uri: coverImage }} style={tw`w-full h-64 `} />
          </ResturantBaseCard>
          <MeaTooltip
            scrollViewRef={scrollViewRef}
            uniqueId={TooltipOrderRestaurantTypes.RESTAURANT_INFO}
            text={t('tooltips.restaurantInfo')}
          >
            <ResturantTitledCard title={restaurant.name}>
              <Text style={tw`textMono title4`}>{translateLocal(restaurant, 'description', inputLanguage)}</Text>
            </ResturantTitledCard>
          </MeaTooltip>
          <MeaTooltip
            scrollViewRef={scrollViewRef}
            uniqueId={TooltipOrderRestaurantTypes.CONTACT_INFO}
            text={t('tooltips.contactInfo')}
          >
            <ResturantTitledCard title={t('l.contact-info')}>
              <Text style={tw`font-bold mb-md textMono title4`}>
                {t('l.address')}: {restaurant.address}
              </Text>
              <Text style={tw`font-bold mb-md textMono title4`}>
                {t('l.email')}: {restaurant.email}
              </Text>
              <Text style={tw`font-bold textMono title4`}>
                {t('l.phone')}: {restaurant.phone}
              </Text>
            </ResturantTitledCard>
          </MeaTooltip>
          <ResturantTitledCard title={t('l.restaurant-social')}>
            <SocialField fieldName={t('l.website')} fieldValue={restaurant.socialInfo?.websiteUrl} />
            <SocialField fieldName={t('l.tripAdvisorUrl')} fieldValue={restaurant.socialInfo?.tripAdvisorUrl} />
            <SocialField fieldName={t('l.instagramUrl')} fieldValue={restaurant.socialInfo?.instagramUrl} />
            <SocialField fieldName={t('l.facebookUrl')} fieldValue={restaurant.socialInfo?.facebookUrl} />
          </ResturantTitledCard>

          <ResturantTitledCard title={t('l.restaurant-settings')}>
            <Text style={tw`font-bold mb-md textMono title4`}>
              {t('l.status')}: {restaurant.status === 'active' ? '✅' : '❌'}
            </Text>
            <Text style={tw`font-bold mb-md textMono title4`}>
              {t('l.currency')}: {restaurant.priceCurrency}
            </Text>
            <Text style={tw`font-bold textMono title4`}>
              {t('l.coverPrice')}: {restaurant.coverPrice.toFixed(2)}
            </Text>
          </ResturantTitledCard>

          <Button
            size={ButtonSize.Small}
            label={t('l.edit')}
            onPress={() => navigation.navigate('RestaurantEditorScreen')}
            variant={ButtonVariant.SecondaryLight}
          />
          {employee && employee.email && (
            <View style={tw`mt-lg`}>
              <ResturantTitledCard title={t('l.account')}>
                <Button
                  size={ButtonSize.Small}
                  variant={ButtonVariant.DangerLight}
                  label={t('l.changeEmail')}
                  onPress={() => setChangeEmailModalOpen(true)}
                />
                <MeaTooltip
                  scrollViewRef={scrollViewRef}
                  uniqueId={TooltipOrderRestaurantTypes.CHANGE_PASSWORD}
                  text={t('tooltips.changePassword')}
                >
                  <Button
                    style={tw`mt-sm`}
                    size={ButtonSize.Small}
                    variant={ButtonVariant.DangerLight}
                    label={t('l.changePassword')}
                    onPress={() => setChangePasswordModalOpen(true)}
                  />
                </MeaTooltip>
              </ResturantTitledCard>
            </View>
          )}
        </ScrollView>
      )}
      {employee && employee.email && (
        <>
          <PasswordManagementModal
            visible={changePasswordModalOpen}
            email={employee.email}
            onClose={() => setChangePasswordModalOpen(false)}
            onPasswordChanged={() => goToLogin(navigation)}
          />
          <EmailManagementModal
            visible={changeEmailModalOpen}
            onClose={() => setChangeEmailModalOpen(false)}
            onEmailChanged={async () => {
              try {
                await EmployeeAuthService.logoutEmployee(
                  backendClient.defaults.headers.common['Authorization'] as string
                )
                reinitializeStores()
              } catch (e) {
                meaErrorHandler(e, true)
              }
            }}
          />
        </>
      )}
    </Screen>
  )
}
