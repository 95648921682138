import { colors, useTw } from '@mea-menu/components'
import { useEffect, useRef } from 'react'
import { Image, StyleProp, Text, TextInput, View, ViewStyle } from 'react-native'
import { useSessionStore, useThemeStore } from '../../store'
import { LANGUAGES } from '../../utils/commonHelpers'

export interface MeaTextInputProps {
  label?: string
  showInputLocaleFlag?: boolean
  placeHolder: string
  editable?: boolean
  initialValue?: string
  numberOfLines?: number
  autoFocus?: boolean
  maxTextSize?: number
  onChangeText: (text: string) => void
  onBlur?: () => void
  onFocus?: () => void
  style?: StyleProp<ViewStyle>
}

export const MeaTextInput = ({
  label,
  showInputLocaleFlag = false,
  placeHolder,
  editable = true,
  initialValue,
  numberOfLines,
  autoFocus,
  maxTextSize,
  style,
  onChangeText,
  onBlur,
  onFocus,
}: MeaTextInputProps) => {
  const { tw } = useTw()
  const { colorMode } = useThemeStore()
  const { inputLanguage } = useSessionStore()
  const textRef = useRef<TextInput>(null)

  useEffect(() => {
    if (initialValue) {
      textRef.current?.setNativeProps({ text: initialValue })
    }
  }, [initialValue])

  return (
    <View style={style}>
      {label && <Text style={tw`mb-xs textMono label`}>{label}</Text>}
      <View style={[tw`items-center p-xxs rounded-sm fillCard`]}>
        <TextInput
          ref={textRef}
          editable={editable}
          defaultValue={initialValue}
          autoFocus={autoFocus}
          onBlur={onBlur}
          onFocus={onFocus}
          numberOfLines={numberOfLines}
          // onSubmitEditing={onSumbit}
          style={[tw`textMono px-sm py-xs w-full`, { outlineStyle: 'none' }]}
          multiline={numberOfLines ? true : false}
          onChangeText={onChangeText}
          onKeyPress={e => {
            if (
              e.nativeEvent.key !== 'Backspace' &&
              maxTextSize !== undefined &&
              ((textRef.current as any).value ?? '').length > maxTextSize - 1
            )
              e.preventDefault()
          }}
          placeholder={placeHolder}
          placeholderTextColor={colorMode === 'light' ? colors.grey[300] : colors.grey[200]}
        />
        {showInputLocaleFlag && (
          <Image style={tw`absolute right-3 bottom-3 w-md h-md`} source={{ uri: LANGUAGES[inputLanguage].langFlag }} />
        )}
      </View>
    </View>
  )
}
