import { Button, ButtonSize, ButtonState, ButtonVariant, useTw } from '@mea-menu/components'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, Text, View } from 'react-native'
import { RestaurantEntityService } from '../api/employee/RestaurantEntityService'
import { Screen } from '../components'
import { ImageInput } from '../components/core/ImageInput'
import { MeaNumberInput } from '../components/core/MeaNumberInput'
import { MeaTextInput } from '../components/core/MeaTextInput'
import { RestaurantStackScreenProps } from '../navigation'
import { pop } from '../navigation/Navigator'
import { useNotificationsStore, useRestaurantStore, useSessionStore } from '../store'
import { useMediaStore } from '../store/mediaStore'
import { MeaMedia, MeaNotificationType, RestaurantCurrencies } from '../types'
import { getLocalizedField, getNextStringLiteralEnumValue } from '../types/utils'
import { showToast, translateLocal } from '../utils'
import { deepEquals } from '../utils/commonHelpers'

export function RestaurantEditorScreen({ navigation, route }: RestaurantStackScreenProps<'RestaurantEditorScreen'>) {
  const { tw } = useTw()
  const { t } = useTranslation()

  const { restaurant, fetchRestaurant, setRestaurant } = useRestaurantStore()
  const { fetchCoverImage } = useMediaStore()
  const { setNotification } = useNotificationsStore()
  const { inputLanguage } = useSessionStore()

  const [coverImage, setCoverImage] = useState<string>()
  const [editingRestaurant, setEditingRestaurant] = useState(restaurant)
  const hasPendingEdits = useMemo(() => !deepEquals(editingRestaurant, restaurant), [editingRestaurant, restaurant])

  const getCoverImage = async () => {
    if (restaurant && restaurant.coverId) {
      const image = await fetchCoverImage(restaurant.coverId)
      setCoverImage(image)
    }
  }

  useEffect(() => {
    getCoverImage()
  }, [restaurant])

  const handleSave = async (media?: MeaMedia) => {
    if (restaurant) {
      const savingRestaurant = {
        ...editingRestaurant,
        allergens: undefined,
        coverId: media ? media._id : editingRestaurant ? editingRestaurant.coverId : undefined,
      }

      try {
        await RestaurantEntityService.update(restaurant._id, savingRestaurant)
        const updatedRestaurant = await fetchRestaurant(restaurant._id)
        setEditingRestaurant(updatedRestaurant)
        showToast(t('l.restaurantSaved'), 'SUCCESS')
      } catch (error) {
        showToast(t('errors.errorSaveRestaurant'))
      }
    }
  }

  const getMenuStatusLabel = () => {
    let result = ''
    if (!editingRestaurant) return
    switch (editingRestaurant.priceCurrency) {
      case RestaurantCurrencies.EUR:
        result = 'EUR'
        break
      case RestaurantCurrencies.USD:
        result = 'USD'
        break
      case RestaurantCurrencies.GBP:
        result = 'GBP'
        break

      default:
        break
    }
    return result as any
  }

  const checkAndWarnPendingEdits = (callback: () => void) => {
    const doConfirm = () => {
      setEditingRestaurant(restaurant)
      callback()
    }

    if (hasPendingEdits) {
      setNotification({
        title: t('l.warning'),
        type: MeaNotificationType.CONFIRM,
        description: t('l.pendingEditsAskSureToCancel'),
        onConfirm: () => doConfirm(),
      })
    } else {
      doConfirm()
    }
  }

  if (!editingRestaurant) return null

  return (
    <Screen
      noRestaurantName
      padded
      showBackButton
      showInputLanguageChooser
      topBarChildren={
        hasPendingEdits && (
          <Button
            state={editingRestaurant.name.trim().length >= 3 ? ButtonState.Default : ButtonState.Disabled}
            size={ButtonSize.Small}
            label={t('l.save')}
            onPress={handleSave}
            variant={ButtonVariant.Secondary}
          />
        )
      }
      overrideBackButtonPress={() => checkAndWarnPendingEdits(() => pop(navigation))}
    >
      <ScrollView style={tw`p-md`} showsVerticalScrollIndicator={false}>
        {restaurant && editingRestaurant && (
          <View>
            <View style={tw`mb-md`}>
              <Text style={tw`textMono label mt-md mb-sm`}>{t('l.image')}</Text>
              <ImageInput
                currentImageUri={coverImage}
                modalTitle={t('l.image')}
                imageId={editingRestaurant.coverId}
                saveImage={handleSave}
                onImageRemoved={handleSave}
              />
            </View>
            <MeaTextInput
              label={t('l.name')}
              placeHolder={t('l.name')}
              initialValue={restaurant.name}
              onChangeText={newValue => setEditingRestaurant({ ...editingRestaurant, name: newValue })}
              style={tw`mb-md`}
            />
            <MeaTextInput
              label={t('l.description')}
              placeHolder={t('l.description')}
              showInputLocaleFlag
              initialValue={translateLocal(restaurant, 'description', inputLanguage)}
              numberOfLines={3}
              onChangeText={newValue =>
                setEditingRestaurant({
                  ...editingRestaurant,
                  [getLocalizedField('description', inputLanguage)]: newValue,
                })
              }
              style={tw`mb-md`}
            />
            <MeaTextInput
              label={t('l.phone')}
              placeHolder={t('l.phone')}
              initialValue={restaurant.phone}
              onChangeText={newValue => setEditingRestaurant({ ...editingRestaurant, phone: newValue })}
              style={tw`mb-md`}
            />
            <MeaTextInput
              label={t('l.address')}
              placeHolder={t('l.address')}
              initialValue={restaurant.address}
              onChangeText={newValue => setEditingRestaurant({ ...editingRestaurant, address: newValue })}
              style={tw`mb-md`}
            />
            <MeaTextInput
              label={t('l.website')}
              placeHolder={t('l.website')}
              initialValue={restaurant.socialInfo?.websiteUrl ?? ''}
              onChangeText={newValue =>
                setEditingRestaurant({
                  ...editingRestaurant,
                  socialInfo: { ...editingRestaurant.socialInfo, websiteUrl: newValue },
                })
              }
              style={tw`mb-md`}
            />
            <MeaTextInput
              label={t('l.tripAdvisorUrl')}
              placeHolder={t('l.tripAdvisorUrl')}
              initialValue={restaurant.socialInfo?.tripAdvisorUrl ?? ''}
              onChangeText={newValue =>
                setEditingRestaurant({
                  ...editingRestaurant,
                  socialInfo: { ...editingRestaurant.socialInfo, tripAdvisorUrl: newValue },
                })
              }
              style={tw`mb-md`}
            />
            <MeaTextInput
              label={t('l.instagramUrl')}
              placeHolder={t('l.instagramUrl')}
              initialValue={restaurant.socialInfo?.instagramUrl ?? ''}
              onChangeText={newValue =>
                setEditingRestaurant({
                  ...editingRestaurant,
                  socialInfo: { ...editingRestaurant.socialInfo, instagramUrl: newValue },
                })
              }
              style={tw`mb-md`}
            />{' '}
            <MeaTextInput
              label={t('l.facebookUrl')}
              placeHolder={t('l.facebookUrl')}
              initialValue={restaurant.socialInfo?.facebookUrl ?? ''}
              onChangeText={newValue =>
                setEditingRestaurant({
                  ...editingRestaurant,
                  socialInfo: { ...editingRestaurant.socialInfo, facebookUrl: newValue },
                })
              }
              style={tw`mb-md`}
            />
            <MeaNumberInput
              label={t('l.coverPrice')}
              placeHolder={t('l.coverPrice')}
              initialValue={restaurant.coverPrice}
              onChangeValue={newValue => setEditingRestaurant({ ...editingRestaurant, coverPrice: newValue })}
              style={tw`mb-md`}
            />
            <Button
              size={ButtonSize.Small}
              style={tw`max-w-[50%]`}
              label={`${t('l.currency')}: ${t(getMenuStatusLabel())}`}
              onPress={() => {
                const nextCurrency = getNextStringLiteralEnumValue<RestaurantCurrencies>(
                  RestaurantCurrencies,
                  editingRestaurant.priceCurrency as RestaurantCurrencies,
                  true
                )
                nextCurrency && setEditingRestaurant({ ...editingRestaurant, priceCurrency: nextCurrency })
              }}
            />
          </View>
        )}
      </ScrollView>
    </Screen>
  )
}
