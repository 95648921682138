import { Icon, useTw } from '@mea-menu/components'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'
import { useMenuStore, useSessionStore } from '../store'
import { specialDishesKeys } from '../types'
import { translateLocal } from '../utils'

export const CATEGORY_NAME_ONE_LINE_HEIGHT = 24
export const CATEGORY_NAME_TWO_LINES_HEIGHT = 64

export function CategoryName({
  categoryKey,
  catSubcatSeparator,
}: {
  categoryKey: string
  catSubcatSeparator: 'dash' | 'newLine'
}) {
  const { tw } = useTw()
  const { t, i18n } = useTranslation()
  const { menu } = useMenuStore()
  const { inputLanguage } = useSessionStore()

  if (!menu) return null
  const { dishCategories } = menu

  const MainText = ({ string }: { string: string }) => <Text style={tw`textMono title3`}>{string}</Text>
  const SecondaryText = ({ string }: { string: string }) => <Text style={tw`textMono label pt-[4px]`}>{string}</Text>

  const layout =
    catSubcatSeparator === 'dash'
      ? 'flex-row items-baseline'
      : catSubcatSeparator === 'newLine'
      ? 'flex-col items-center'
      : ''

  if (!dishCategories || !categoryKey) return null
  if (categoryKey.endsWith('.others')) {
    return (
      <View
        style={tw`h-[${
          catSubcatSeparator === 'newLine' ? CATEGORY_NAME_TWO_LINES_HEIGHT : CATEGORY_NAME_ONE_LINE_HEIGHT
        }px] ${layout}`}
      >
        <MainText string={`${t('l.otherDishes')}`} />
        <SecondaryText
          string={`${catSubcatSeparator === 'dash' ? ' - ' : ''}${translateLocal(
            dishCategories[categoryKey.split('.')[0]],
            'name',
            inputLanguage
          )}`}
        />
      </View>
    )
  } else if (specialDishesKeys.includes(categoryKey)) {
    return (
      <View style={tw`h-[${CATEGORY_NAME_ONE_LINE_HEIGHT}px]`}>
        <MainText string={i18n.t(`l.${categoryKey}`)} />
      </View>
    )
  } else if (categoryKey.includes('.')) {
    const parentKey = categoryKey.split('.')[0]
    return (
      <View style={tw`flex-1 flex-row justify-between`}>
        <View
          style={tw`h-[${
            catSubcatSeparator === 'newLine' ? CATEGORY_NAME_TWO_LINES_HEIGHT : CATEGORY_NAME_ONE_LINE_HEIGHT
          }px] ${layout}`}
        >
          <MainText string={`${translateLocal(dishCategories[categoryKey], 'name', inputLanguage)}`} />
          <SecondaryText
            string={`${catSubcatSeparator === 'dash' ? ' - ' : ''}${translateLocal(
              dishCategories[parentKey],
              'name',
              inputLanguage
            )}`}
          />
        </View>
        {!!translateLocal(dishCategories[categoryKey], 'description', inputLanguage) && <Icon name="Info" size={20} />}
      </View>
    )
  } else {
    return (
      <View style={tw`flex-row justify-between flex-1 h-[${CATEGORY_NAME_ONE_LINE_HEIGHT}px]`}>
        <MainText string={`${translateLocal(dishCategories[categoryKey], 'name', inputLanguage)}`} />
        {!!translateLocal(dishCategories[categoryKey], 'description', inputLanguage) && <Icon name="Info" size={20} />}
      </View>
    )
  }
}
