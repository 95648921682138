import { t } from 'i18next'
import { DishCategory, DishCategoryMap, MeaError } from '../../types'
import { backendClient, noResponse } from '../HTTPClient'
import { TranslatableEntityService } from '../TranslatableEntityService'

export class DishCategoryEntityService extends TranslatableEntityService<DishCategory>() {
  static entityPath: string = 'dish-category'

  static async getByMenuId(menuId: string): Promise<DishCategoryMap> {
    const response = await backendClient.get(`${this.entityPath}/getByMenu/${menuId}`)
    if (noResponse(response)) {
      throw new MeaError('errorFetchingData', t('errors.errorFetchingData'))
    }
    return response.data
  }

  static getByRestaurantId(restaurantId: string): Promise<DishCategory[]> {
    return this.advancedSearch({
      equal: {
        or: [],
        and: [{ field: 'restaurantId', value: restaurantId, caseSensitive: true }],
      },
      like: { or: [], and: [] },
      offset: 0,
      limit: 100,
      sort: [{ field: '_id', direction: 1 }],
    })
  }
}
