import { t } from 'i18next'
import { MeaError } from '../types'
import { BaseService } from './BaseService'
import { backendClient, noResponse } from './HTTPClient'

export function BaseEntityService<T>() {
  abstract class BaseEntityService extends BaseService() {
    static async getMany(): Promise<T[]> {
      const response = await backendClient.get(this.entityPath)
      if (noResponse(response)) {
        throw new MeaError('errorFetchingData', t('errors.errorFetchingData'))
      }
      return response.data
    }

    static async getById(id: string): Promise<T> {
      const response = await backendClient.get(`${this.entityPath}/${id}`)
      if (noResponse(response)) {
        throw new MeaError('errorFetchingData', t('errors.errorFetchingData'))
      }
      return response.data
    }

    static async create(item: Partial<T>): Promise<T> {
      const response = await backendClient.post(this.entityPath, item)
      if (noResponse(response)) {
        throw new MeaError('errorUpdatingData', t('errors.errorUpdatingData'))
      }
      return response.data
    }

    static async update(id: string, fieldsObj: any): Promise<T> {
      const response = await backendClient.put(`${this.entityPath}/${id}`, fieldsObj)
      if (noResponse(response)) {
        throw new MeaError('errorUpdatingData', t('errors.errorUpdatingData'))
      }
      return response.data
    }

    static async delete(id: string): Promise<T> {
      const response = await backendClient.delete(`${this.entityPath}/${id}`)
      if (noResponse(response)) {
        throw new MeaError('errorUpdatingData', t('errors.errorUpdatingData'))
      }
      return response.data
    }

    static async advancedSearch(filters: any): Promise<T[]> {
      const response = await backendClient.post(`${this.entityPath}/advancedsearch`, filters)
      if (noResponse(response)) {
        throw new MeaError('errorFetchingData', t('errors.errorFetchingData'))
      }
      return response.data
    }

    static async advancedCount(params: any): Promise<T[]> {
      const response = await backendClient.post(`${this.entityPath}/advancedcount`, params)
      if (noResponse(response)) {
        throw new MeaError('errorFetchingData', t('errors.errorFetchingData'))
      }
      return response.data
    }
  }

  return BaseEntityService
}
