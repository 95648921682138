import { matchesInAnyLanguage, translateLocal } from '.'
import { Images } from '../../assets'
import {
  Dish,
  DishCategory,
  DishCategoryMap,
  GroupedDishes,
  IngredientsMap,
  Intolerance,
  Menu,
  specialDishesKeys,
} from '../types'

export const INTOLERANCES: Intolerance[] = [
  /*
      {
        nameIt: "Carne",
        nameEn: "Meat",
        nameZh: "蛤蜊",
        value: 16384,
        imageUrl: Images.meat,
      },
      */
  /*  allergens order is following
        italian legislation
        "Allergeni e reg. 1169/11"
        https://www.onb.it/2014/12/22/allergeni-e-reg-116911/
     */
  {
    _id: '6245b3ad94890b7b54b9e3da',
    nameIt: 'Glutine',
    nameEn: 'Gluten',
    nameZh: '麸质',
    nameEs: 'Gluten',
    nameFr: 'Gluten',
    nameDe: 'Gluten',
    nameRu: 'Глютен',
    nameJa: 'グルテン',
    value: 4,
    imageUri: Images.gluten,
  },
  {
    _id: '6245b3b794890b7b54b9e3e0',
    nameIt: 'Crostacei',
    nameEn: 'Shellfish',
    nameZh: '贝类',
    nameEs: 'Mariscos',
    nameFr: 'Fruits de mer',
    nameDe: 'Krustentiere',
    nameRu: 'Морепродукты',
    nameJa: '甲殻類',
    value: 8,
    imageUri: Images.crab,
  },
  {
    _id: '6245b43594890b7b54b9e41c',
    nameIt: 'Uova',
    nameEn: 'Eggs',
    nameZh: '蛋',
    nameEs: 'Huevos',
    nameFr: 'Oeufs',
    nameDe: 'Eier',
    nameRu: 'Яйца',
    nameJa: '卵',
    value: 16,
    imageUri: Images.egg,
  },
  {
    _id: '6245b44194890b7b54b9e422',
    nameIt: 'Pesce',
    nameEn: 'Fish',
    nameZh: '鱼',
    nameEs: 'Pescado',
    nameFr: 'Poisson',
    nameDe: 'Fisch',
    nameRu: 'Рыба',
    nameJa: '魚',
    value: 32,
    imageUri: Images.fish,
  },
  {
    _id: '6245b39e94890b7b54b9e3d4',
    nameIt: 'Arachidi',
    nameEn: 'Peanuts',
    nameZh: '花生',
    nameEs: 'Cacahuetes',
    nameFr: 'Arachides',
    nameDe: 'Erdnüsse',
    nameRu: 'Арахис',
    nameJa: 'ピーナッツ',
    value: 2,
    imageUri: Images.peanut,
  },
  {
    _id: '6245b44a94890b7b54b9e428',
    nameIt: 'Soia',
    nameEn: 'Soy',
    nameZh: '大豆',
    nameEs: 'Soja',
    nameFr: 'Soja',
    nameDe: 'Soja',
    nameRu: 'Соя',
    nameJa: '大豆',
    value: 64,
    imageUri: Images.soybean,
  },
  {
    _id: '6245b38094890b7b54b9e3cb',
    nameIt: 'Latticini',
    nameEn: 'Dairy products',
    nameZh: '奶制品',
    nameEs: 'Productos lácteos',
    nameFr: 'Produits laitiers',
    nameDe: 'Milchprodukte',
    nameRu: 'Молочные продукты',
    nameJa: '乳製品',
    value: 1,
    imageUri: Images.milk,
  },
  {
    _id: '6245b45794890b7b54b9e42e',
    nameIt: 'Frutta a guscio',
    nameEn: 'Nuts',
    nameZh: '坚果',
    nameEs: 'Frutos secos',
    nameFr: 'Noix',
    nameDe: 'Nüsse',
    nameRu: 'Орехи',
    nameJa: 'ナッツ',
    value: 128,
    imageUri: Images.nuts,
  },
  {
    _id: '6245b45f94890b7b54b9e434',
    nameIt: 'Sedano',
    nameEn: 'Celery',
    nameZh: '芹菜',
    nameEs: 'Apio',
    nameFr: 'Céleri',
    nameDe: 'Sellerie',
    nameRu: 'Сельдерей',
    nameJa: 'セロリ',
    value: 256,
    imageUri: Images.celery,
  },
  {
    _id: '6245b46c94890b7b54b9e43a',
    nameIt: 'Senape',
    nameEn: 'Mustard',
    nameZh: '芥末',
    nameEs: 'Mostaza',
    nameFr: 'Moutarde',
    nameDe: 'Senf',
    nameRu: 'Горчица',
    nameJa: 'マスタード',
    value: 512,
    imageUri: Images.mustard,
  },
  {
    _id: '6245b47594890b7b54b9e440',
    nameIt: 'Sesamo',
    nameEn: 'Sesame',
    nameZh: '芝麻',
    nameEs: 'Sésamo',
    nameFr: 'Sésame',
    nameDe: 'Sesam',
    nameRu: 'Кунжут',
    nameJa: 'ごま',
    value: 1024,
    imageUri: Images.sesame,
  },
  {
    _id: '6245b47d94890b7b54b9e446',
    nameIt: 'Solfiti',
    nameEn: 'Sulphites',
    nameZh: '亚硫酸盐',
    nameEs: 'Sulfitos',
    nameFr: 'Sulfites',
    nameDe: 'Sulphite',
    nameRu: 'Сульфиты',
    nameJa: '亜硫酸塩',
    value: 2048,
    imageUri: Images.sulfide,
  },
  {
    _id: '6245b48b94890b7b54b9e44c',
    nameIt: 'Lupini',
    nameEn: 'Lupins',
    nameZh: '羽扇豆',
    nameEs: 'Altramuces',
    nameFr: 'Lupins',
    nameDe: 'Lupinen',
    nameRu: 'Люпин',
    nameJa: 'ルピナス',
    value: 4096,
    imageUri: Images.lupin,
  },
  {
    _id: '6245b49594890b7b54b9e452',
    nameIt: 'Molluschi',
    nameEn: 'Clams',
    nameZh: '蛤蜊',
    nameEs: 'Almejas',
    nameFr: 'Palourdes',
    nameDe: 'Muscheln',
    nameRu: 'Моллюски',
    nameJa: '貝類',
    value: 8192,
    imageUri: Images.clams,
  },
]

export const getTranslatedCategoryName = (dishCategory: DishCategory, language: string) => {
  return translateLocal(dishCategory, 'name', language)
}

const doesDishRespectFilter = (dish: Dish, filter: string, ingredients: IngredientsMap) => {
  if (matchesInAnyLanguage(dish, 'name', filter)) return true
  if (dish.recipe.ingredientsId.map(id => ingredients[id]).some(i => matchesInAnyLanguage(i, 'name', filter)))
    return true
  return false
}

export const getFilteredDishes = (
  dishCategories: DishCategoryMap,
  ingredients: IngredientsMap,
  groupedDishes: GroupedDishes | undefined,
  filter: string
): GroupedDishes => {
  let filtered: GroupedDishes = {}
  if (!groupedDishes) return filtered
  if (filter.length === 0) return groupedDishes

  const matchDish = (dishes: Dish[], categoryKey: string) => {
    const filteredDishes = dishes.filter(d => doesDishRespectFilter(d, filter, ingredients))
    if (filteredDishes.length > 0) filtered[categoryKey] = filteredDishes
  }

  Object.entries(groupedDishes).forEach(group => {
    const categoryKey = group[0]
    if (specialDishesKeys.includes(categoryKey)) {
      matchDish(group[1], categoryKey)
    } else {
      const category = dishCategories[categoryKey]
      if (matchesInAnyLanguage(category, 'name', filter.trim())) {
        // if name of category matches we return it entirely
        filtered[categoryKey] = group[1]
      } else {
        // otherwise check dishes names and ingredients
        matchDish(group[1], categoryKey)
      }
    }
  })
  return filtered
}

export const groupDishes = (dishes: Dish[]): GroupedDishes => {
  const groupedDishes: GroupedDishes = {}
  dishes.forEach(d => {
    const dishCat: string = d.dishSubCategory ?? d.dishCategory
    if (!groupedDishes[dishCat]) {
      groupedDishes[dishCat] = [d]
    } else {
      if (!groupedDishes[dishCat].map(dish => dish.recipeId).includes(d.recipeId)) {
        groupedDishes[dishCat].push(d)
      }
    }
  })
  return groupedDishes
}

export function sortDishesById(dishesToBeSorted: Dish[], idsToSortTo: string[]) {
  return dishesToBeSorted.sort((a, b) => {
    const indexA = idsToSortTo.indexOf(a.recipeId)
    const indexB = idsToSortTo.indexOf(b.recipeId)
    return indexA - indexB
  })
}

export function getDishesFromRecipesId(recipesIds: string[], menuDishes: Dish[]): Dish[] {
  return sortDishesById(
    menuDishes.filter(d => recipesIds.includes(d.recipeId)),
    recipesIds
  )
}

export function getDishesFromDishesIds(dishesIds: string[], menuDishes: Dish[]): Dish[] {
  return dishesIds.map(dishId => menuDishes.find(dish => dish._id === dishId)).filter(Boolean) as Dish[]
}

export function getRecipeIdFromDishId(dishId: string, selectedMenu: Menu): string | undefined {
  return selectedMenu.dishes.find(d => d._id === dishId)?.recipeId
}
