import { colors } from '@mea-menu/components'
import Toast from 'react-native-root-toast'
import { AppFeature } from '../types'

export const translateLocal = (entity: any, key: string, language: string, enableFallback: boolean = true): string => {
  if (!entity) return ''
  let _lan = language[0].toUpperCase() + language[1]
  if (entity[key + _lan]) {
    return entity[key + _lan]
  }
  return enableFallback ? entity[key + 'En'] ?? entity[key + 'It'] : ''
}

export const matchesInAnyLanguage = (
  entity: any,
  key: string,
  search: string,
  caseSensitive = false,
  fullWord = false
): boolean => {
  const languagesToMatch = ['En', 'It', 'Zh']
  for (const lang of languagesToMatch) {
    if (entity[key + lang]) {
      let val = entity[key + lang]
      if (typeof val === 'string') {
        val = caseSensitive ? val : val.toLowerCase()
        const filter = caseSensitive ? search : search.toLowerCase()
        if ((fullWord && val === filter) || (!fullWord && val.includes(filter))) return true
      }
    }
  }
  return false
}

export const appFeatureIsAvailable = (appFeature: AppFeature, appFeatures: number) => {
  return (appFeature & appFeatures) !== 0
}

export const showToast = (
  message: string,
  type: 'ERROR' | 'SUCCESS' = 'ERROR',
  duration?: number,
  position?: number
) => {
  Toast.show(message, {
    duration: duration ?? 3000,
    position: position ?? Toast.positions.TOP,
    shadow: true,
    animation: true,
    hideOnPress: true,
    opacity: 1,
    backgroundColor: type === 'SUCCESS' ? colors.teal[400] : colors.red[400],
    delay: 0,
  })
}
