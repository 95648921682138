import { t } from 'i18next'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { EmployeeAuthService } from '../api/employee/EmployeeAuthService'
import { EmployeeEntityService } from '../api/employee/EmployeeEntityService'
import { setBackendToken } from '../api/HTTPClient'
import { Employee, EmployeeLogin, MeaError } from '../types'

export interface UserState {
  accessToken?: string
  refreshToken?: string
  employee?: Employee
  isSessionValid: boolean
}

export interface UserStore extends UserState {
  employeeLogin: (email: string, password: string) => Promise<Employee>
  validateSession: () => Promise<boolean>
  setSessionValidity: (valid: boolean) => void
  refreshEmployeeToken: () => Promise<EmployeeLogin>
  getTokens: () => { accessToken: string | undefined; refreshToken: string | undefined }
  getEmployee: () => Employee | undefined
  clearStore: () => void
}

async function isValidEmployee(employeeLogin: EmployeeLogin): Promise<boolean> {
  try {
    await EmployeeEntityService.validateEmployee(employeeLogin.employee._id, employeeLogin.accessToken)
    return true
  } catch (error) {
    return false
  }
}

const initialState: UserState = {
  accessToken: undefined,
  employee: undefined,
  refreshToken: undefined,
  isSessionValid: false,
}

export const useUserStore = create<UserStore>()(
  persist(
    (set, get) => ({
      ...initialState,

      employeeLogin: async (email, password) => {
        const { accessToken, employee, refreshToken } = await EmployeeAuthService.loginEmployee(email, password)
        await setBackendToken(accessToken)
        set({ accessToken, employee, refreshToken, isSessionValid: true })
        return employee
      },

      validateSession: async () => {
        const { accessToken, employee, refreshToken, refreshEmployeeToken } = get()
        if (!accessToken || !employee || !refreshToken) return false

        const isValid = await isValidEmployee({ accessToken, employee, refreshToken })
        if (isValid) return true

        // else try to refresh token
        try {
          await refreshEmployeeToken()
          set({ isSessionValid: true })
          return true
        } catch (error) {
          return false
        }
      },
      setSessionValidity: (validity: boolean) => {
        set({ isSessionValid: validity })
      },
      refreshEmployeeToken: async () => {
        const { accessToken, employee, refreshToken } = get()
        if (!accessToken || !employee || !refreshToken)
          throw new MeaError('cannotRenewToken', t('errors.cannotRenewToken'))
        const newEmployeeLogin = await EmployeeAuthService.refreshEmployeeToken({
          accessToken,
          employee,
          refreshToken,
        })
        set({ ...newEmployeeLogin, isSessionValid: true })
        await setBackendToken(newEmployeeLogin.accessToken)
        return newEmployeeLogin
      },

      getTokens() {
        const { accessToken, refreshToken } = get()
        return { accessToken, refreshToken }
      },

      getEmployee() {
        const { employee } = get()
        return employee
      },

      clearStore() {
        set(initialState)
      },
    }),
    {
      name: 'meamenumanager.user',
      storage: createJSONStorage(() => localStorage),
    }
  )
)
