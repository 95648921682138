import { Button, ButtonSize, ButtonState, ButtonVariant, IconButton, Toggle, useTw } from '@mea-menu/components'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, Text, View } from 'react-native'
import { MenuEntityService } from '../api/employee/MenuEntityService'
import { AddTimeSliceModal, Screen } from '../components'
import { MeaTextInput } from '../components/core/MeaTextInput'
import { MeaTooltip } from '../components/core/tooltip/MeaTooltip'
import { MenuStackScreenProps } from '../navigation'
import { pop } from '../navigation/Navigator'
import { useMenuStore, useNotificationsStore, useRestaurantStore, useSessionStore } from '../store'
import { MeaNotificationType, Menu, MenuStatus, TimeSlice, TooltipOrderMenuTypes } from '../types'
import { getLocalizedField } from '../types/utils'
import { showToast, translateLocal } from '../utils'
import { deepEquals } from '../utils/commonHelpers'
import { meaErrorHandler } from '../utils/MeaErrorHandler'

export interface MenuScreenProps {
  menuId?: string
}

export function MenuScreen({ navigation, route }: MenuStackScreenProps<'MenuScreen'>) {
  const { menuId } = route.params ?? {}

  const { tw } = useTw()
  const { t, i18n } = useTranslation()

  const { menu, setMenu } = useMenuStore()
  const { setNotification } = useNotificationsStore()
  const { restaurant } = useRestaurantStore()
  const { inputLanguage, getAllOtherInputLanguages } = useSessionStore()

  const [editingMenu, setEditingMenu] = useState<Partial<Menu>>()
  const [addingTimeSlice, setAddingTimeSlice] = useState<TimeSlice>()
  const hasPendingEdits = useMemo(
    () =>
      !deepEquals(
        {
          ...editingMenu,
          ingredients: undefined,
          dishes: undefined,
          unavailableRecipeIds: undefined,
          dishCategories: undefined,
        },
        {
          ...menu,
          ingredients: undefined,
          dishes: undefined,
          unavailableRecipeIds: undefined,
          dishCategories: undefined,
        }
      ),
    [editingMenu, menu]
  )

  const MENU_NAME_MIN_LENGHT = 3

  useEffect(() => {
    if (!restaurant) return
    if (!menuId) {
      const initialMenu: Partial<Menu> = {
        akinatorEnabled: false,
        allDay: true,
        allYouCanEat: false,
        highlight: false,
        [getLocalizedField('name', inputLanguage)]: '',
        [getLocalizedField('description', inputLanguage)]: '',
        restaurantId: restaurant._id,
        showInPriceList: true,
        timeSlices: [],
        userCanOrder: true,
      }
      setMenu(initialMenu as Menu)
      setEditingMenu(initialMenu)
    } else {
      setEditingMenu(menu)
    }
  }, [])

  const formatMinutes = (minutes: number) =>
    minutes.toLocaleString(i18n.language, {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })

  const getMenuStatusLabel = () => {
    if (!editingMenu) return ''
    let result = ''
    switch (editingMenu.status) {
      case MenuStatus.ACTIVE:
        result = t('l.statusActive')
        break
      case MenuStatus.DRAFT:
        result = t('l.statusDraft')
        break
      case MenuStatus.HIDDEN:
        result = t('l.statusHidden')
        break
    }
    return result as any
  }

  const getMenuStatusIcon = () => {
    if (!editingMenu) return ''
    let result = ''
    switch (editingMenu.status) {
      case MenuStatus.ACTIVE:
        result = '✅'
        break
      case MenuStatus.DRAFT:
        result = '✎'
        break
      case MenuStatus.HIDDEN:
        result = '❌'
        break
    }
    return result as any
  }

  const TimeSlotRow = ({ timeSlice }: { timeSlice: TimeSlice }) => {
    const timeLabel = `${timeSlice.fromHour}:${formatMinutes(timeSlice.fromMin)} -> ${timeSlice.toHour}:${formatMinutes(
      timeSlice.toMin
    )}`
    if (!editingMenu) return null
    return (
      <View style={tw`flex-row py-xs items-center border-b-[1px] strokeMono`}>
        <Text style={tw`textMono`}>{timeLabel}</Text>
        <View style={tw`ml-sm`}>
          <IconButton
            icon={'Minus'}
            size={ButtonSize.XSmall}
            state={ButtonState.Error}
            onPress={() => {
              if (editingMenu.timeSlices?.length === 1) {
                setNotification({
                  type: MeaNotificationType.ALERT,
                  title: t('l.warning'),
                  description: t('errors.cannotDeleteLastTimeSliceUnlessEnableAlwaysAvailable'),
                })
              } else {
                setNotification({
                  title: t('l.confirm'),
                  type: MeaNotificationType.CONFIRM,
                  description: `${t('l.sureToDeleteThisTimeSlot')}\n${timeLabel}`,
                  onConfirm: () => {
                    const indexOfTimeSlice = (editingMenu.timeSlices ?? []).findIndex(
                      ts =>
                        ts.fromHour === timeSlice.fromHour &&
                        ts.fromMin === timeSlice.fromMin &&
                        ts.toHour === timeSlice.toHour &&
                        ts.toMin === timeSlice.toMin
                    )
                    if (indexOfTimeSlice !== -1) {
                      setEditingMenu({
                        ...editingMenu,
                        timeSlices: editingMenu.timeSlices?.toSpliced(indexOfTimeSlice, 1),
                      })
                    }
                  },
                })
              }
            }}
          />
        </View>
      </View>
    )
  }

  const LabeledToggleRow = ({
    label,
    active,
    onActiveChanged,
  }: {
    label: string
    active: boolean
    onActiveChanged: (active: boolean) => void
  }) => {
    return (
      <View style={tw`flex-row justify-between mt-md`}>
        <Text style={tw`title4 textMono`}>{label}</Text>
        <Toggle
          active={active}
          onSwitch={newActiveValue => {
            onActiveChanged(newActiveValue)
          }}
        />
      </View>
    )
  }

  const SettingsView = () => {
    if (!editingMenu) return null
    return (
      <View>
        <Text style={tw`title3 mt-xl textMono`}>{t('l.settings')}</Text>
        <LabeledToggleRow
          active={editingMenu.userCanOrder ?? true}
          label={t('l.allowOrdering')}
          onActiveChanged={active => {
            if (editingMenu) setEditingMenu({ ...editingMenu, userCanOrder: active })
          }}
        />
        <LabeledToggleRow
          active={editingMenu.showInPriceList ?? true}
          label={t('l.showInPriceList')}
          onActiveChanged={active => {
            if (editingMenu) setEditingMenu({ ...editingMenu, showInPriceList: active })
          }}
        />
        <MeaTooltip
          uniqueId={TooltipOrderMenuTypes.MENU_ALWAYS_AVAILABLE}
          text={t('tooltips.alwaysAvailableMenu')}
          position="top"
        >
          <LabeledToggleRow
            active={editingMenu.allDay ?? true}
            label={t('l.alwaysAvailable')}
            onActiveChanged={active => {
              if (editingMenu) setEditingMenu({ ...editingMenu, allDay: active })
            }}
          />
        </MeaTooltip>
      </View>
    )
  }

  const TimeSlotsView = () => {
    if (!editingMenu) return null
    return (
      <View>
        {!editingMenu.allDay && (
          <View>
            <View style={tw`mt-xl mb-sm flex-row items-center`}>
              <Text style={tw`font-bold textMono mr-sm`}>{t('l.timeSlots')}</Text>
              {editingMenu && (
                <IconButton
                  size={ButtonSize.Small}
                  variant={ButtonVariant.Secondary}
                  onPress={() => {
                    const nowHours = new Date().getHours()
                    setAddingTimeSlice({
                      fromHour: nowHours,
                      fromMin: 0,
                      toHour: nowHours + 6 > 23 ? 0 : nowHours + 6,
                      toMin: 0,
                    })
                  }}
                  icon={'Plus'}
                />
              )}
            </View>
            {editingMenu.timeSlices &&
              editingMenu.timeSlices.length > 0 &&
              editingMenu.timeSlices.map((timeSlice, index) => <TimeSlotRow timeSlice={timeSlice} key={index} />)}
          </View>
        )}
      </View>
    )
  }

  const saveEdits = async () => {
    if (!editingMenu) return
    let newMenu: Menu | undefined = undefined
    try {
      editingMenu.dishes = undefined
      editingMenu.ingredients = undefined
      if (editingMenu._id) {
        newMenu = await MenuEntityService.update(editingMenu._id!, editingMenu)
      } else {
        newMenu = await MenuEntityService.create(editingMenu)
        const translations = await MenuEntityService.translate(
          newMenu._id,
          getLocalizedField('name', inputLanguage),
          getAllOtherInputLanguages()
        )
        newMenu = { ...newMenu, ...translations }
        const description: string | undefined = (editingMenu as any)[getLocalizedField('description', inputLanguage)]
        if (description && description.trim().length > 0) {
          const translations = await MenuEntityService.translate(
            newMenu._id,
            getLocalizedField('description', inputLanguage),
            getAllOtherInputLanguages()
          )
          newMenu = { ...newMenu, ...translations }
        }
      }
      setMenu(newMenu)
      setEditingMenu(newMenu)
      showToast(t('l.menuSaved'), 'SUCCESS')
    } catch (e) {
      meaErrorHandler(e, 'UPDATE')
    }
  }

  const putAddingTimeSliceToTempEditingMenu = () => {
    if (!editingMenu || !addingTimeSlice) return
    setAddingTimeSlice(undefined)
    setEditingMenu({ ...editingMenu, timeSlices: [addingTimeSlice, ...(editingMenu.timeSlices ?? [])] })
  }

  const checkAndWarnPendingEdits = (callback: () => void) => {
    const doConfirm = () => {
      setEditingMenu(menu)
      callback()
    }

    if (hasPendingEdits) {
      setNotification({
        title: t('l.warning'),
        type: MeaNotificationType.CONFIRM,
        description: t('l.pendingEditsAskSureToCancel'),
        onConfirm: () => doConfirm(),
      })
    } else {
      doConfirm()
    }
  }

  if (!editingMenu) return null
  return (
    <Screen
      padded
      showInputLanguageChooser={!!editingMenu._id}
      noRestaurantName
      showBackButton
      topBarChildren={
        hasPendingEdits && (
          <Button
            onPress={saveEdits}
            label={t('l.save')}
            state={
              (editingMenu as any)[getLocalizedField('name', inputLanguage)] &&
              (editingMenu as any)[getLocalizedField('name', inputLanguage)].trim().length >= MENU_NAME_MIN_LENGHT
                ? ButtonState.Default
                : ButtonState.Disabled
            }
            variant={ButtonVariant.Secondary}
            size={ButtonSize.Small}
          />
        )
      }
      overrideBackButtonPress={() => checkAndWarnPendingEdits(() => pop(navigation))}
    >
      <ScrollView style={tw`p-md`} showsVerticalScrollIndicator={false}>
        <View style={tw`flex-row justify-between items-end mb-md`}>
          <MeaTextInput
            style={tw`flex-1`}
            label={t('l.name')}
            placeHolder={t('l.name')}
            showInputLocaleFlag
            initialValue={translateLocal(menu, 'name', inputLanguage)}
            onChangeText={text => {
              setEditingMenu({ ...editingMenu, [getLocalizedField('name', inputLanguage)]: text })
            }}
          />
        </View>
        <MeaTextInput
          label={t('l.description')}
          style={tw`mb-md`}
          showInputLocaleFlag
          placeHolder={t('l.description')}
          initialValue={translateLocal(menu, 'description', inputLanguage, false)}
          onChangeText={text => {
            setEditingMenu({ ...editingMenu, [getLocalizedField('description', inputLanguage)]: text })
          }}
        />
        <SettingsView />
        <TimeSlotsView />
        {!!editingMenu._id && (
          <View style={tw`mt-lg p-sm w-full justify-end`}>
            <Button
              label={t('l.deleteMenu')}
              style={tw`mb-sm`}
              variant={ButtonVariant.DangerLight}
              size={ButtonSize.Small}
              onPress={() => {
                setNotification({
                  title: t('l.deleteMenu'),
                  description: t('l.deleteMenuConfirm'),
                  type: MeaNotificationType.CONFIRM,
                  onConfirm: async () => {
                    if (!editingMenu._id) return
                    try {
                      await MenuEntityService.delete(editingMenu._id)
                      showToast(t('l.deleteMenuSuccessfully'), 'SUCCESS')
                      pop(navigation)
                    } catch (e) {
                      meaErrorHandler(e, 'UPDATE')
                    }
                  },
                })
              }}
            />
          </View>
        )}
      </ScrollView>
      <AddTimeSliceModal
        timeSlice={addingTimeSlice}
        setTimeSlice={setAddingTimeSlice}
        onSavePressed={putAddingTimeSliceToTempEditingMenu}
      />
    </Screen>
  )
}
