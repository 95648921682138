import { t } from 'i18next'
import { Dish, GetMenuIngredientsResponse, MeaError, Menu, MenuPreview } from '../../types'
import { backendClient, noResponse } from '../HTTPClient'
import { TranslatableEntityService } from '../TranslatableEntityService'

export class MenuEntityService extends TranslatableEntityService<Menu>() {
  static entityPath: string = 'menu'

  static async getMenuDishes(menuId: string): Promise<Dish[]> {
    const response = await backendClient.get(`${this.entityPath}/dishes/${menuId}`)
    if (noResponse(response)) {
      throw new MeaError('errorFetchingData', t('errors.errorFetchingData'))
    }
    return response.data
  }

  static async getMenuIngredients(menuId: string): Promise<GetMenuIngredientsResponse> {
    // FIXME returns {}
    const response = await backendClient.get(`${this.entityPath}/ingredients/${menuId}`)
    if (noResponse(response)) {
      throw new MeaError('errorFetchingData', t('errors.errorFetchingData'))
    }
    return response.data
  }

  static getByRestaurantId(restaurantId: string): Promise<MenuPreview[]> {
    return this.advancedSearch({
      equal: {
        or: [],
        and: [{ field: 'restaurantId', value: restaurantId, caseSensitive: true }],
      },
      like: { or: [], and: [] },
      offset: 0,
      limit: 20,
      sort: [{ field: '_id', direction: 1 }],
      customProject: { type: 'ExludeDecorations', exclude: true },
    })
  }

  static async clone(menuId: string): Promise<MenuPreview> {
    const response = await backendClient.get(`${this.entityPath}/copy/${menuId}`)
    if (noResponse(response)) {
      throw new MeaError('errorUpdatingData', t('errors.errorUpdatingData'))
    }
    return response.data
  }
}
