import { t } from 'i18next'
import { EmployeeLogin, MeaError, RefreshTokenResponse } from '../../types'
import { BaseService } from '../BaseService'
import { authClient, noResponse } from '../HTTPClient'
import { EmployeeEntityService } from './EmployeeEntityService'

export class EmployeeAuthService extends BaseService() {
  static entityPath: string = 'employeeauth'

  static async loginEmployee(email: string, password: string): Promise<EmployeeLogin> {
    const response = await authClient.post(`${this.entityPath}/login`, { email, password })
    if (noResponse(response)) {
      throw new MeaError('loginError', t('errors.loginError'))
    }
    return response.data
  }

  static async logoutEmployee(accessToken: string): Promise<void> {
    const response = await authClient.post(`${this.entityPath}/logout`, undefined, {
      headers: { Authorization: accessToken },
    })
    if (noResponse(response)) {
      throw new MeaError('loginError', t('errors.loginError'))
    }
    return response.data
  }

  static async refreshEmployeeToken(employeeLogin: EmployeeLogin): Promise<EmployeeLogin> {
    const response = await authClient.get(`${this.entityPath}/refreshToken/${employeeLogin.refreshToken}`, {
      headers: { Authorization: employeeLogin.accessToken },
    })
    if (noResponse(response)) {
      throw new MeaError('errorRefreshingToken', t('errors.errorRefreshingToken'))
    }
    const { accessToken, refreshToken }: RefreshTokenResponse = response.data
    const employee = await EmployeeEntityService.validateEmployee(employeeLogin.employee._id, accessToken)
    return { accessToken, refreshToken, employee }
  }

  static async changePassword(email: string, oldPassword: string, newPassword: string): Promise<void> {
    const response = await authClient.post(`${this.entityPath}/resetPasswordWithOld`, {
      email,
      oldPassword,
      newPassword,
    })
    if (noResponse(response)) {
      throw new MeaError('errorChangingPassword', t('errors.errorChangingPassword'))
    }
    return response.data
  }
}
