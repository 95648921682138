import { TouchableOpacity, ViewProps } from 'react-native'

export interface ClickableProps extends ViewProps {
  id?: string
  activeOpacity?: number
  onPress?: () => void
  onLongPress?: () => void
}

export function Clickable({ onPress, onLongPress, id, children, style, activeOpacity }: ClickableProps) {
  return (
    <TouchableOpacity
      activeOpacity={activeOpacity ?? 0.8}
      onLongPress={onLongPress}
      onPress={() => {
        onPress && onPress()
      }}
      style={style}
      key={id}
    >
      {children}
    </TouchableOpacity>
  )
}
