import { BaseModal, Button, ButtonSize, ButtonVariant, useTw } from '@mea-menu/components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'
import { EmployeeEntityService } from '../../api/employee/EmployeeEntityService'
import { useRestaurantStore, useUserStore } from '../../store'
import { showToast } from '../../utils'
import { meaErrorHandler } from '../../utils/MeaErrorHandler'
import { MeaTextInput } from '../core/MeaTextInput'

type Disclaimer = { label: string; error: boolean }

export interface EmailManagementModalProps {
  visible: boolean
  onClose: () => void
  label?: string
  onEmailChanged: (newEmail: string) => void
}

export const EmailManagementModal = ({ visible, label, onClose, onEmailChanged }: EmailManagementModalProps) => {
  const { tw } = useTw()
  const { t } = useTranslation()

  const { restaurant } = useRestaurantStore()
  const { employee } = useUserStore()

  const ORIGINAL_DISCLAIMER: Disclaimer = {
    label: '',
    error: false,
  }
  const [newEmail, setnewEmail] = useState<string>('')
  const [confirmNewEmail, setConfirmNewEmail] = useState<string>('')
  const [disclaimer, setDisclaimer] = useState<Disclaimer>(ORIGINAL_DISCLAIMER)

  useEffect(() => {
    if (!disclaimer.error) return
    setTimeout(() => setDisclaimer(ORIGINAL_DISCLAIMER), 5000)
  }, [disclaimer])

  const setErrorDisclaimer = (label: string) => setDisclaimer({ label, error: true })

  const changeEmail = async (newEmail: string, confirmNewEmail: string) => {
    if (!restaurant || !employee || !employee.email) return
    const trimmedNew = newEmail.trim()
    const trimmedConfirmNew = confirmNewEmail.trim()

    if (trimmedNew.length === 0) return setErrorDisclaimer(t('errors.youMustInsertNewEmail'))
    if (trimmedConfirmNew.length === 0) return setErrorDisclaimer(t('errors.youMustInsertNewConfirmEmail'))
    if (trimmedNew !== trimmedConfirmNew) return setErrorDisclaimer(t('errors.newConfirmEmailMismatch'))
    if (trimmedNew === employee.email) return setErrorDisclaimer(t('errors.oldAndNewEmailAreEqual'))

    if (!/^[\w\.-]+@[\w.-]+\.[a-zA-Z]{2,}$/.test(trimmedNew))
      return setErrorDisclaimer(t('errors.incorrectEmailFormat'))
    try {
      await EmployeeEntityService.changeEmail(employee._id, restaurant._id, trimmedNew)
      onClose()
      showToast(t('l.emailChangedSuccesfully'), 'SUCCESS')
      onEmailChanged(trimmedNew)
    } catch (e) {
      meaErrorHandler(e, true)
    }
  }

  return (
    <BaseModal visible={visible} onClose={onClose} title={label ?? t('l.changeEmail')}>
      <View style={tw`h-[${disclaimer.label.length === 0 ? 0 : 80}px] justify-center`}>
        <Text style={tw`${disclaimer.error ? 'textError' : 'textMono'}`}>{disclaimer.label}</Text>
      </View>
      <MeaTextInput
        label={t('l.newEmail')}
        style={tw`mt-md`}
        placeHolder={t('l.newEmail')}
        onChangeText={setnewEmail}
      />
      <MeaTextInput
        label={t('l.confirmNewEmail')}
        style={tw`mt-md`}
        placeHolder={t('l.typeNewEmailAgain')}
        onChangeText={setConfirmNewEmail}
      />
      <Button
        style={tw`mt-lg`}
        size={ButtonSize.Small}
        variant={ButtonVariant.DangerLight}
        label={t('l.changeEmail')}
        onPress={() => changeEmail(newEmail, confirmNewEmail)}
      />
    </BaseModal>
  )
}
