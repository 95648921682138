import { Ingredient } from '../../types'
import { TranslatableEntityService } from '../TranslatableEntityService'

export class IngredientEntityService extends TranslatableEntityService<Ingredient>() {
  static entityPath: string = 'ingredient'

  static getByRestaurantId(restaurantId: string): Promise<Ingredient[]> {
    return this.advancedSearch({
      equal: {
        or: [
          {
            and: [
              { field: 'system', value: false, caseSensitive: true },
              { field: 'restaurantId', value: restaurantId, caseSensitive: true },
            ],
          },
          { field: 'system', value: true, caseSensitive: true },
        ],
      },
      like: { or: [], and: [] },
      offset: 0,
      limit: 20,
      sort: [{ field: '_id', direction: 1 }],
    })
  }
}
