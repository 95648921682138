import { BaseModal, Button, useTw } from '@mea-menu/components'
import { useState } from 'react'
import { ScrollView, Text, View } from 'react-native'
import { DishCategoryEntityService } from '../api/employee/DishCategoryEntityService'
import { DishEntityService } from '../api/employee/DishEntityService'
import { EmployeeEntityService } from '../api/employee/EmployeeEntityService'
import { MenuEntityService } from '../api/employee/MenuEntityService'
import { RecipeEntityService } from '../api/employee/RecipeEntityService'
import { RestaurantEntityService } from '../api/employee/RestaurantEntityService'
import { Screen } from '../components'
import { RootStackScreenProps } from '../navigation'
import { useMenuStore, useRestaurantStore, useUserStore } from '../store'
import { SCREEN_AVAILABLE_HEIGHT } from '../theme/sizes'

export function EmployeeDebugScreen({ navigation, route }: RootStackScreenProps<'EmployeeDebugScreen'>) {
  const { tw } = useTw()
  const { restaurant } = useRestaurantStore()
  const { menu } = useMenuStore()
  const { employee, accessToken } = useUserStore()
  const [modalData, setModalData] = useState<any>()

  const ActionButton = ({ title, api }: { title: string; api: any }) => (
    <Button
      label={title}
      style={tw`mb-md`}
      onPress={async () => {
        try {
          const data = await api()
          setModalData(data)
        } catch (error) {
          console.log(error)
        }
      }}
    />
  )

  const restaurantId = restaurant?._id
  if (!restaurantId) return null

  const menuId = menu?._id
  const dishes = menu?.dishes

  return (
    <Screen padded>
      <Text style={tw`my-lg textMono title2 text-center`}>EMPLOYEE API DEBUG SCREEN</Text>
      <ScrollView>
        {employee && (
          <ActionButton
            title="Get employee"
            api={() => EmployeeEntityService.validateEmployee(employee._id, accessToken)}
          />
        )}

        <View>
          <ActionButton title="Get restaurant" api={() => RestaurantEntityService.getById(restaurantId)} />
          <ActionButton title="Get restaurant menus" api={() => MenuEntityService.getByRestaurantId(restaurantId)} />
          {menuId && (
            <View>
              <ActionButton title="Get selected menu" api={() => MenuEntityService.getById(menuId)} />
              <ActionButton
                title="Get selected menu recipes"
                api={() =>
                  RecipeEntityService.getForMenu({ restaurantId, menuId, txtFilter: '', includeAlreadyIn: true })
                }
              />
              <ActionButton title="Get selected menu dishes" api={() => MenuEntityService.getMenuDishes(menuId)} />
              <ActionButton
                title="Get selected menu ingredients"
                api={() => MenuEntityService.getMenuIngredients(menuId)}
              />
              <ActionButton
                title="Get selected menu dish categories"
                api={() => DishCategoryEntityService.getByMenuId(menuId)}
              />
              <ActionButton
                title="Get selected menu not available dishes"
                api={() => DishEntityService.getNotAvailableByMenuId(menuId)}
              />
              {dishes && dishes.length > 0 && (
                <View>
                  <ActionButton
                    title="Set first recipe as unavailable"
                    api={() => RecipeEntityService.setAvailability(restaurantId, dishes[0].recipeId, false)}
                  />
                  <ActionButton
                    title="Set first recipe as available"
                    api={() => RecipeEntityService.setAvailability(restaurantId, dishes[0].recipeId, true)}
                  />
                </View>
              )}
            </View>
          )}
        </View>
      </ScrollView>
      <BaseModal visible={!!modalData} onClose={() => setModalData(undefined)} title={'Data'}>
        <View style={tw`max-h-${SCREEN_AVAILABLE_HEIGHT - 500}`}>
          <ScrollView>
            <Text style={tw`textMono`}>{JSON.stringify(modalData)}</Text>
          </ScrollView>
        </View>
      </BaseModal>
    </Screen>
  )
}
