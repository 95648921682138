import { BaseModal, Card, Icon, IconName, useTw } from '@mea-menu/components'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'
import { useDish } from '../../hooks/useDishHook'
import { Dish } from '../../types'

export interface DishSmartOptionsProps {
  dish: Dish
  onClose: () => void
}

const ListItem = ({ text, iconName, onPress }: { text: string; iconName: IconName; onPress: () => void }) => {
  const { tw } = useTw()
  return (
    <Card style={tw`px-md py-md rounded-md mt-md flex-row justify-between items-center`} onPress={onPress}>
      <Text style={tw`flex-10 textMono`} numberOfLines={1}>
        {text}
      </Text>
      <Icon name={iconName} />
    </Card>
  )
}

const goToAddVariantFromDishScreen = (dish: Dish, navigation: StackNavigationProp<any>) => {
  const navProps = { dishId: dish._id, mode: 'variant' }
  navigation.navigate('DishEditorScreen', navProps)
}

export function DishSmartOptionsModal({ dish, onClose }: DishSmartOptionsProps) {
  const { t } = useTranslation()
  const { dishName, dishPrice, dishIsAvailable, dishCaption, toggleDishAvailability, getImageUrl } = useDish(dish)
  const navigation = useNavigation<StackNavigationProp<any>>()

  return (
    <>
      {dish && (
        <BaseModal
          onClose={() => {
            onClose()
          }}
          title={dishName}
          visible={!!dish}
        >
          <View>
            <View>
              <ListItem
                onPress={() => {
                  goToAddVariantFromDishScreen(dish, navigation)
                  onClose()
                }}
                text={t('l.addVariantFromDish')}
                iconName={'ClocheAdd'}
              />
              <ListItem
                onPress={() => {
                  toggleDishAvailability()
                  onClose()
                }}
                text={dishIsAvailable ? t('l.hideDish') : t('l.showDish')}
                iconName={dishIsAvailable ? 'EyeClosed' : 'EyeOpen'}
              />
            </View>
          </View>
        </BaseModal>
      )}
    </>
  )
}
