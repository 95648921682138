import axios, { AxiosInstance, AxiosResponse } from 'axios'
import axiosRetry from 'axios-retry'
import { t } from 'i18next'
import { useUserStore } from '../store'
import { DomainError, MeaError, NotAuthorizedCode } from '../types'
import { meaErrorHandler } from '../utils/MeaErrorHandler'
import { apiConfig } from './apiConfig'

const AUTH_EXCLUDED_APIS: string[] = ['link/redirect']

export const backendClient: AxiosInstance = axios.create({
  baseURL: apiConfig.backEndUrl,
})

export const authClient: AxiosInstance = axios.create({
  baseURL: apiConfig.authUrl,
})

axiosRetry(backendClient, { retries: 3, retryDelay: axiosRetry.exponentialDelay })
axiosRetry(authClient, { retries: 3, retryDelay: axiosRetry.exponentialDelay })

export const setBackendToken = async (token: string) => {
  return new Promise<void>(resolve => {
    backendClient.defaults.headers.common['Authorization'] = token
    resolve()
  })
}

backendClient.interceptors.request.use(async request => {
  if (AUTH_EXCLUDED_APIS.some(url => request.url!.includes(url))) return request
  const accessToken = useUserStore.getState().accessToken
  if (!backendClient.defaults.headers.common['Authorization'] && accessToken) {
    await setBackendToken(accessToken)
    request.headers!['Authorization'] = accessToken
  }
  return request
})

backendClient.interceptors.response.use(undefined, async error => {
  if (isAxiosErrorFromDomainWithInternalCode(error, NotAuthorizedCode.INVALID_TOKEN)) {
    // token is not valid - renew token and retry request
    try {
      const { refreshEmployeeToken } = useUserStore.getState()
      const { accessToken } = await refreshEmployeeToken()
      error.config.headers!['Authorization'] = accessToken
      return axios.request(error.config)
    } catch (e) {
      const { setSessionValidity } = useUserStore.getState()
      const error = new MeaError(`errorRefreshingToken - ${(e as Error).message}`, t('errors.errorRefreshingToken'))
      meaErrorHandler(error, false, true)
      // back to login screen
      setSessionValidity(false)
      return Promise.reject(t('errors.errorRefreshingToken'))
    }
  }
  return Promise.reject(error)
})

export const isAxiosErrorFromDomainWithInternalCode = (error: any, internalCode: number): boolean => {
  return (
    axios.isAxiosError(error) &&
    !!error.response &&
    !!(error.response.data as DomainError) &&
    (error.response.data as DomainError).internalCode === internalCode
  )
}

export const isAxiosErrorFromDomainNotAllowed = (error: any): boolean => {
  return (
    axios.isAxiosError(error) &&
    !!error.response &&
    !!(error.response.data as DomainError) &&
    (error.response.data as DomainError).internalCode.toString().startsWith('42')
  )
}

export const noResponse = (response: AxiosResponse) => {
  return response === undefined || response.data === undefined
}
