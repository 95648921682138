import { useTw } from '@mea-menu/components'
import { useTranslation } from 'react-i18next'
import { StatusBar, StyleProp, View, ViewStyle } from 'react-native'
import { SCREEN_AVAILABLE_HEIGHT, SCREEN_AVAILABLE_WIDTH } from '../../theme/sizes'
import { TooltipOrderSpecialTypes } from '../../types'
import { TopBar } from '../TopBar'
import { InputLanguageChooser } from './InputLanguageChooser'
import { MeaTooltip } from './tooltip/MeaTooltip'

export interface ScreenProps {
  statusBackgroundColor?: string
  noTopbar?: boolean
  topBarChildren?: React.ReactNode
  noRestaurantName?: boolean
  showBackButton?: boolean
  backButtonSendsToHome?: boolean
  overrideBackButtonPress?: () => void
  padded?: boolean
  children: React.ReactNode
  scrollableOverflow?: boolean
  style?: StyleProp<ViewStyle>
  showInputLanguageChooser?: boolean
  noSettingsIcon?: boolean
}

export function Screen({
  children,
  statusBackgroundColor,
  style,
  noTopbar,
  showBackButton,
  overrideBackButtonPress,
  backButtonSendsToHome,
  noRestaurantName,
  padded,
  topBarChildren,
  scrollableOverflow,
  showInputLanguageChooser,
  noSettingsIcon,
}: ScreenProps) {
  const statusBg = statusBackgroundColor ? statusBackgroundColor : 'transparent'
  const { tw } = useTw()
  const { t } = useTranslation()

  const TopBarChildrenView = (
    <View style={tw`flex-1 h-full flex-row justify-end mr-sm`}>
      {topBarChildren}
      {showInputLanguageChooser && topBarChildren && <View style={tw`mx-xs`} />}
      {showInputLanguageChooser && (
        <MeaTooltip
          uniqueId={TooltipOrderSpecialTypes.TRANSLATE_SETTINGS_ICON}
          text={t('tooltips.inputLanguage')}
          position="bottom"
          removeWidthWrapper
        >
          <InputLanguageChooser />
        </MeaTooltip>
      )}
    </View>
  )

  return (
    <View
      style={[
        tw`flex-1 fillBackground ${scrollableOverflow ? 'overflow-scroll' : 'overflow-hidden'}`,
        { height: scrollableOverflow ? undefined : SCREEN_AVAILABLE_HEIGHT, width: SCREEN_AVAILABLE_WIDTH },
        style,
      ]}
    >
      <StatusBar backgroundColor={statusBg} barStyle={'dark-content'} translucent animated />
      <View style={[tw`flex-1 ${padded ? 'px-xs' : ''}`]}>
        {!noTopbar && (
          <TopBar
            noRestaurantName={noRestaurantName}
            showBackButton={showBackButton}
            overrideBackButtonPress={overrideBackButtonPress}
            backButtonSendsToHome={backButtonSendsToHome}
            children={TopBarChildrenView}
            noSettingsIcon={noSettingsIcon}
          />
        )}
        {children}
      </View>
    </View>
  )
}
