import { t } from 'i18next'
import { Employee, MeaError } from '../../types'
import { BaseEntityService } from '../BaseEntityService'
import { backendClient, noResponse } from '../HTTPClient'

export class EmployeeEntityService extends BaseEntityService<Employee>() {
  static entityPath: string = 'employee'

  static async validateEmployee(employeeId: string, accessToken?: string): Promise<Employee> {
    const response = await backendClient.get(
      `${this.entityPath}/${employeeId}`,
      accessToken ? { headers: { Authorization: accessToken } } : {}
    )
    if (noResponse(response)) {
      throw new MeaError('employeeValidationError', t('errors.employeeValidationError'))
    }
    return response.data
  }

  static async changeEmail(employeeId: string, restaurantId: string, newEmail: string): Promise<Employee> {
    return this.update(employeeId, {
      email: newEmail,
      _id: employeeId,
      restaurantId,
    })
  }
}
