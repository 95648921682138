import { Button, ButtonSize, ButtonVariant, Icon, useTw } from '@mea-menu/components'
import { useNavigation } from '@react-navigation/native'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, SafeAreaView, StatusBar, Text, View } from 'react-native'
import { goHome, pop } from '../navigation/Navigator'
import { useRestaurantStore } from '../store'
import { TooltipOrderSpecialTypes } from '../types'
import { Clickable, SettingsModal } from './'
import { MeaTooltip } from './core/tooltip/MeaTooltip'

const STATUSBAR_HEIGHT = StatusBar.currentHeight

const MyStatusBar = ({ backgroundColor, hidden, ...props }: { backgroundColor: string; hidden: boolean }) => {
  if (hidden) {
    return <StatusBar hidden />
  }
  return (
    <View style={[{ backgroundColor, height: STATUSBAR_HEIGHT }]}>
      <SafeAreaView>
        <StatusBar translucent backgroundColor={backgroundColor} {...props} />
      </SafeAreaView>
    </View>
  )
}

export interface TopBarProps {
  title?: string
  noRestaurantName?: boolean
  showBackButton?: boolean
  overrideBackButtonPress?: () => void
  backButtonSendsToHome?: boolean
  children?: React.ReactNode
  noSettingsIcon?: boolean
}

export function TopBar({
  noRestaurantName,
  showBackButton,
  overrideBackButtonPress,
  backButtonSendsToHome,
  children,
  title,
  noSettingsIcon,
}: TopBarProps) {
  let hasNotch = false
  {
    /*DeviceInfo.hasNotch()*/
  }
  if (Platform.OS === 'android') {
    hasNotch = (StatusBar?.currentHeight ?? 0) > 24
  }

  const { t } = useTranslation()
  const { tw } = useTw()

  const { restaurant } = useRestaurantStore()
  const navigation = useNavigation()
  const [settingsModalVisible, setsettingsModalVisible] = useState(false)
  const _bg = 'bg-whiteBg'

  return (
    <View>
      <MyStatusBar backgroundColor={_bg} hidden={false} />
      <View style={tw`h-[60px] items-center flex-row justify-between px-md py-sm`}>
        {showBackButton && (
          <Button
            label={t('l.back')}
            icon="Back"
            size={ButtonSize.Small}
            variant={ButtonVariant.Ghost}
            iconToTheLeft
            onPress={() => {
              if (overrideBackButtonPress) {
                overrideBackButtonPress()
              } else {
                if (backButtonSendsToHome) {
                  goHome(navigation)
                } else {
                  pop(navigation)
                }
              }
            }}
          />
        )}
        {!noRestaurantName && restaurant && (
          <Text style={tw`flex-1 textMono title`} numberOfLines={2}>
            {restaurant?.name}
          </Text>
        )}
        {children}
        {!noSettingsIcon && (
          <MeaTooltip uniqueId={TooltipOrderSpecialTypes.SETTINGS} text={t('tooltips.settings')} position="bottom">
            <Clickable
              id="open_settings"
              style={tw`py-xs flex-row rounded-md`}
              onPress={() => setsettingsModalVisible(true)}
            >
              <Icon name="Settings" />
            </Clickable>
          </MeaTooltip>
        )}

        <SettingsModal onClose={() => setsettingsModalVisible(false)} visible={settingsModalVisible} />
      </View>
    </View>
  )
}
