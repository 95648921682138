import { BaseModal, Button, ButtonSize, ButtonVariant, Card, CardVariant, Icon, useTw } from '@mea-menu/components'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, Image, Text, View } from 'react-native'
import { Images } from '../../../assets'
import { IngredientEntityService } from '../../api/employee/IngredientEntityService'
import { MeaTextInput } from '../../components/core/MeaTextInput'
import { useSessionStore } from '../../store'
import { SCREEN_AVAILABLE_HEIGHT } from '../../theme/sizes'
import { Ingredient } from '../../types'
import { capitalizeFirstLetter, getIngredientAllergens, getLocalizedField } from '../../types/utils'
import { translateLocal } from '../../utils'
import { INTOLERANCES } from '../../utils/dishHelpers'
import { meaErrorHandler } from '../../utils/MeaErrorHandler'

export interface SelectIngredientModalProps {
  isVisible: boolean
  onClose: () => void
  onSelect: (ingredient: Ingredient) => void
}

const MIN_SEARCH_CHARS = 3

export const SelectIngredientModal = ({ isVisible, onClose, onSelect }: SelectIngredientModalProps) => {
  const { t, i18n } = useTranslation()
  const { tw } = useTw()

  const { inputLanguage, getAllOtherInputLanguages } = useSessionStore()
  const [ingredientName, setIngredientName] = useState('')
  const [ingredients, setIngredients] = useState<Ingredient[]>([])
  const [isCreationMode, setisCreationMode] = useState(false)
  const [selectedAllergenes, setSelectedAllergenes] = useState<string[]>([])

  const searchIngredient = async () => {
    if (ingredientName.length < MIN_SEARCH_CHARS) return setIngredients([])
    const ingredients = await IngredientEntityService.advancedSearch({
      general: { fields: [getLocalizedField('$name', i18n.language), '$nameEn'], value: ingredientName },
      limit: 10,
    })
    setIngredients(ingredients)
  }

  useEffect(() => {
    if (!isCreationMode) searchIngredient()
  }, [ingredientName])

  const toggleAllergen = useCallback(
    (allergen: string) => {
      if (selectedAllergenes.includes(allergen)) {
        setSelectedAllergenes(selectedAllergenes.filter(item => item !== allergen))
      } else {
        setSelectedAllergenes([...selectedAllergenes, allergen])
      }
    },
    [selectedAllergenes]
  )

  const createIngredient = async () => {
    try {
      let ingredient = await IngredientEntityService.create({
        [getLocalizedField('name', inputLanguage)]: ingredientName,
        categoriesId: selectedAllergenes,
      })
      const translations = await IngredientEntityService.translate(
        ingredient._id,
        getLocalizedField('name', inputLanguage),
        getAllOtherInputLanguages()
      )
      ingredient = { ...ingredient, ...translations }
      onSelect(ingredient)
      clearSearch()
    } catch (e) {
      meaErrorHandler(e, 'UPDATE')
    }
  }

  const clearSearch = () => {
    setIngredientName('')
    setIngredients([])
    setisCreationMode(false)
    setSelectedAllergenes([])
  }

  return (
    <BaseModal
      onClose={() => {
        onClose()
        setTimeout(() => {
          clearSearch()
        }, 500)
      }}
      title={isCreationMode ? t('l.createNewIngredient') : ''}
      visible={isVisible}
    >
      {!isCreationMode && (
        <View style={tw`h-[${SCREEN_AVAILABLE_HEIGHT}px]`}>
          <MeaTextInput
            placeHolder={t('l.ingredientName')}
            label={t('l.searchIngredient')}
            showInputLocaleFlag
            autoFocus
            initialValue={ingredientName}
            onChangeText={setIngredientName}
          />
          {ingredientName.length < MIN_SEARCH_CHARS && ingredients.length === 0 && (
            <View style={tw`mt-md opacity-60`}>
              <Text style={tw`textMono title4`}>
                {t('l.writeAtLeastNCharsToSearch', { minChars: MIN_SEARCH_CHARS })}
              </Text>
              <View style={tw`h-full items-center justify-center pt-xl`}>
                <Image source={Images.illustration_intolerance} style={tw`w-[200px] h-[200px] rounded-xs`} />
              </View>
            </View>
          )}
          <FlatList
            data={ingredients}
            ListEmptyComponent={
              <View>
                {ingredientName.length >= MIN_SEARCH_CHARS && (
                  <>
                    <Text style={tw`textMono`}>{t('l.noIngredientsFound')}</Text>
                    <Button
                      variant={ButtonVariant.Primary}
                      label={t('l.createNewIngredient')}
                      onPress={() => {
                        setIngredientName(capitalizeFirstLetter(ingredientName.trim()))
                        setisCreationMode(true)
                      }}
                      style={tw`mt-xs`}
                      size={ButtonSize.Small}
                    />
                  </>
                )}
              </View>
            }
            style={tw`mt-md max-h-[${SCREEN_AVAILABLE_HEIGHT - 200}px]`}
            renderItem={({ item }) => {
              const ingredientAllergens = getIngredientAllergens(item.allergens)

              return (
                <Card
                  style={tw`mb-xs justify-between flex-row`}
                  onPress={() => {
                    onSelect(item)
                    setTimeout(() => {
                      clearSearch()
                    }, 500)
                  }}
                >
                  <View style={tw`flex-row items-end justify-start`}>
                    <Text style={tw`textMono`}>{translateLocal(item, 'name', i18n.language)}</Text>
                    {ingredientAllergens.length > 0 && (
                      <View style={tw`flex-row ml-sm `}>
                        {ingredientAllergens.map(allergen => (
                          <View key={allergen._id} style={tw`mr-xs`}>
                            <Image source={allergen.imageUri} style={tw`w-md h-md`} />
                          </View>
                        ))}
                      </View>
                    )}
                  </View>
                  <Icon name="Plus" />
                </Card>
              )
            }}
          />
        </View>
      )}
      {isCreationMode && (
        <View style={tw`h-[${SCREEN_AVAILABLE_HEIGHT}px]`}>
          <MeaTextInput
            placeHolder={t('l.ingredientName')}
            label={t('l.ingredientName')}
            initialValue={ingredientName}
            onChangeText={setIngredientName}
            showInputLocaleFlag
            autoFocus
            style={tw`mt-md`}
            numberOfLines={3}
          />
          <Text style={tw`textMono label mt-md mb-xs`}>{t('l.selectAllergen')}</Text>
          <FlatList
            data={INTOLERANCES}
            extraData={selectedAllergenes}
            showsVerticalScrollIndicator={false}
            numColumns={2}
            renderItem={({ item, index }) => (
              <Card
                style={tw`flex-row justify-center p-sm mb-xs ${index % 2 === 0 ? 'mr-xs' : ''}`}
                variant={selectedAllergenes.includes(item._id) ? CardVariant.Primary : CardVariant.Default}
                onPress={() => toggleAllergen(item._id)}
              >
                <Text style={tw`textMono button`}>{translateLocal(item, 'name', i18n.language)}</Text>
                <Image source={item.imageUri} style={tw`ml-xs w-md h-md`} />
              </Card>
            )}
            ListFooterComponent={
              <Button
                size={ButtonSize.Small}
                variant={ButtonVariant.Primary}
                label={t('l.createIngredient')}
                onPress={createIngredient}
                style={tw`mt-sm`}
              />
            }
          />
        </View>
      )}
    </BaseModal>
  )
}
