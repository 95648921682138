import { BaseModal, Card, Icon, IconName, useTw } from '@mea-menu/components'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'
import { useSessionStore } from '../../store'
import { MenuPreview, MenuStatus } from '../../types'
import { translateLocal } from '../../utils'

export interface MenuSmartOptionsProps {
  menu?: MenuPreview
  onClose: () => void
  onGoToManageDishesScreen: () => Promise<void>
  onGoToEditMenuScreen: () => Promise<void>
  onToggleMenuAvailability: () => Promise<void>
}

const ListItem = ({ text, iconName, onPress }: { text: string; iconName: IconName; onPress: () => void }) => {
  const { tw } = useTw()
  return (
    <Card style={tw`px-md py-md rounded-md mt-md flex-row justify-between items-center`} onPress={onPress}>
      <Text style={tw`flex-10 textMono`} numberOfLines={1}>
        {text}
      </Text>
      <Icon name={iconName} />
    </Card>
  )
}

export function MenuSmartOptionsModal({
  menu,
  onClose,
  onToggleMenuAvailability,
  onGoToEditMenuScreen,
  onGoToManageDishesScreen,
}: MenuSmartOptionsProps) {
  const { t } = useTranslation()
  const navigation = useNavigation<StackNavigationProp<any>>()
  const { inputLanguage } = useSessionStore()

  return (
    <>
      {menu && (
        <BaseModal
          onClose={() => {
            onClose()
          }}
          title={translateLocal(menu, 'name', inputLanguage)}
          visible={!!menu}
        >
          <View>
            <View>
              <ListItem
                onPress={async () => {
                  await onGoToEditMenuScreen()
                  onClose()
                }}
                text={t('l.editMenu')}
                iconName={'Settings'}
              />
              <ListItem
                onPress={async () => {
                  await onGoToManageDishesScreen()
                  onClose()
                }}
                text={t('l.manageDishes')}
                iconName={'Food'}
              />
              <ListItem
                onPress={async () => {
                  await onToggleMenuAvailability()
                  onClose()
                }}
                text={menu.status === MenuStatus.ACTIVE ? t('l.hideMenu') : t('l.showMenu')}
                iconName={menu.status === MenuStatus.ACTIVE ? 'EyeClosed' : 'EyeOpen'}
              />
            </View>
          </View>
        </BaseModal>
      )}
    </>
  )
}
