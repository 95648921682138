import { Intolerance } from '.'
import { useMenuStore, useRestaurantStore, useSessionStore, useUserStore } from '../store'
import { useRecipeStore } from '../store/recipeStore'
import { INTOLERANCES } from '../utils/dishHelpers'

/**
 * Use this type to require at least one of the optional properties example:
 * ```
 * type T = {
 *  a?: string
 *  b?: string
 * }
 * type T1 = RequireAtLeastOne<T>
 *
 * const t: T1 = {} // error
 * const t1: T1 = { a: 'a' } // ok
 * const t2: T1 = { b: 'b' } // ok
 * const t3: T1 = { a: 'a', b: 'b' } // ok
 * ```
 */
export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>
  }[Keys]

export function unique(value: any, index: number, array: any[]) {
  return array.indexOf(value) === index
}

export function getNextStringLiteralEnumValue<T extends string>(
  enumObject: Record<string, T>,
  currentValue: T,
  loop: boolean = false
): T | null {
  const enumValues = Object.values(enumObject)
  const currentIndex = enumValues.indexOf(currentValue)

  if (currentIndex === -1 || (currentIndex === enumValues.length - 1 && !loop)) {
    return null
  }

  return enumValues[(currentIndex + 1) % enumValues.length] as T
}

export function capitalizeFirstLetter(string: string) {
  return string && string.length === 0 ? '' : string.charAt(0).toUpperCase() + string.slice(1)
}

export function getLocalizedField(field: string, langCode: string) {
  return `${field}${capitalizeFirstLetter(langCode)}`
}

export const reinitializeStores = () => {
  const { clearStore: clearUserStore } = useUserStore.getState()
  const { clearStore: clearRestaurantStore } = useRestaurantStore.getState()
  const { clearStore: clearMenuStore } = useMenuStore.getState()
  const { clearStore: clearRecipeStore } = useRecipeStore.getState()

  clearUserStore()
  clearRestaurantStore()
  clearMenuStore()
  clearRecipeStore()
}

export const getIngredientAllergens = (ingredientAllergens: number) => {
  const allergens: Intolerance[] = []
  INTOLERANCES.forEach(intolerance => {
    if ((ingredientAllergens & intolerance.value) === intolerance.value) allergens.push(intolerance)
  })
  return allergens
}
