import { t } from 'i18next'
import { MeaError } from '../types'
import { BaseEntityService } from './BaseEntityService'
import { backendClient, noResponse } from './HTTPClient'

export function TranslatableEntityService<T>() {
  abstract class TranslatableEntityService extends BaseEntityService<T>() {
    static async translate(id: string, field: string, toLanguages: string[]): Promise<T> {
      const response = await backendClient.post(`${this.entityPath}/translate/${id}`, {
        field: field,
        languages: toLanguages,
      })
      if (noResponse(response)) {
        throw new MeaError('errorUpdatingData', t('errors.errorUpdatingData'))
      }
      return response.data
    }
  }

  return TranslatableEntityService
}
