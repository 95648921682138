import { Icon, useTw } from '@mea-menu/components'
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { ScrollView, Text, View } from 'react-native'
import Tooltip from 'react-native-walkthrough-tooltip'
import { useTooltipStore } from '../../../store'
import { SCREEN_AVAILABLE_HEIGHT } from '../../../theme/sizes'
import { TOOLTIP_ORDER } from '../../../types'

/**
 * This tooltip is used to show a tooltip on a specific element.
 * When unique id is the first element of readyTooltips
 *
 *
 * @param uniqueId - unique id for the tooltip to be shown
 * @param text - text to be shown in the tooltip
 * @param position - position of the tooltip
 * @param scrollViewRef - reference to the scroll view that contains the "tooltiped" element
 * @param removeWidthWrapper - if true, the tooltip will not be wrapped in a View with 100% width
 */
export interface MeaTooltipProps {
  uniqueId: string
  text: string
  position?: 'top' | 'right' | 'bottom' | 'left' | 'center'
  scrollViewRef?: React.RefObject<ScrollView>
  removeWidthWrapper?: boolean
}

function shouldTooltipAppear(shownTooltips: string[], readyTooltips: string[], uniqueId: string) {
  const relativeOrder = TOOLTIP_ORDER.filter(item => !shownTooltips.includes(item) && readyTooltips.includes(item))
  return relativeOrder.indexOf(uniqueId) === 0
}

function getPositionToScroll(top: number, height: number) {
  return top - SCREEN_AVAILABLE_HEIGHT / 2 + height / 2
}

export const WAIT_TIME_FOR_TOOLTIP = 500

export const MeaTooltip = ({
  scrollViewRef,
  uniqueId,
  children,
  text,
  position = 'top',
  removeWidthWrapper,
}: PropsWithChildren<MeaTooltipProps>) => {
  const { tw, theme } = useTw()
  const { setTooltipsShown, shownTooltips, readyTooltips, addReadyTooltip, removeReadyTooltip } = useTooltipStore()

  // shouldShowTooltip:true - scrolla, parte une timeout che tra 500ms setta isTooltipVisible a true
  const [shouldShowTooltip, setShouldShowTooltip] = useState(false)
  const [isTooltipVisible, setisTooltipVisible] = useState(false)
  const [viewInformation, setViewInformation] = useState<{ top: number; height: number }>()

  // after first render mark the tooltip as ready
  useEffect(() => {
    addReadyTooltip(uniqueId)
  }, [])

  useEffect(() => {
    if (shouldShowTooltip) return
    const shouldAppear = shouldTooltipAppear(shownTooltips, readyTooltips, uniqueId)
    if (shouldAppear) {
      setShouldShowTooltip(true)
      scrollToTooltipContent()
      setTimeout(() => {
        setisTooltipVisible(true)
      }, WAIT_TIME_FOR_TOOLTIP)
    }
  }, [shownTooltips, readyTooltips])

  const scrollToTooltipContent = useCallback(() => {
    if (scrollViewRef?.current && viewInformation) {
      scrollViewRef.current.scrollTo({
        y: getPositionToScroll(viewInformation.top, viewInformation.height),
        animated: true,
      })
    }
  }, [scrollViewRef, viewInformation])

  const onGoNext = () => {
    setTooltipsShown([uniqueId], true)
    removeReadyTooltip(uniqueId)

    // clear
    setShouldShowTooltip(false)
    setisTooltipVisible(false)
  }

  return (
    <Tooltip
      childrenWrapperStyle={{}}
      useInteractionManager={true}
      contentStyle={tw`rounded-sm`}
      tooltipStyle={tw`shadow-none`}
      showChildInTooltip={true}
      useReactNativeModal={true}
      placement={position}
      isVisible={isTooltipVisible}
      content={
        <View style={tw`px-2 my-sm min-w-40 max-w-60 items-center`}>
          <Text style={tw`text-center ${theme === 'light' ? 'textMono' : ''}`}>{text}</Text>
          <View style={tw`w-full flex-row justify-end items-center pt-2`}>
            <Icon name="MeaMenu" size={16} />
          </View>
        </View>
      }
      onClose={onGoNext}
    >
      <View
        onLayout={layout => {
          const { top, height } = layout.nativeEvent.layout as any
          setViewInformation({ top, height })
        }}
        style={tw`${removeWidthWrapper ? '' : 'justify-center w-[100%] h-[100%]'}`}
      >
        {children}
      </View>
    </Tooltip>
  )
}
