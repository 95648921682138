import { produce } from 'immer'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

interface TooltipState {
  shownTooltips: string[]
  readyTooltips: string[]
}

export interface TooltipStore extends TooltipState {
  setTooltipsShown: (keys: string[], value: boolean) => void
  isTooltipShown: (id: string) => boolean
  clearShownTooltipStore: () => void
  addReadyTooltip: (tooltip: string) => void
  removeReadyTooltip: (tooltip: string) => void
  clearTooltip: () => void
}

const initialState: TooltipState = {
  shownTooltips: [],
  readyTooltips: [],
}

export const useTooltipStore = create<TooltipStore>()(
  persist(
    (set, get) => ({
      ...initialState,

      setTooltipsShown: (keys: string[], value: boolean) => {
        const { shownTooltips } = get()
        const updatedShownTooltips = value
          ? [...shownTooltips, ...keys]
          : shownTooltips.filter(tooltipName => !keys.includes(tooltipName))

        set({ shownTooltips: updatedShownTooltips })
      },

      isTooltipShown: (id: string) => {
        const state = get()
        return state.shownTooltips.includes(id)
      },

      addReadyTooltip: tooltip => {
        const { readyTooltips } = get()
        if (readyTooltips.includes(tooltip)) return

        set(
          produce((state: TooltipState) => {
            state.readyTooltips.push(tooltip)
          })
        )
      },
      removeReadyTooltip: tooltip => {
        const { readyTooltips } = get()
        set({ readyTooltips: readyTooltips.filter(tt => tt !== tooltip) })
      },

      clearTooltip: () => {
        set({ readyTooltips: [] })
      },

      clearShownTooltipStore: () => {
        set({ shownTooltips: [] })
      },
    }),
    {
      partialize(state: TooltipStore) {
        const { readyTooltips, ...rest } = state
        return rest
      },
      name: 'meamenumanager.shownTooltips',
      storage: createJSONStorage(() => localStorage), // Specify the storage engine
    }
  )
)
