import { Card, CardVariant, useTw } from '@mea-menu/components'
import { FlatList, Image, Text } from 'react-native'
import { LanguageLink } from '../types'
import { LANGUAGES } from '../utils/commonHelpers'

export interface SelectLanguageFragmentProps {
  currentLanguage: string
  onLanguageSelected: (language: string) => void
  onClose: () => void
}

export function SelectLanguageFragment({ currentLanguage, onLanguageSelected, onClose }: SelectLanguageFragmentProps) {
  const { tw } = useTw()

  const LanguageItem = ({ langCode, langItem, index }: { langCode: string; langItem: LanguageLink; index: number }) => {
    return (
      <Card
        key={langItem.langName}
        variant={CardVariant.Default}
        style={tw`m-xs py-xs items-center border-2 ${index % 2 !== 0 ? 'mr-md' : ''} ${
          langCode === currentLanguage ? 'strokePrimary' : 'strokeTransparent'
        }`}
        onPress={async () => {
          onClose()
          setTimeout(() => {
            onLanguageSelected(langCode)
          }, 50)
        }}
      >
        <Image style={{ width: 40, height: 40 }} source={{ uri: langItem.langFlag }} />
        <Text style={tw`mt-sm textMono`}>{langItem.langName}</Text>
      </Card>
    )
  }

  return (
    <FlatList
      data={Object.entries(LANGUAGES)}
      numColumns={2}
      renderItem={({ item, index }) => <LanguageItem langCode={item[0]} langItem={item[1]} index={index} />}
    />
  )
}
