import deepEqual from 'fast-deep-equal/es6'
import { useEffect, useState } from 'react'
import { Images } from '../../assets'
import { LanguageLink } from '../types'

export const LANGUAGES: { [key: string]: LanguageLink } = {
  en: {
    langName: 'English',
    langFlag: Images.flag_en,
    langCodes: ['en', 'en-US', 'en-GB'],
  },
  it: {
    langName: 'Italiano',
    langFlag: Images.flag_it,
    langCodes: ['it', 'it-IT', 'it-CH'],
  },
  es: {
    langName: 'Español',
    langFlag: Images.flag_es,
    langCodes: ['es', 'es-ES'],
  },
  fr: {
    langName: 'Français',
    langFlag: Images.flag_fr,
    langCodes: ['fr', 'fr-FR'],
  },
  de: {
    langName: 'Deutsch',
    langFlag: Images.flag_de,
    langCodes: ['de', 'de-DE', 'de-CH'],
  },
  ru: {
    langName: 'Русский',
    langFlag: Images.flag_ru,
    langCodes: ['ru', 'ru-RU'],
  },
  zh: {
    langName: '中国人',
    langFlag: Images.flag_zh,
    langCodes: ['zh', 'zh-CN', 'zh-HK'],
  },
  ja: {
    langName: '日本語',
    langFlag: Images.flag_ja,
    langCodes: ['ja', 'ja-JP'],
  },
}

/**
 * Hook that will delay the execution of a function until the last item is added
 * @param delay delay in ms
 * @param onItemMatured function to be executed after the delay
 * @returns addItemWithDelay function to add an item to the queue
 */
export function useDelayer<T>(delay: number, onItemMatured: (item: T) => void) {
  const [lastItem, setLastItem] = useState<T>()
  const [dispatcherTimeout, setDispatcherTimeout] = useState<NodeJS.Timeout>()

  useEffect(() => {
    if (!lastItem) return
    if (dispatcherTimeout) clearInterval(dispatcherTimeout)

    // this will propagate the last item after the delay
    const interval = setTimeout(() => {
      onItemMatured(lastItem)
      setLastItem(undefined)
    }, delay)
    setDispatcherTimeout(interval)
    return () => {
      if (dispatcherTimeout) clearTimeout(dispatcherTimeout)
    }
  }, [lastItem])

  return {
    addItemWithDelay: (item: T) => {
      setLastItem(item)
      clearInterval(dispatcherTimeout)
    },
  }
}

/**
 * Remove subcategories from a list of categories
 * subcategories are identified by a dot in the category name
 * @param allCategories
 * @returns filtered categories
 */
export const filterOutSubcategories = (allCategories: string[]) => {
  const result: string[] = []
  allCategories.forEach(cat => {
    const root = cat.split('.')[0]
    if (!result.includes(root)) {
      result.push(root)
    }
  })
  return result
}

/**
 * Retrieve subcategories of a category
 * subcategories are identified by a dot in the category name
 * @param allCategories
 * @param categoryKey
 * @returns subcategories of the category
 */
export const retrieveSubcategories = (allCategories: string[], categoryKey: string) => {
  const result: string[] = []
  allCategories.forEach(cat => {
    const root = cat.split('.')[0]
    if (root === categoryKey) {
      result.push(cat)
    }
  })
  return result
}

export function deepEquals(a: any, b: any) {
  return deepEqual(a, b)
}
