import { ButtonSize, ButtonVariant, Card, Icon, IconButton, useTw } from '@mea-menu/components'
import { useEffect, useState } from 'react'
import { Text, View } from 'react-native'
import { useDish } from '../../hooks/useDishHook'
import { Dish } from '../../types'
import { SmartImage } from '../core/SmartImage'

export const MENU_DISH_ITEM_HEIGHT = 90

export interface MenuDishItemProps {
  dish: Dish
  smartDishModalMenu?: Dish
  highlight?: boolean
  onPress?: () => void
  onOpenSmartModal?: (dish: Dish) => void
  showMoreOptions?: boolean
}

export function MenuDishItem({ dish, onOpenSmartModal, highlight, onPress, showMoreOptions }: MenuDishItemProps) {
  const { tw } = useTw()
  const { dishName, dishPrice, dishIsAvailable, dishIsSuggested, dishCaption, toggleDishAvailability, getImageUrl } =
    useDish(dish)
  const [imageUrl, setImageUrl] = useState<string | undefined>()

  const fetchImageUrl = async () => {
    const url = await getImageUrl()
    setImageUrl(url)
  }

  useEffect(() => {
    fetchImageUrl()
  }, [])

  return (
    <View style={tw`h-[${MENU_DISH_ITEM_HEIGHT}px]`}>
      <Card
        onPress={onPress}
        key={dish._id}
        style={tw`flex-row p-xs ${highlight ? 'fillCardAccent' : ''} ${!dishIsAvailable ? 'fillBackground' : ''}`}
      >
        <View style={[tw`justify-center w-[70px] h-[70px]`, { opacity: dishIsAvailable ? 1 : 0.6 }]}>
          <SmartImage
            style={tw`w-full h-full rounded-sm`}
            source={{ uri: imageUrl }}
            resizeMode="contain"
            forceHeight={120}
          />
        </View>
        <View style={[tw`ml-sm flex-1`, { opacity: dishIsAvailable ? 1 : 0.6 }]}>
          <Text style={tw`title4 textMono`} numberOfLines={2}>
            {dishName}
          </Text>
          <Text style={tw`justify-center textMono`}>{dishPrice}</Text>
          <Text style={tw`textMono mt-xxs`} numberOfLines={1}>
            {dishCaption}
          </Text>
        </View>
        <View style={tw`justify-between items-center`}>
          {showMoreOptions && (
            <>
              <IconButton
                style={tw`self-start`}
                icon="VerticalMenu"
                size={ButtonSize.Small}
                variant={ButtonVariant.Accent}
                onPress={() => onOpenSmartModal?.(dish)}
              />
            </>
          )}
          {dishIsSuggested && <Icon name="Stars" />}
        </View>
      </Card>
    </View>
  )
}
