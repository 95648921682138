import {
  BaseModal,
  Button,
  ButtonSize,
  ButtonVariant,
  colors,
  Icon,
  IconColor,
  IconSize,
  useTw,
} from '@mea-menu/components'
import * as ImagePicker from 'expo-image-picker'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, Text, TouchableOpacity, View } from 'react-native'
import { Browser as SentryBrowser } from 'sentry-expo'
import { Images } from '../../../assets'
import { MediaService } from '../../api/employee/MediaService'
import { useNotificationsStore } from '../../store'
import { useMediaStore } from '../../store/mediaStore'
import { MeaMedia, MeaNotificationType } from '../../types'
import { showToast } from '../../utils'
import { SmartImage } from './SmartImage'

export interface ImageInputProps {
  modalTitle?: string
  imageId?: string
  currentImageUri?: string
  saveImage: (image: MeaMedia) => Promise<void> // TODO better handling if fail to save
  onImageUploaded?: (uri: string, fileName?: string) => void
  onImageRemoved?: () => void
  uploadImage?: (imageUrl: string) => Promise<void>
}

export async function uploadImage(image: ImagePicker.ImagePickerAsset) {
  const { uri, fileName, mimeType } = image
  const imageFormat = uri.split(';')[0].split('/')[1]
  let file = await fetch(uri)
    .then(r => r.blob())
    .then(blobFile => new File([blobFile], fileName ?? 'image', { type: mimeType }))

  return await MediaService.upload(file)
}

export function ImageInput({ currentImageUri, imageId, modalTitle, onImageRemoved, saveImage }: ImageInputProps) {
  const { tw } = useTw()
  const { t } = useTranslation()

  const { setNotification } = useNotificationsStore()
  const { fetchCoverImage } = useMediaStore()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [uploadedImage, setUploadedImage] = useState<ImagePicker.ImagePickerAsset>()
  const [imageWithoutBackground, setImageWithoutBackground] = useState<MeaMedia>()
  const [isShowingOldImage, setIsShowingOldImage] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState<string>()

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      allowsMultipleSelection: false,
      quality: 1,
    })
    if (!result.canceled) {
      setUploadedImage(result.assets[0])
    }
  }

  const removeBackground = async () => {
    if (!imageId) return
    setLoadingMessage(t('l.processingImage'))
    try {
      const imageWithoutBg = await MediaService.removeBackgroundAndCrop(imageId)
      setImageWithoutBackground(imageWithoutBg)
    } catch (error) {
      SentryBrowser.captureException(error)
      setIsModalVisible(false)
    }
    setLoadingMessage(undefined)
  }

  const resetUi = () => {
    setImageWithoutBackground(undefined)
    setUploadedImage(undefined)
    setIsModalVisible(false)
    setLoadingMessage(undefined)
  }

  const saveNewImage = async () => {
    // UPLOAD NEW IMAGE
    if (uploadedImage) {
      setLoadingMessage(t('l.savingImage'))
      try {
        const newMedia = await uploadImage(uploadedImage)
        await saveImage(newMedia)
        fetchCoverImage(newMedia._id)
      } catch (error) {
        showToast("L'immagine è troppo grande, riprova o contatta il supporto")
        SentryBrowser.captureException(error)
      }
    }

    // REMOVE BACKGROUND FROM EXISTING IMAGE
    else if (imageWithoutBackground) {
      setLoadingMessage(t('l.savingImage'))
      try {
        await saveImage(imageWithoutBackground)
        fetchCoverImage(imageWithoutBackground._id)
      } catch (error) {
        SentryBrowser.captureException(error)
      }
    }

    // FINALLY RESET UI
    resetUi()
  }

  const deleteImage = useCallback(async () => {
    if (!imageId) return
    setLoadingMessage(t('l.deletingImage'))
    try {
      // await MediaService.delete(imageId)
      onImageRemoved?.()
    } catch (error) {
      console.log(error)
      SentryBrowser.captureException(error)
    }
    resetUi()
  }, [imageId, onImageRemoved])

  const imageUrl = useMemo(() => {
    if (imageWithoutBackground && !isShowingOldImage) return imageWithoutBackground.url
    if (uploadedImage && !isShowingOldImage) return uploadedImage.uri
    if (currentImageUri) return currentImageUri
    return Images.placeholder
  }, [uploadedImage, currentImageUri, imageWithoutBackground, isShowingOldImage])

  const hasUpdatedImage = useMemo(() => {
    if (uploadedImage) return true
    if (imageWithoutBackground) return true
    return false
  }, [uploadedImage, imageWithoutBackground])

  return (
    <View>
      <TouchableOpacity onPress={() => setIsModalVisible(true)}>
        <SmartImage source={{ uri: imageUrl }} style={tw`w-full h-30 rounded-xs`} />
        <View style={tw`absolute top-md right-md`}>
          <Icon name="Edit" color={IconColor.secondary} size={IconSize.Medium} />
        </View>
      </TouchableOpacity>

      <BaseModal visible={isModalVisible} title={modalTitle ?? ''} onClose={resetUi}>
        <TouchableOpacity activeOpacity={0.8} onPress={() => setIsShowingOldImage(!isShowingOldImage)}>
          <SmartImage
            source={{ uri: imageUrl }}
            loadingIndicatorSource={Images.placeholder}
            resizeMode={'contain'}
            forceHeight={80}
            style={tw`w-full h-80 rounded-xs my-xs`}
          />

          {imageWithoutBackground && (
            <Text style={tw`textMono label mt-md`}>
              {isShowingOldImage ? t('l.clickImageToSeeWithoutBackground') : t('l.clickImageToSeeWithBackground')}
            </Text>
          )}
        </TouchableOpacity>
        <View style={tw`h-40`}>
          {loadingMessage && (
            <>
              <Text style={tw`textMono label text-center mb-sm`}>{loadingMessage}</Text>
              <ActivityIndicator size="large" color={colors.orange[400]} />
            </>
          )}

          {!loadingMessage && (
            <>
              {hasUpdatedImage && (
                <View>
                  <Button
                    label={t('l.saveImage')}
                    variant={ButtonVariant.Primary}
                    onPress={saveNewImage}
                    size={ButtonSize.Small}
                    style={tw`mt-lg flex-1`}
                  />
                  <Button
                    label={t('l.cancel')}
                    variant={ButtonVariant.Ghost}
                    onPress={() => {
                      setImageWithoutBackground(undefined)
                      setUploadedImage(undefined)
                      setIsModalVisible(false)
                    }}
                    size={ButtonSize.Small}
                    style={tw`mt-lg`}
                  />
                </View>
              )}
              {!hasUpdatedImage && (
                <>
                  <Button
                    label={t('l.loadImage')}
                    variant={ButtonVariant.Secondary}
                    onPress={pickImage}
                    size={ButtonSize.Small}
                    style={tw`m-xs`}
                  />

                  {imageId && (
                    <View>
                      <Button
                        label={t('l.removeBackground')}
                        variant={ButtonVariant.Secondary}
                        onPress={removeBackground}
                        size={ButtonSize.Small}
                        style={tw`m-xs`}
                      />
                      <Button
                        label={t('l.deleteImage')}
                        variant={ButtonVariant.Danger}
                        onPress={() =>
                          setNotification({
                            cancelText: t('l.cancel'),
                            confirmText: t('l.deleteImage'),
                            title: t('l.deleteImage'),
                            type: MeaNotificationType.CONFIRM,
                            description: t('l.sureToDeleteImageItWillBeLostForEver'),
                            onConfirm: deleteImage,
                          })
                        }
                        size={ButtonSize.Small}
                        style={tw`m-xs`}
                      />
                    </View>
                  )}
                </>
              )}
            </>
          )}
        </View>
      </BaseModal>
    </View>
  )
}
