import { Theme } from '@mea-menu/components'
import { Appearance } from 'react-native'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

interface ThemeState {
  colorMode: Theme
}

interface ThemeStore extends ThemeState {
  setColorMode: (colorMode: Theme) => void
  clearStore: () => void
}

const initialState: ThemeState = { colorMode: Appearance.getColorScheme() === 'dark' ? Theme.DARK : Theme.LIGHT }

export const useThemeStore = create<ThemeStore>()(
  persist(
    (set, _) => ({
      ...initialState,
      setColorMode(colorMode) {
        set({ colorMode })
      },
      clearStore() {
        set(initialState)
      },
    }),
    {
      name: 'meamenumanager.theme',
      storage: createJSONStorage(() => localStorage),
    }
  )
)
