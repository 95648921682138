import { BaseModal, Button, ButtonSize, ButtonVariant, Card, IconButton, useTw } from '@mea-menu/components'
import { useIsFocused } from '@react-navigation/native'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, Text, View } from 'react-native'
import { DishCategoryEntityService } from '../api/employee/DishCategoryEntityService'
import { DishEntityService } from '../api/employee/DishEntityService'
import { IngredientEntityService } from '../api/employee/IngredientEntityService'
import { MenuEntityService } from '../api/employee/MenuEntityService'
import { LoadingIndicator, Screen } from '../components'
import { MeaTooltip } from '../components/core/tooltip/MeaTooltip'
import { MenuSmartOptionsModal } from '../components/modals/MenuSmartOptionsModal'
import { MenuStackScreenProps } from '../navigation'
import { useMenuStore, useRestaurantStore, useUserStore } from '../store'
import { useRecipeStore } from '../store/recipeStore'
import { Menu, MenuPreview, MenuStatus, TooltipOrderMenuTypes } from '../types'
import { unique } from '../types/utils'
import { showToast, translateLocal } from '../utils'
import { getDishesFromDishesIds } from '../utils/dishHelpers'
import { meaErrorHandler } from '../utils/MeaErrorHandler'

export function MenuSelectScreen({ navigation, route }: MenuStackScreenProps<'MenuSelectScreen'>) {
  const { tw } = useTw()
  const isFocused = useIsFocused()
  const { t, i18n } = useTranslation()

  const { restaurant, fetchRestaurant } = useRestaurantStore()
  const { setMenu, setUnavailableRecipeIds } = useMenuStore()
  const { fetchRecipes } = useRecipeStore()
  const { employee } = useUserStore()

  const [loading, setloading] = useState<boolean>(false)
  const [newMenuModeModalOpen, setNewMenuModeModalOpen] = useState<boolean>(false)
  const [smartModalMenu, setSmartModalMenu] = useState<MenuPreview | undefined>(undefined)

  const selectMenu = async (mode: 'manageMenuDishes' | 'edit', menu?: MenuPreview) => {
    if (!menu) return
    if (!restaurant) return
    const menuId = menu._id!
    setloading(true)
    try {
      const [dishes, dishCategories, ingredients] = await Promise.all([
        await DishEntityService.advancedSearch({
          equal: { and: [{ field: 'menuId', value: menuId }] },
          sort: [{ field: 'defaultSort', direction: 1 }],
        }),
        await DishCategoryEntityService.getByRestaurantId(restaurant._id),
        await IngredientEntityService.getByRestaurantId(restaurant._id), // TODO avoid fetching all of them. Maybe fetch those when opening ingredients related screen?
      ])

      const selectedMenu: Menu = {
        ...menu,
        dishes,
        dishCategories: dishCategories.reduce((acc, category) => ({ ...acc, [category.key]: category }), {}),
        ingredients: ingredients.reduce((acc, ingredient) => ({ ...acc, [ingredient._id]: ingredient }), {}),
        recipeLikesMap: {}, // TODO verify
        unavailableRecipeIds: [], // TODO verify
      }

      const unavailableDishIds = await DishEntityService.getNotAvailableByMenuId(menuId)
      setUnavailableRecipeIds(
        getDishesFromDishesIds(unavailableDishIds, dishes)
          .map(dish => dish.recipeId)
          .filter(unique)
      )
      const recipes = await fetchRecipes()

      selectedMenu.dishes.forEach(dish => {
        const relatedRecipe = recipes.find(recipe => recipe._id === dish.recipeId)
        if (relatedRecipe) dish.recipe = relatedRecipe
      })
      setMenu(selectedMenu)

      navigation.navigate(mode === 'manageMenuDishes' ? 'ManageMenuScreen' : 'MenuScreen', { menuId })
    } catch (e) {
      meaErrorHandler(e, 'FETCH')
    } finally {
      setloading(false)
    }
  }

  const createNewMenu = () => {
    if (!restaurant) return
    if (restaurant.menus.length > 0) setNewMenuModeModalOpen(true)
    else navigation.navigate('MenuScreen', {})
  }

  const updateRestaurantRelatedData = async () => {
    if (!employee) return
    if ((restaurant?.menus.length ?? 0) === 0) setloading(true)
    try {
      await fetchRestaurant(employee.restaurantId)
    } catch (e) {
      meaErrorHandler(e, 'FETCH')
    } finally {
      setloading(false)
    }
  }

  const toggleMenuAvailability = async () => {
    if (!smartModalMenu) return
    const newStatus = smartModalMenu.status === MenuStatus.ACTIVE ? MenuStatus.HIDDEN : MenuStatus.ACTIVE

    let newMenu: Partial<Menu> = {
      _id: smartModalMenu._id,
      restaurantId: smartModalMenu.restaurantId,
      status: newStatus,
    }
    try {
      newMenu = await MenuEntityService.update(newMenu._id!, newMenu)
      showToast(t('l.menuSaved'), 'SUCCESS')
    } catch (e) {
      meaErrorHandler(e, 'UPDATE')
    }
  }

  useEffect(() => {
    if (isFocused) {
      updateRestaurantRelatedData()
    }
  }, [isFocused])

  const MenuItem = ({
    item,
    showTooltip,
    onPress,
    cloning = false,
  }: {
    item: MenuPreview
    showTooltip?: boolean
    onPress: () => void
    cloning?: boolean
  }) => {
    const VerticalMenuButton = () => (
      <IconButton
        style={tw`self-start opacity-100`}
        icon="VerticalMenu"
        size={ButtonSize.Small}
        variant={ButtonVariant.Accent}
        onPress={() => setSmartModalMenu(item)}
      />
    )

    return (
      <Card
        id={'select_menu_' + item._id}
        onPress={onPress}
        style={tw`mt-md flex-row items-center justify-between p-xxs`}
      >
        <View style={tw`opacity-${cloning ? '100' : item.status === MenuStatus.ACTIVE ? '100' : '35'}`}>
          <Text style={tw`textMono py-md px-sm font-bold`}>{translateLocal(item, 'name', i18n.language)}</Text>
        </View>
        {!cloning && (
          <View style={tw`flex-row pr-sm`}>
            {showTooltip ? (
              <MeaTooltip
                uniqueId={TooltipOrderMenuTypes.MENU_OPTIONS}
                text={t('tooltips.menuOptions')}
                position="left"
              >
                <VerticalMenuButton />
              </MeaTooltip>
            ) : (
              <VerticalMenuButton />
            )}
          </View>
        )}
      </Card>
    )
  }

  const NewMenuModeModal = () => {
    if (!restaurant) return
    const [showExisting, setShowExisting] = useState<boolean>(false)

    return (
      <BaseModal
        visible={newMenuModeModalOpen}
        title={showExisting ? t('l.cloneFrom') : t('l.newMenu')}
        onClose={() => setNewMenuModeModalOpen(false)}
      >
        <View>
          {!showExisting ? (
            <View style={tw`flex-row my-xl`}>
              <Button style={tw`flex-1`} label={t('l.cloneExisting')} onPress={() => setShowExisting(true)} />
              <View style={tw`w-md`} />
              <Button
                style={tw`flex-1`}
                label={t('l.createNew')}
                onPress={() => {
                  setNewMenuModeModalOpen(false)
                  navigation.navigate('MenuScreen', {})
                }}
              />
            </View>
          ) : (
            <FlatList
              data={restaurant.menus}
              keyExtractor={(item, index) => item._id ?? index.toString()}
              renderItem={({ item }) => <MenuItem item={item} cloning onPress={() => cloneMenu(item._id)} />}
            />
          )}
        </View>
      </BaseModal>
    )
  }

  const cloneMenu = async (menuId: string) => {
    try {
      setNewMenuModeModalOpen(false)
      setloading(true)
      const clonedMenu = await MenuEntityService.clone(menuId)
      await updateRestaurantRelatedData()
      selectMenu('edit', clonedMenu)
    } catch (e) {
      meaErrorHandler(e, 'UPDATE')
    } finally {
      setloading(false)
    }
  }

  if (!restaurant) return null

  return (
    <Screen padded scrollableOverflow>
      <LoadingIndicator visible={loading} />
      <View style={tw`p-md`}>
        <View style={tw`flex-row justify-between`}>
          <Text style={tw`flex-1 title2 textMono`}>{t('l.menu')}</Text>
          <MeaTooltip uniqueId={TooltipOrderMenuTypes.MENU_CREATE} text={t('tooltips.createMenu')} position="bottom">
            <Button
              label={t('l.menu')}
              icon="Plus"
              variant={ButtonVariant.SecondaryLight}
              size={ButtonSize.Small}
              onPress={createNewMenu}
            />
          </MeaTooltip>
        </View>
        <FlatList
          ListEmptyComponent={<Text style={tw`mt-xl title3 textMono self-center`}>{t('l.noMenuAvailable')}</Text>}
          data={restaurant.menus}
          keyExtractor={(item, index) => item._id ?? index.toString()}
          renderItem={({ item, index }) => (
            <MenuItem item={item} onPress={() => selectMenu('manageMenuDishes', item)} showTooltip={index === 0} />
          )}
        />
      </View>
      <NewMenuModeModal />

      <MenuSmartOptionsModal
        onGoToEditMenuScreen={() => selectMenu('edit', smartModalMenu)}
        onToggleMenuAvailability={toggleMenuAvailability}
        onGoToManageDishesScreen={() => selectMenu('manageMenuDishes', smartModalMenu)}
        onClose={() => {
          setSmartModalMenu(undefined)
          updateRestaurantRelatedData()
        }}
        menu={smartModalMenu}
      />
    </Screen>
  )
}
