import { Icon, IconColor, useTw } from '@mea-menu/components'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { DefaultTheme, LinkingOptions, NavigationContainer, NavigationProp } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, Text, View } from 'react-native'
import { MenuStackParamList, RecipeStackParamList, RestaurantStackParamList, RootStackParamList, TabParamList } from '.'
import { DishEditorScreen } from '../screens/DishEditorScreen'
import { EmployeeDebugScreen } from '../screens/EmployeeDebugScreen'
import { LoginScreen } from '../screens/LoginScreen'
import { ManageMenuScreen } from '../screens/ManageMenuScreen'
import { MenuScreen } from '../screens/MenuScreen'
import { MenuSelectScreen } from '../screens/MenuSelectScreen'
import { NewDishScreen } from '../screens/NewDishScreen'
import { RecipeEditorScreen } from '../screens/RecipeEditorScreen'
import { RecipesScreen } from '../screens/RecipesScreen'
import { RestaurantEditorScreen } from '../screens/RestaurantEditorScreen'
import { RestaurantScreen } from '../screens/RestaurantScreen'
import { useSessionStore, useTooltipStore, useUserStore } from '../store'

const Stack = createStackNavigator<RootStackParamList>()
const Tab = createBottomTabNavigator<TabParamList>()
const MenuStack = createStackNavigator<MenuStackParamList>()
const RecipeStack = createStackNavigator<RecipeStackParamList>()
const RestaurantStack = createStackNavigator<RestaurantStackParamList>()

export const goHome = (navigation: NavigationProp<any>) => {
  navigation.reset({
    index: 0,
    routes: [{ name: 'MenuSelectScreen' }],
  })
}

export const goRecipeList = (navigation: NavigationProp<any>) => {
  navigation.reset({
    index: 0,
    routes: [{ name: 'RecipesScreen' }],
  })
}

export const goToLogin = (navigation: NavigationProp<any>) => {
  navigation.reset({
    index: 0,
    routes: [{ name: 'LoginScreen' }],
  })
}

export const pop = (navigation: NavigationProp<any>) => {
  if (navigation.canGoBack()) {
    navigation.goBack()
  } else {
    goHome(navigation)
  }
}

const TabBarIcon = ({ name, focused }: { name: keyof TabParamList; focused: boolean }) => {
  const { tw } = useTw()
  const { t } = useTranslation()

  const tabBarIcons = {
    RecipeStackNavigator: (s: boolean) => <Icon name="Cloche" color={s ? IconColor.secondary : IconColor.mono} />,
    MenuStackNavigator: (s: boolean) => <Icon name="Receipt" color={s ? IconColor.secondary : IconColor.mono} />,
    RestaurantStackNavigator: (s: boolean) => <Icon name="Person" color={s ? IconColor.secondary : IconColor.mono} />,
  }

  const TabIcon = () => tabBarIcons[name](focused)
  let label = ''
  switch (name) {
    case 'MenuStackNavigator':
      label = t('l.menu')
      break
    case 'RecipeStackNavigator':
      label = t('l.recipes')
      break
    case 'RestaurantStackNavigator':
      label = t('l.restaurant')
      break
  }

  return (
    <View style={tw`mt-[10px] mb-[6px] items-center`}>
      <TabIcon />
      <Text style={tw`mt-[6px] min-w-[50px] ${focused ? 'textSecondary font-bold' : 'textMono'} text-center`}>
        {label}
      </Text>
    </View>
  )
}

const TabNavigator = () => {
  const { setInputLanguage } = useSessionStore()
  const { i18n } = useTranslation()

  return (
    <Tab.Navigator
      initialRouteName={'MenuStackNavigator'}
      screenOptions={({ route }) => ({
        headerShown: false,
        tabBarShowLabel: false,
        tabBarIcon: params => <TabBarIcon name={route.name} focused={params.focused} />,
        tabBarBadge: undefined,
        tabBarStyle: {
          borderColor: 'strokePrimary',
          borderTopWidth: 1,
          backgroundColor: 'fillBackground',
          height: Platform.OS === 'ios' ? 96 : 80,
        },
      })}
      screenListeners={{
        focus: () => setInputLanguage(i18n.language),
      }}
    >
      <Tab.Screen name="MenuStackNavigator" component={MenuStackNavigation} />
      <Tab.Screen name="RecipeStackNavigator" component={RecipeStackNavigation} />
      <Tab.Screen name="RestaurantStackNavigator" component={RestaurantStackNavigator} />
    </Tab.Navigator>
  )
}

const MenuStackNavigation = () => {
  const { setInputLanguage } = useSessionStore()
  const { i18n } = useTranslation()

  return (
    <MenuStack.Navigator
      initialRouteName={'MenuSelectScreen'}
      screenOptions={{
        headerShown: false,
      }}
      screenListeners={{ focus: () => setInputLanguage(i18n.language) }}
    >
      <MenuStack.Screen name="MenuSelectScreen" component={MenuSelectScreen} />
      <MenuStack.Screen name="MenuScreen" component={MenuScreen} />
      <MenuStack.Screen name="ManageMenuScreen" component={ManageMenuScreen} />
      <MenuStack.Screen name="NewDishScreen" component={NewDishScreen} />
      <MenuStack.Screen name="DishEditorScreen" component={DishEditorScreen} />
    </MenuStack.Navigator>
  )
}

const RecipeStackNavigation = () => {
  const { setInputLanguage } = useSessionStore()
  const { i18n } = useTranslation()

  return (
    <RecipeStack.Navigator
      initialRouteName={'RecipesScreen'}
      screenOptions={{
        headerShown: false,
      }}
      screenListeners={{ focus: () => setInputLanguage(i18n.language) }}
    >
      <RecipeStack.Screen name="RecipesScreen" component={RecipesScreen} />
      <RecipeStack.Screen name="RecipeEditorScreen" component={RecipeEditorScreen} />
    </RecipeStack.Navigator>
  )
}

const RestaurantStackNavigator = () => {
  const { setInputLanguage } = useSessionStore()
  const { i18n } = useTranslation()

  return (
    <RestaurantStack.Navigator
      initialRouteName={'RestaurantScreen'}
      screenOptions={{
        headerShown: false,
      }}
      screenListeners={{ focus: () => setInputLanguage(i18n.language) }}
    >
      <RestaurantStack.Screen name="RestaurantScreen" component={RestaurantScreen} />
      <RestaurantStack.Screen name="RestaurantEditorScreen" component={RestaurantEditorScreen} />
    </RestaurantStack.Navigator>
  )
}

export const AppNavigator = () => {
  const { isSessionValid } = useUserStore()
  const { setInputLanguage } = useSessionStore()
  const { i18n } = useTranslation()
  const { tw } = useTw()

  const ref = useRef<any>(null)

  const stackLinking: LinkingOptions<ReactNavigation.RootParamList> = {
    config: {
      screens: {
        LoginScreen: 'login',
        TabNavigation: {
          screens: {
            MenuStackNavigator: {
              path: 'menus',
              screens: {
                MenuSelectScreen: { path: 'select' },
                MenuScreen: { path: ':menuId?' },
                ManageMenuScreen: { path: ':menuId/manage' },
                NewDishScreen: { path: ':menuId/newdish/:categoryKey' },
                DishEditorScreen: { path: ':dishId/editor/:mode?' },
              },
            },
            RecipeStackNavigator: {
              path: 'recipe',
              screens: {
                RecipesScreen: { path: 'select' },
                RecipeEditorScreen: { path: ':recipeId?/editor' },
              },
            },
            RestaurantStackNavigator: {
              path: 'restaurant',
              screens: {
                RestaurantScreen: { path: 'view' },
                RestaurantEditorScreen: { path: 'edit' },
              },
            },
          },
        },
        EmployeeDebugScreen: 'debug',
      },
    },
    prefixes: [],
  }

  useEffect(() => {
    if (!isSessionValid && ref.current) goToLogin(ref.current)
  }, [isSessionValid])

  return (
    <View style={tw`absolute bottom-0 w-full h-full fillBackground`}>
      <NavigationContainer
        ref={ref}
        documentTitle={{ formatter: (options, route) => `Mea Menu Manager` }}
        linking={isSessionValid ? stackLinking : undefined}
        theme={{ ...DefaultTheme, colors: { ...DefaultTheme.colors, background: 'transparent' } }}
      >
        <Stack.Navigator
          initialRouteName={isSessionValid ? 'TabNavigation' : 'LoginScreen'}
          detachInactiveScreens={true}
          screenOptions={{ headerShown: false, gestureEnabled: false }}
          screenListeners={{ focus: () => setInputLanguage(i18n.language) }}
        >
          <Stack.Screen name="LoginScreen" component={LoginScreen} />
          <Stack.Screen name="TabNavigation" component={TabNavigator} />
          <Stack.Screen name="EmployeeDebugScreen" component={EmployeeDebugScreen} />
        </Stack.Navigator>
      </NavigationContainer>
    </View>
  )
}
