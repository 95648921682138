import { Button, ButtonSize, ButtonVariant, Icon, IconButton, IconSize, Theme, useTw } from '@mea-menu/components'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, TextInput, View } from 'react-native'
import { isAxiosErrorFromDomainWithInternalCode } from '../api/HTTPClient'
import { LoadingIndicator, PasswordManagementModal, Screen } from '../components'
import { RootStackScreenProps } from '../navigation'
import { useUserStore } from '../store'
import { MeaError, NotAuthorizedCode } from '../types'
import { meaErrorHandler } from '../utils/MeaErrorHandler'

export function LoginScreen({ navigation, route }: RootStackScreenProps<'LoginScreen'>) {
  const { tw, theme } = useTw()
  const { t } = useTranslation()

  const { employeeLogin } = useUserStore()

  const [loading, setloading] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [visiblePassword, setPasswordVisible] = useState<boolean>(false)
  const [invalidCredentials, setInvalidCredentials] = useState<boolean>(false)
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState<boolean>(false)

  const login = useCallback(
    async (password: string) => {
      if (!email || !password) return
      setloading(true)
      try {
        const employee = await employeeLogin(email, password)
        if (__DEV__) console.log('employee', employee)
        navigation.navigate('TabNavigation', { screen: 'MenuStackNavigator', params: { screen: 'MenuSelectScreen' } })
      } catch (e) {
        if (isAxiosErrorFromDomainWithInternalCode(e, NotAuthorizedCode.FORCE_RESET_PASSWORD)) {
          return setChangePasswordModalOpen(true)
        }
        const error = new MeaError(`loginError - ${(e as Error).message}`, t('errors.loginError'))
        meaErrorHandler(error, true)
        setInvalidCredentials(true)
      } finally {
        setloading(false)
      }
    },
    [email, password]
  )

  return (
    <Screen padded noSettingsIcon>
      <LoadingIndicator visible={loading} />
      <View style={tw`items-center`}>
        <Icon size={IconSize.Large} name="MeaMenu" />
        <Text style={tw`textMono title3 mt-xl ml-sm self-center`}>{t('l.reserved_area')}</Text>
        <TextInput
          defaultValue={''}
          placeholder={t('l.email')}
          keyboardType="email-address"
          textContentType="emailAddress"
          autoComplete="email"
          onChangeText={setEmail}
          style={tw`textMono fillCard rounded-sm p-md mt-xl min-w-70`}
        />
        <View>
          <TextInput
            defaultValue={''}
            placeholder={t('l.password')}
            textContentType="password"
            autoComplete="password"
            secureTextEntry={!visiblePassword}
            onChangeText={setPassword}
            style={tw`textMono fillCard rounded-sm p-md mt-md min-w-70`}
          />
          <IconButton
            style={[
              tw`absolute right-3 bottom-[8px]`,
              { backgroundColor: theme === Theme.LIGHT ? 'rgba(128, 128, 128, 0.6)' : 'rgba(128, 128, 128, 0.6)' },
            ]}
            size={ButtonSize.Small}
            icon={visiblePassword ? 'EyeOpen' : 'EyeClosed'}
            onPress={() => setPasswordVisible(!visiblePassword)}
          />
        </View>
        {invalidCredentials && <Text style={tw`mt-md textError`}>{t('errors.invalid_email_or_password')}</Text>}
        <View style={tw`items-center w-full`}>
          <Button
            variant={ButtonVariant.Primary}
            style={tw`mt-md min-w-40`}
            onPress={() => login(password)}
            label={t('l.login')}
          />
        </View>
      </View>
      <PasswordManagementModal
        label={t('l.passwordChangeRequired')}
        email={email.trim()}
        visible={changePasswordModalOpen}
        onClose={() => {
          setChangePasswordModalOpen(false)
        }}
        onPasswordChanged={newPass => {
          setPassword(newPass)
          login(newPass)
        }}
      />
    </Screen>
  )
}
