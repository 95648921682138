import { AxiosError } from 'axios'
import { produce } from 'immer'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { RecipeEntityService } from '../api/employee/RecipeEntityService'
import { isAxiosErrorFromDomainNotAllowed } from '../api/HTTPClient'
import { DomainError, NotAllowedCode, Recipe } from '../types'
import { getLocalizedField } from '../types/utils'
import { translateLocal } from '../utils'
import { useMediaStore } from './mediaStore'
import { useRestaurantStore } from './restaurantStore'

export function filterRecipes(recipes: Recipe[], searchFilter: string, language: string) {
  const filter = searchFilter.toLowerCase().trim()
  if (filter === '') return recipes
  return recipes.filter(recipe => {
    const name = translateLocal(recipe, 'name', language).toLowerCase()
    return name.includes(filter)
  })
}

export function sortRecipes(recipes: Recipe[], isAscending: boolean, language: string, sorting?: Sorting) {
  if (!sorting) return recipes
  if (sorting.type === SortingType.alphabetical) {
    return recipes.sort((a, b) => {
      const aName = translateLocal(a, 'name', language) || ''
      const bName = translateLocal(b, 'name', language) || ''
      const comparison = aName.localeCompare(bName)
      return isAscending ? comparison * -1 : comparison
    })
  } else if (sorting.type === SortingType.updateDate) {
    return recipes.sort((a, b) => {
      const aDate = a.updatedAt ? new Date(a.updatedAt) : new Date()
      const bDate = b.updatedAt ? new Date(b.updatedAt) : new Date()
      const comparison = aDate.getTime() - bDate.getTime()
      return isAscending ? comparison : comparison * -1
    })
  }
  return recipes
}

export enum SortingType {
  alphabetical = 'alphabetical',
  updateDate = 'updateDate',
}

export interface Sorting {
  type: SortingType
  isAscending: boolean
}

interface RecipeState {
  recipes: Recipe[]
}

export interface RecipeStore extends RecipeState {
  fetchRecipes: () => Promise<Recipe[]>
  getFilteredRecipes: (searchFilter: string, language: string, sorting: Sorting) => Recipe[]
  updateRecipe: (recipeId: string, update: Partial<Recipe>) => Promise<boolean>
  createRecipe: (recipe: Partial<Recipe>, inputLanguage: string, outputLanguages: string[]) => Promise<false | Recipe>
  deleteRecipe: (recipeId: string) => Promise<boolean | NotAllowedCode>
  forceDeleteRecipe: (recipeId: string) => Promise<boolean>
  clearStore: () => void
}

const initialState: RecipeState = {
  recipes: [],
}

export const useRecipeStore = create<RecipeStore>()(
  persist(
    (set, get) => ({
      ...initialState,
      fetchRecipes: async () => {
        const recipes = await RecipeEntityService.advancedSearch({ txtFilter: '' })
        set({ recipes })
        return recipes
      },
      getFilteredRecipes: (searchFilter: string, language: string, sorting: Sorting) => {
        const { recipes } = get()
        return sortRecipes([...filterRecipes(recipes, searchFilter, language)], sorting.isAscending, language, sorting)
      },
      updateRecipe: async (recipeId: string, update: Partial<Recipe>) => {
        const { restaurant } = useRestaurantStore.getState()
        try {
          const updatedRecipe = await RecipeEntityService.update(recipeId, {
            ...update,
            restaurantId: restaurant?._id,
            _id: recipeId,
          })
          if (updatedRecipe.coverId) await useMediaStore.getState().fetchCoverImage(updatedRecipe.coverId)
          set(
            produce((state: RecipeState) => {
              if (!state.recipes) return
              const recipeIndex = state.recipes.findIndex(recipe => recipe._id === recipeId)
              state.recipes[recipeIndex] = updatedRecipe
            })
          )
          return true
        } catch (error) {
          console.error(error)
          return false
        }
      },
      createRecipe: async (recipe: Partial<Recipe>, inputLanguage: string, outputLanguages: string[]) => {
        const { restaurant } = useRestaurantStore.getState()
        const { recipes } = get()

        try {
          const currentName = (recipe as any)[getLocalizedField('name', inputLanguage)]
          let createdRecipe = await RecipeEntityService.create({
            ...recipe,
            restaurantId: restaurant?._id,
            nameIt: currentName,
            nameEn: currentName,
            nameDe: currentName,
            nameEs: currentName,
            nameFr: currentName,
            nameJa: currentName,
            nameRu: currentName,
            nameZh: currentName,
          })

          const description: string | undefined = (recipe as any)[getLocalizedField('description', inputLanguage)]
          if (description && description.trim().length > 0) {
            const translations = await RecipeEntityService.translate(
              createdRecipe._id,
              getLocalizedField('description', inputLanguage),
              outputLanguages
            )
            createdRecipe = { ...createdRecipe, ...translations }
          }

          const recipeIndex = recipes.findIndex(r => r._id === recipe._id)
          set(
            produce((state: RecipeState) => {
              if (recipeIndex !== -1) state.recipes[recipeIndex] = createdRecipe
              else state.recipes.push(createdRecipe)
            })
          )
          return createdRecipe
        } catch (error) {
          console.error(error)
          return false
        }
      },
      deleteRecipe: async (recipeId: string) => {
        try {
          await RecipeEntityService.delete(recipeId)
          get().fetchRecipes()
          return true
        } catch (error) {
          console.error(error)
          if (isAxiosErrorFromDomainNotAllowed(error)) {
            return ((error as AxiosError).response!.data as DomainError).internalCode as NotAllowedCode
          }
          return false
        }
      },
      forceDeleteRecipe: async (recipeId: string) => {
        try {
          await RecipeEntityService.forceDelete(recipeId)
          get().fetchRecipes()
          return true
        } catch (error) {
          console.error(error)
          return false
        }
      },
      clearStore: () => set(initialState),
    }),
    {
      name: 'meamenumanager.recipes',
      storage: createJSONStorage(() => localStorage),
    }
  )
)
