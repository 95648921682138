import { produce } from 'immer'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { MediaService } from '../api/employee/MediaService'

interface MediaState {
  // map cover id to cover url
  coverImageMap: Record<string, string | undefined>
}

export interface MediaStore extends MediaState {
  fetchCoverImage: (id: string) => Promise<string | undefined>
  clearStore: () => void
}

const initialState: MediaState = {
  coverImageMap: {},
}

export const useMediaStore = create<MediaStore>()(
  persist(
    (set, get) => ({
      ...initialState,
      fetchCoverImage: async id => {
        const { coverImageMap } = get()
        let result = coverImageMap[id]
        if (result) return result

        try {
          const media = await MediaService.getById(id)
          result = media.url
        } catch (error) {
          result = undefined
        }

        set(
          produce((store: MediaState) => {
            store.coverImageMap[id] = result
          })
        )
        return result
      },

      clearStore: () => set(initialState),
    }),
    {
      name: 'meamenumanager.media',
      storage: createJSONStorage(() => localStorage),
    }
  )
)
