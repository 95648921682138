import { t } from 'i18next'
import { Dish, MeaError, TranslatableCaption, TranslatableDescription } from '../../types'
import { backendClient, noResponse } from '../HTTPClient'
import { TranslatableEntityService } from '../TranslatableEntityService'

export interface UpdateMenuSortingItem {
  dishId: string
  dishCategoryKey: string
  dishSubCategoryKey?: string
}

export class DishEntityService extends TranslatableEntityService<Dish>() {
  static entityPath: string = 'dish'

  static async getNotAvailableByMenuId(menuId: string): Promise<string[]> {
    const response = await backendClient.get<{ _id: string }[]>(`${this.entityPath}/notAvailable/${menuId}`)
    if (noResponse(response)) {
      throw new MeaError('cannotGetNotAvailableDishes', t('errors.cannotGetNotAvailableDishes'))
    }
    return response.data.map(dish => dish._id)
  }

  static async updateMenuSorting({ orderedDishes }: { orderedDishes: UpdateMenuSortingItem[] }): Promise<boolean> {
    const response = await backendClient.post<{ ok: boolean }>(
      `${this.entityPath}/updatecatsort`,
      orderedDishes.map(dishItem => ({
        _id: dishItem.dishId,
        dishCategory: dishItem.dishCategoryKey,
        dishSubCategory: dishItem.dishSubCategoryKey ?? null,
      }))
    )
    if (noResponse(response)) {
      throw new MeaError('cannotUpdateMenuSorting', t('errors.cannotUpdateMenuSorting'))
    }
    return response.data.ok
  }
}
