import { Button, useTw } from '@mea-menu/components'
import { useState } from 'react'
import { Text, View } from 'react-native'
import { useUserStore } from '../../store'
import { MeaError } from '../../types'
import { parseJwtToken } from '../../utils/jwt'

interface Props {
  exitDevMode: () => void
}

export function DevPage({ exitDevMode }: Props) {
  const { tw } = useTw()
  const [viewDataModeEnabled, setViewDataModeEnabled] = useState(false)
  const { employee, accessToken } = useUserStore()

  if (viewDataModeEnabled) {
    const token = accessToken ? parseJwtToken(accessToken) : undefined

    return (
      <View>
        <Text style={tw`title2 text-center mt-md mb-xl`}>App data</Text>
        <Text>Employee</Text>
        {employee && (
          <View>
            <Text>{`Id: ${employee._id}`}</Text>
            <Text>{`Email: ${employee.email}`}</Text>
            <Text>{`Status: ${employee.status}`}</Text>
          </View>
        )}
        <Text style={tw`mt-lg`}>Access token</Text>
        {token ? (
          <View>
            <Text>{`iat: ${new Date(token.iat * 1000)}`}</Text>
            <Text>{`exp: ${new Date(token.exp * 1000)}`}</Text>
          </View>
        ) : (
          <Text>Not found</Text>
        )}
        <View style={tw`mt-xl`}>
          <Button label="GO BACK" onPress={() => setViewDataModeEnabled(false)} />
        </View>
      </View>
    )
  }

  return (
    <View style={tw`justify-center items-center`}>
      <Text style={tw`title2 mt-md mb-xl text-center`}>Dev Mode</Text>
      <Button
        label="Throw error"
        onPress={() => {
          throw new MeaError('devPageTestError')
        }}
      />
      <Button
        label="View data"
        onPress={() => {
          setViewDataModeEnabled(true)
        }}
      />
      <Button
        label="Reset data"
        onPress={() => {
          localStorage.clear()
          exitDevMode()
        }}
      />
      <Button label="GO BACK" onPress={exitDevMode} />
    </View>
  )
}
