import { Button, ButtonSize, ButtonVariant, useTw } from '@mea-menu/components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { DishEntityService } from '../api/employee/DishEntityService'
import { RecipeEntityService } from '../api/employee/RecipeEntityService'
import { LoadingIndicator, Screen } from '../components'
import { DishAndRecipeEditorFragment, EditingDish } from '../fragments/DishAndRecipeEditorFragment'
import { MenuStackScreenProps } from '../navigation'
import { pop } from '../navigation/Navigator'
import { useMenuStore, useNotificationsStore } from '../store'
import { useRecipeStore } from '../store/recipeStore'
import { Dish, MeaNotificationType } from '../types'
import { showToast } from '../utils'
import { meaErrorHandler } from '../utils/MeaErrorHandler'

export interface DishEditorScreenProps {
  dishId?: string
  mode?: DishEditorScreenMode
}

export type DishEditorScreenMode = 'variant'

export function DishEditorScreen({ navigation, route }: MenuStackScreenProps<'DishEditorScreen'>) {
  const { dishId, mode } = route.params ?? {}

  const { menu, fetchDishes } = useMenuStore()
  const { recipes } = useRecipeStore()
  const { setNotification } = useNotificationsStore()
  const { t } = useTranslation()
  const { tw } = useTw()
  const [dish, setDish] = useState<Partial<Dish>>()

  if (!menu || !menu.dishes) return null

  const { dishes } = menu

  const initDish = async () => {
    try {
      let dish: Partial<Dish> = dishes.find(dish => dish._id === dishId) as Partial<Dish>
      if (dish && !dish.recipe && dish.recipeId) {
        const recipe = await RecipeEntityService.getById(dish.recipeId)
        dish = { ...dish, recipe }
      }
      if (
        dish &&
        dish.recipe &&
        dish.recipe.ingredientsId.length > 0 &&
        (!dish.recipe.ingredients || dish.recipe.ingredients.length === 0)
      ) {
        const recipe = await RecipeEntityService.getById(dish.recipe._id ?? dish.recipeId)
        dish = { ...dish, recipe: { ...dish.recipe, ingredients: recipe.ingredients } }
      }

      if (dish && mode === 'variant') {
        dish = { ...dish, _id: undefined }
      }

      setDish(dish)
    } catch (e) {
      meaErrorHandler(e, 'FETCH')
    }
  }

  useEffect(() => {
    initDish()
  }, [])

  return (
    <Screen noRestaurantName padded showBackButton showInputLanguageChooser>
      {!dish ? (
        <LoadingIndicator visible />
      ) : (
        <>
          <View style={tw`flex-1`}>
            <DishAndRecipeEditorFragment dish={dish as EditingDish} onDishAndRecipeSaved={() => navigation.pop()}>
              {mode !== 'variant' && (
                <Button
                  style={tw`mb-md`}
                  variant={ButtonVariant.DangerLight}
                  size={ButtonSize.Small}
                  label={t('l.deleteDish')}
                  onPress={() => {
                    setNotification({
                      title: t('l.warning'),
                      description: t('l.deleteDishConfirm'),
                      type: MeaNotificationType.CONFIRM,
                      onConfirm: async () => {
                        if (!dish._id) return
                        try {
                          await DishEntityService.delete(dish._id)
                          showToast(t('l.dishDeletedSuccesfully'), 'SUCCESS')
                          await fetchDishes()
                          pop(navigation)
                        } catch (e) {
                          meaErrorHandler(e, 'UPDATE')
                        }
                      },
                    })
                  }}
                />
              )}
            </DishAndRecipeEditorFragment>
          </View>
          {/* {screenMode === 'dish' && (
              <DishEditorFragment
                title={mode === 'variant' ? t('l.addDishVariant') : dish.recipe?.nameIt}
                dish={dish}
                onDishSaved={() => pop(navigation)}
              />
              <Button
                style={tw`mb-md`}
                variant={ButtonVariant.DangerLight}
                size={ButtonSize.Small}
                label={t('l.deleteDish')}
                onPress={() => {
                  setNotification({
                    title: t('l.warning'),
                    description: t('l.deleteDishConfirm'),
                    type: MeaNotificationType.CONFIRM,
                    onConfirm: async () => {
                      if (!dish._id) return
                      try {
                        await DishEntityService.delete(dish._id)
                        showToast(t('l.dishDeletedSuccesfully'), 'SUCCESS')
                        await fetchDishes()
                        pop(navigation)
                      } catch (e) {
                        meaErrorHandler(e, 'UPDATE')
                      }
                    },
                  })
                }}
              />
              <Button
                style={tw`mb-md`}
                variant={ButtonVariant.SecondaryLight}
                size={ButtonSize.Small}
                label={t('l.editRecipe')}
                onPress={async () => {
                  if (
                    dish &&
                    dish.recipe &&
                    dish.recipe.ingredientsId.length > 0 &&
                    (!dish.recipe.ingredients || dish.recipe.ingredients.length === 0)
                  ) {
                    const recipe = await RecipeEntityService.getById(dish.recipe._id ?? dish.recipeId)
                    setDish({ ...dish, recipe: { ...dish.recipe, ingredients: recipe.ingredients } })
                  }
                  setScreenMode('recipe')
                }}
              />
          )}
          {screenMode === 'recipe' && (
            <RecipeEditorFragment
              recipe={dish.recipe ?? (recipes.find(recipe => recipe._id === dish.recipeId) as Partial<Recipe>)}
              onRecipeSaved={() => fetchDishes()}
            />
          )} */}
        </>
      )}
    </Screen>
  )
}
