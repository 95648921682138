export const Images = {
  celery: require('./celery.png'),
  clams: require('./clams.png'),
  crab: require('./crab.png'),
  egg: require('./egg.png'),
  fish: require('./fish.png'),
  flag_en: require('./flag_en.png'),
  flag_it: require('./flag_it.png'),
  flag_zh: require('./flag_zh.png'),
  flag_de: require('./flag_de.png'),
  flag_fr: require('./flag_fr.png'),
  flag_es: require('./flag_es.png'),
  flag_ru: require('./flag_ru.png'),
  flag_ja: require('./flag_ja.png'),
  gluten: require('./gluten.png'),
  lupin: require('./lupin.png'),
  meat: require('./meat.png'),
  milk: require('./milk.png'),
  mustard: require('./mustard.png'),
  nuts: require('./nuts.png'),
  peanut: require('./peanut.png'),
  sesame: require('./sesame.png'),
  soybean: require('./soybean.png'),
  sulfide: require('./sulfide.png'),
  empty_cart: require('./empty_cart.png'),
  illustration_intolerance: require('./illustration_intolerance.png'),
  illustration_order: require('./illustration_order.png'),
  illustration_waiter: require('./illustration_waiter.png'),
  heart_bg: require('./heart_bg.png'),
  placeholder: require('./placeholder.png'),
}
