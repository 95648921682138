import { MeaMedia } from '../../types'
import { BaseEntityService } from '../BaseEntityService'
import { backendClient, noResponse } from '../HTTPClient'

export class MediaService extends BaseEntityService<MeaMedia>() {
  static entityPath: string = 'media'

  // after the upload the connection between the recipe and the media is made
  // setting the field coverId of the recipe to the id of the media
  static async upload(file: File): Promise<MeaMedia> {
    const formData = new FormData()
    formData.append('file', file)
    const response = await backendClient.post(`${this.entityPath}/upload`, formData)
    if (noResponse(response)) {
      throw new Error('Error uploading file')
    }
    return response.data[0]
  }

  // after the operation a new media is returned with the id of the cropped image
  static async removeBackgroundAndCrop(mediaId: string): Promise<MeaMedia> {
    const response = await backendClient.post(`${this.entityPath}/removeBackgroundAndCrop/${mediaId}`)
    if (noResponse(response)) {
      throw new Error('Error removing background')
    }
    return response.data
  }
}
