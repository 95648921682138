import { Icon, useTw } from '@mea-menu/components'
import { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Animated, TextInput, View } from 'react-native'
import { Clickable } from './'

export interface SearchBarProps {
  value: string
  onTextChange: (text: string) => void
  onSumbit?: () => void
}

export function SearchBar({ value, onTextChange, onSumbit }: SearchBarProps) {
  const { tw, theme } = useTw()

  const inputRef = useRef<TextInput>(null)
  const { t } = useTranslation()
  const animatedValue = useMemo(() => new Animated.Value(1), [])

  return (
    <View style={[tw`flex-row flex-1 items-center py-xxs px-sm rounded-sm fillCard`]}>
      <Icon name="Search" />
      <TextInput
        onSubmitEditing={onSumbit}
        style={[tw`textMono px-sm py-xs w-full`, { outlineStyle: 'none' }]}
        value={value}
        ref={inputRef}
        onChangeText={newText => {
          onTextChange(newText)
        }}
        placeholder={t('l.search')}
        placeholderTextColor={theme === 'light' ? 'black' : 'white'}
      />
      {value.length > 0 && (
        <Clickable
          id="clear_search"
          onPress={() => {
            onTextChange('')
          }}
          style={tw`ml-md`}
        >
          <Icon name="Close" />
        </Clickable>
      )}
    </View>
  )
}
