import {
  BaseModal,
  Button,
  ButtonSize,
  ButtonState,
  ButtonVariant,
  Card,
  IconButton,
  useTw,
} from '@mea-menu/components'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, Image, ScrollView, Text, View } from 'react-native'
import { IngredientEntityService } from '../api/employee/IngredientEntityService'
import { LoadingIndicator } from '../components'
import { ImageInput } from '../components/core/ImageInput'
import { MeaTextInput } from '../components/core/MeaTextInput'
import { MeaTooltip } from '../components/core/tooltip/MeaTooltip'
import { SelectIngredientModal } from '../components/modals/SelectIngredientModal'
import { useMenuStore, useRestaurantStore, useSessionStore } from '../store'
import { useMediaStore } from '../store/mediaStore'
import { useRecipeStore } from '../store/recipeStore'
import { Ingredient, MeaMedia, Recipe, TooltipOrderRecipeTypes } from '../types'
import { getIngredientAllergens, getLocalizedField } from '../types/utils'
import { showToast, translateLocal } from '../utils'
import { deepEquals } from '../utils/commonHelpers'
import { meaErrorHandler } from '../utils/MeaErrorHandler'

export interface RecipeEditorFragmentProps {
  recipe: Partial<Recipe>
  onRecipeSaved?: (recipe: Recipe) => void
}

export function RecipeEditorFragment({ recipe, onRecipeSaved }: RecipeEditorFragmentProps) {
  const { t, i18n } = useTranslation()
  const { tw } = useTw()

  const { coverImageMap } = useMediaStore()
  const { restaurant } = useRestaurantStore()
  const { menu } = useMenuStore()
  const { updateRecipe, createRecipe, fetchRecipes } = useRecipeStore()
  const { inputLanguage, setInputLanguage, getAllOtherInputLanguages } = useSessionStore()
  const scrollViewRef = useRef<ScrollView>(null)

  const [loading, setLoading] = useState<boolean>(false)
  const [isIngredientModalVisible, setIsIngredientModalVisible] = useState<boolean>(false)
  const [renamingIngredient, setRenamingIngredient] = useState<Ingredient>()
  const [uploadedCover, setUploadedCover] = useState<MeaMedia>()

  const [originalRecipe, setOriginalRecipe] = useState<Partial<Recipe>>(recipe)
  const [editingRecipe, setEditingRecipe] = useState<Partial<Recipe>>(originalRecipe)
  const hasPendingEdits = useMemo(
    () => !recipe._id || !deepEquals(editingRecipe, originalRecipe),
    [editingRecipe, originalRecipe]
  )
  const recipeName = translateLocal(editingRecipe, 'name', inputLanguage, false)

  const saveRecipe = async () => {
    let recipeSaved: Recipe | boolean
    const savingRecipe: Partial<Recipe> = {
      [getLocalizedField('name', inputLanguage)]: recipeName.trim(),
      [getLocalizedField('description', inputLanguage)]: (
        translateLocal(editingRecipe, 'description', inputLanguage, false) ?? ''
      ).trim(),
      coverId: uploadedCover?._id ?? editingRecipe.coverId ?? null,
      ingredientBrandMapping: undefined,
      ingredientsId: (editingRecipe.ingredients ?? []).map(ingredient => ingredient._id),
      parentId: editingRecipe.parentId,
    }

    if (!editingRecipe.system && editingRecipe._id) {
      recipeSaved = await updateRecipe(editingRecipe._id, savingRecipe)
    } else {
      recipeSaved = await createRecipe(savingRecipe, inputLanguage, getAllOtherInputLanguages())
    }

    if (recipeSaved) {
      recipeSaved = recipeSaved === true ? (editingRecipe as Recipe) : recipeSaved
      setOriginalRecipe(recipeSaved)
      setEditingRecipe(recipeSaved)
      onRecipeSaved?.(recipeSaved)
      showToast(t('l.recipeSaved'), 'SUCCESS')
    } else {
      showToast(t('l.failToSaveRecipe'), 'ERROR')
    }
  }

  const renameIngredient = async (ingredientId: string, submittedText: string) => {
    if (
      !restaurant ||
      !menu ||
      !renamingIngredient ||
      !editingRecipe.ingredients ||
      editingRecipe.ingredients.length === 0 ||
      !submittedText ||
      submittedText.length === 0
    )
      return
    const text = submittedText.trim()
    if (text.length === 0) return
    try {
      const updatedIngredient = await IngredientEntityService.update(ingredientId, {
        [getLocalizedField('name', inputLanguage)]: text,
        _id: ingredientId,
        restaurantId: restaurant._id,
      })
      const ingredientIdx = editingRecipe.ingredients.findIndex(ingredient => ingredient._id === ingredientId)
      if (ingredientIdx === -1) return
      setEditingRecipe({
        ...editingRecipe,
        ingredients: [
          ...editingRecipe.ingredients.slice(0, ingredientIdx),
          updatedIngredient,
          ...editingRecipe.ingredients.slice(ingredientIdx + 1),
        ],
      })
      setRenamingIngredient(undefined)
      await fetchRecipes()
    } catch (e) {
      meaErrorHandler(e, 'UPDATE')
    }
  }

  const RenameIngredientModal = () => {
    if (!menu || !renamingIngredient) return null
    const [renamingIngredientText, setRenamingIngredientText] = useState<string>()
    const originalTranslatedName = translateLocal(renamingIngredient, 'name', inputLanguage)

    return (
      <BaseModal
        visible={!!renamingIngredient}
        title={inputLanguage === i18n.language ? t('l.rename') : t('l.translate')}
        onClose={() => setRenamingIngredient(undefined)}
      >
        <View style={tw`justify-between`}>
          <MeaTextInput
            placeHolder={t('l.ingredient')}
            showInputLocaleFlag
            autoFocus
            initialValue={originalTranslatedName}
            onChangeText={setRenamingIngredientText}
          />
          <Button
            style={tw`mt-md`}
            state={
              !renamingIngredientText ||
              renamingIngredientText.trim().length < 3 ||
              renamingIngredientText.trim() === originalTranslatedName.trim()
                ? ButtonState.Disabled
                : ButtonState.Default
            }
            size={ButtonSize.Small}
            variant={ButtonVariant.Primary}
            label={t('l.save')}
            onPress={async () => {
              if (!renamingIngredientText) return
              await renameIngredient(renamingIngredient._id, renamingIngredientText)
              setRenamingIngredientText(undefined)
            }}
          />
        </View>
      </BaseModal>
    )
  }

  return (
    <>
      <View style={tw`flex-row justify-between p-sm`}>
        <Text style={tw`title2 textMono h-[40px]`} numberOfLines={1}>
          {recipeName}
        </Text>
        {(hasPendingEdits || editingRecipe.system) && (
          <Button
            state={recipeName.trim().length >= 3 ? ButtonState.Default : ButtonState.Disabled}
            size={ButtonSize.Small}
            label={t('l.save')}
            onPress={saveRecipe}
            variant={ButtonVariant.Secondary}
          />
        )}
      </View>
      <ScrollView ref={scrollViewRef} style={tw`p-sm`}>
        <MeaTextInput
          label={t('l.recipeName')}
          placeHolder={t('l.recipeName')}
          showInputLocaleFlag
          initialValue={translateLocal(originalRecipe, 'name', inputLanguage)}
          onChangeText={text =>
            setEditingRecipe({ ...editingRecipe, [getLocalizedField('name', inputLanguage)]: text })
          }
        />
        <MeaTextInput
          label={t('l.recipeDescription')}
          placeHolder={t('l.recipeDescription')}
          showInputLocaleFlag
          initialValue={translateLocal(originalRecipe, 'description', inputLanguage, false)}
          onChangeText={text =>
            setEditingRecipe({ ...editingRecipe, [getLocalizedField('description', inputLanguage)]: text })
          }
          style={tw`mt-md`}
          numberOfLines={6}
        />
        <View>
          <Text style={tw`textMono label mt-md mb-sm`}>{t('l.image')}</Text>
          <MeaTooltip
            uniqueId={TooltipOrderRecipeTypes.RECIPE_ADD_IMAGE}
            text={t('tooltips.recipeImage')}
            position="top"
            scrollViewRef={scrollViewRef}
            removeWidthWrapper
          >
            <ImageInput
              currentImageUri={editingRecipe.coverId ? coverImageMap[editingRecipe.coverId] : uploadedCover?._id}
              modalTitle={recipeName}
              imageId={editingRecipe.coverId ?? uploadedCover?._id}
              saveImage={async image => {
                setEditingRecipe({ ...editingRecipe, coverId: image._id })
                setUploadedCover(image)
              }}
              onImageRemoved={() => {
                setEditingRecipe({ ...editingRecipe, coverId: null })
                setUploadedCover(undefined)
              }}
            />
          </MeaTooltip>
        </View>
        <>
          <View style={tw`flex-row justify-between items-center mt-md`}>
            <Text style={tw`textMono title3`}>{t('l.ingredients')}</Text>
            <MeaTooltip
              uniqueId={TooltipOrderRecipeTypes.RECIPE_ADD_INGREDIENT}
              text={t('tooltips.recipeIngredients')}
              position="top"
              scrollViewRef={scrollViewRef}
              removeWidthWrapper
            >
              <Button
                label={t('l.ingredient')}
                icon="Plus"
                size={ButtonSize.Small}
                onPress={() => {
                  setInputLanguage(i18n.language)
                  setIsIngredientModalVisible(true)
                }}
                variant={ButtonVariant.SecondaryLight}
              />
            </MeaTooltip>
          </View>
          <FlatList
            data={editingRecipe.ingredients}
            renderItem={({ item }) => {
              const ingredientAllergens = getIngredientAllergens(item.allergens)
              return (
                <Card style={tw`flex-row mt-xs h-lg items-center`} onPress={() => setRenamingIngredient(item)}>
                  <Text style={tw`textMono title4`}>{translateLocal(item, 'name', inputLanguage)}</Text>
                  {ingredientAllergens.length > 0 && (
                    <View style={tw`flex-row ml-sm`}>
                      {ingredientAllergens.map(allergen => (
                        <View key={allergen._id} style={tw`mr-xs`}>
                          <Image source={allergen.imageUri} style={tw`w-md h-md`} />
                        </View>
                      ))}
                    </View>
                  )}
                  <View style={tw`flex-1 items-end ml-sm`}>
                    <IconButton
                      icon="Minus"
                      onPress={() =>
                        setEditingRecipe({
                          ...editingRecipe,
                          ingredients: [
                            ...(editingRecipe.ingredients ?? []).filter(ingredient => ingredient._id !== item._id),
                          ],
                          ingredientsId: [
                            ...(editingRecipe.ingredientsId ?? []).filter(ingredientId => ingredientId !== item._id),
                          ],
                        })
                      }
                      key={item._id}
                      size={ButtonSize.XSmall}
                      state={ButtonState.Error}
                    />
                  </View>
                </Card>
              )
            }}
            ListFooterComponent={<View style={tw`h-10`}></View>}
          />
        </>
      </ScrollView>
      <SelectIngredientModal
        onSelect={ingredient => {
          setIsIngredientModalVisible(false)
          setEditingRecipe({
            ...editingRecipe,
            ingredients: [ingredient, ...(editingRecipe.ingredients ?? [])],
            ingredientsId: [ingredient._id, ...(editingRecipe.ingredientsId ?? [])],
          })
        }}
        isVisible={isIngredientModalVisible}
        onClose={() => setIsIngredientModalVisible(false)}
      />
      <RenameIngredientModal />
      <LoadingIndicator visible={loading} />
    </>
  )
}
