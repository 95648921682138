import { BaseModal, Button, ButtonSize, ButtonVariant, useTw } from '@mea-menu/components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'
import { EmployeeAuthService } from '../../api/employee/EmployeeAuthService'
import { isAxiosErrorFromDomainWithInternalCode } from '../../api/HTTPClient'
import { NotAuthorizedCode } from '../../types'
import { showToast } from '../../utils'
import { meaErrorHandler } from '../../utils/MeaErrorHandler'
import { MeaTextInput } from '../core/MeaTextInput'

type Disclaimer = { label: string; error: boolean }

const MIN_PASSWORD_LENGTH = 8

export interface PasswordManagementModalProps {
  visible: boolean
  onClose: () => void
  label?: string
  email: string
  onPasswordChanged: (newPassword: string) => void
}

export const PasswordManagementModal = ({
  visible,
  label,
  email,
  onClose,
  onPasswordChanged,
}: PasswordManagementModalProps) => {
  const { tw } = useTw()
  const { t } = useTranslation()

  const ORIGINAL_DISCLAIMER: Disclaimer = {
    label: t('l.changePasswordDisclaimer', { minPasswordChars: MIN_PASSWORD_LENGTH }),
    error: false,
  }
  const [oldPassword, setOldPassword] = useState<string>('')
  const [newPassword, setnewPassword] = useState<string>('')
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('')
  const [disclaimer, setDisclaimer] = useState<Disclaimer>(ORIGINAL_DISCLAIMER)

  useEffect(() => {
    if (!disclaimer.error) return
    setTimeout(() => setDisclaimer(ORIGINAL_DISCLAIMER), 5000)
  }, [disclaimer])

  const setErrorDisclaimer = (label: string) => setDisclaimer({ label, error: true })

  const changePassword = async (oldPass: string, newPass: string, confirmNew: string) => {
    if (!email) return
    const trimmedOld = oldPass.trim()
    const trimmedNew = newPass.trim()
    const trimmedConfirmNew = confirmNew.trim()

    if (trimmedOld.length === 0) return setErrorDisclaimer(t('errors.youMustInsertCurrentPassword'))
    if (trimmedNew.length === 0) return setErrorDisclaimer(t('errors.youMustInsertNewPassword'))
    if (trimmedConfirmNew.length === 0) return setErrorDisclaimer(t('errors.youMustInsertNewConfirmPassword'))
    if (trimmedNew !== trimmedConfirmNew) return setErrorDisclaimer(t('errors.newConfirmPasswordMismatch'))
    if (trimmedNew === trimmedOld) return setErrorDisclaimer(t('errors.oldAndNewPasswordsAreEqual'))
    if (trimmedNew.length < MIN_PASSWORD_LENGTH)
      return setErrorDisclaimer(t('errors.passwordTooShort', { min: MIN_PASSWORD_LENGTH }))
    if (!/[a-z]/.test(trimmedNew)) return setErrorDisclaimer(t('errors.passwordMustContainLowercase'))
    if (!/[A-Z]/.test(trimmedNew)) return setErrorDisclaimer(t('errors.passwordMustContainUppercase'))
    if (!/\d/.test(trimmedNew)) return setErrorDisclaimer(t('errors.passwordMustContainNumbers'))
    if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(trimmedNew))
      return setErrorDisclaimer(t('errors.passwordMustContainSymbols'))

    try {
      await EmployeeAuthService.changePassword(email, trimmedOld, trimmedNew)
      onClose()
      showToast(t('l.passwordChangedSuccesfully'), 'SUCCESS')
      onPasswordChanged(trimmedNew)
    } catch (e) {
      if (isAxiosErrorFromDomainWithInternalCode(e, NotAuthorizedCode.INVALID_CREDENTIALS))
        return setErrorDisclaimer(t('errors.invalidOldPassword'))
      meaErrorHandler(e, true)
    }
  }

  return (
    <BaseModal visible={visible} onClose={onClose} title={label ?? t('l.changePassword')}>
      <View style={tw`h-[80px] justify-center`}>
        <Text style={tw`${disclaimer.error ? 'textError' : 'textMono'}`}>{disclaimer.label}</Text>
      </View>
      <MeaTextInput
        label={t('l.currentPassword')}
        style={tw`mt-md`}
        autoFocus
        placeHolder={t('l.currentPassword')}
        onChangeText={setOldPassword}
      />
      <MeaTextInput
        label={t('l.newPassword')}
        style={tw`mt-md`}
        placeHolder={t('l.newPassword')}
        onChangeText={setnewPassword}
      />
      <MeaTextInput
        label={t('l.confirmNewPassword')}
        style={tw`mt-md`}
        placeHolder={t('l.typeNewPasswordAgain')}
        onChangeText={setConfirmNewPassword}
      />
      <Button
        style={tw`mt-lg`}
        size={ButtonSize.Small}
        variant={ButtonVariant.DangerLight}
        label={t('l.changePassword')}
        onPress={() => changePassword(oldPassword, newPassword, confirmNewPassword)}
      />
    </BaseModal>
  )
}
