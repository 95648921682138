import { produce } from 'immer'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { MenuEntityService } from '../api/employee/MenuEntityService'
import { RestaurantEntityService } from '../api/employee/RestaurantEntityService'
import { Restaurant } from '../types'
import { deepEquals } from '../utils/commonHelpers'

interface RestaurantState {
  restaurant?: Restaurant
}

interface RestaurantStore extends RestaurantState {
  fetchRestaurant: (restaurantId: string) => Promise<Restaurant>
  setRestaurant: (restaurant: Restaurant) => void
  clearStore: () => void
}

const initialState: RestaurantState = {
  restaurant: undefined,
}

export const useRestaurantStore = create<RestaurantStore>()(
  persist(
    (set, get) => ({
      ...initialState,
      async fetchRestaurant(restaurantId) {
        const { restaurant } = get()
        const restaurantInfo = await RestaurantEntityService.getById(restaurantId)
        const menus = await MenuEntityService.getByRestaurantId(restaurantId)
        restaurantInfo.menus = menus
        if (!restaurant || !deepEquals(restaurant, restaurantInfo)) set({ restaurant: restaurantInfo })
        return restaurantInfo
      },
      setRestaurant: newRestaurant => {
        set(
          produce((state: RestaurantState) => {
            if (!state.restaurant) return
            const newState = { ...newRestaurant }
            newState.menus = state.restaurant.menus
            state.restaurant = newState
          })
        )
      },
      clearStore: () => set(initialState),
    }),
    {
      name: 'meamenumanager.restaurant',
      storage: createJSONStorage(() => localStorage),
    }
  )
)
