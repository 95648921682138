import { t } from 'i18next'
import { MeaError, Recipe } from '../../types'
import { backendClient, noResponse } from '../HTTPClient'
import { TranslatableEntityService } from '../TranslatableEntityService'

export type GetMenuRecipesResponse = { restaurantRecipes: Recipe[]; systemRecipes: Recipe[] }

export class RecipeEntityService extends TranslatableEntityService<Recipe>() {
  static entityPath: string = 'recipe'

  static async getForMenu(filters: {
    restaurantId: string
    menuId: string
    txtFilter?: string
    includeAlreadyIn: boolean
    limit?: number
    offset?: number
  }): Promise<GetMenuRecipesResponse> {
    const response = await backendClient.post(`${this.entityPath}/getForMenu`, filters)
    if (noResponse(response)) {
      throw new MeaError('errorFetchingData', t('errors.errorFetchingData'))
    }
    return response.data
  }

  static setAvailability(restaurantId: string, recipeId: string, available: boolean): Promise<Recipe> {
    return this.update(recipeId, { restaurantId, available, _id: recipeId })
  }

  static async forceDelete(recipeId: string): Promise<Recipe> {
    const response = await backendClient.delete(`${this.entityPath}/force/${recipeId}`)
    if (noResponse(response)) {
      throw new MeaError('errorUpdatingData', t('errors.errorUpdatingData'))
    }
    return response.data
  }
}
