import { Button, ButtonSize, ButtonVariant, IconNames, useTw } from '@mea-menu/components'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, Text, View } from 'react-native'
import { Screen, SearchBar } from '../components'
import { MeaTooltip } from '../components/core/tooltip/MeaTooltip'
import { RecipeRow } from '../components/RecipeRow'
import { RecipeStackScreenProps } from '../navigation'
import { SortingType, useRecipeStore } from '../store/recipeStore'
import { TooltipOrderRecipeTypes } from '../types'
import { getNextStringLiteralEnumValue } from '../types/utils'
import { meaErrorHandler } from '../utils/MeaErrorHandler'

export function RecipesScreen({ navigation, route }: RecipeStackScreenProps<'RecipesScreen'>) {
  const { tw } = useTw()
  const { fetchRecipes, getFilteredRecipes, recipes } = useRecipeStore()
  const { t, i18n } = useTranslation()
  const [searchFilter, setSearchFilter] = useState('')
  const [sorting, setSorting] = useState<SortingType>(SortingType.updateDate)
  const [isAscending, setIsAscending] = useState(false)

  useEffect(() => {
    const getRecipes = async () => {
      try {
        fetchRecipes()
      } catch (error) {
        meaErrorHandler(error, 'FETCH')
      }
    }
    getRecipes()
  }, [])

  const filteredRecipes = useMemo(
    () => getFilteredRecipes(searchFilter, sorting, { isAscending: isAscending, type: sorting }),
    [recipes, searchFilter, i18n.language, isAscending, sorting]
  )

  return (
    <Screen padded>
      <FlatList
        style={tw`p-md`}
        showsVerticalScrollIndicator={false}
        keyExtractor={item => `${item._id}${sorting}${isAscending}`}
        ListHeaderComponent={
          <View>
            <View style={tw`flex-row justify-between`}>
              <Text style={tw`textMono title2`}>{t('l.recipes')}</Text>
              <MeaTooltip
                uniqueId={TooltipOrderRecipeTypes.RECIPE_CREATE_ICON}
                text={t('tooltips.createRecipe')}
                position="bottom"
              >
                <Button
                  label={t('l.add')}
                  icon="Plus"
                  size={ButtonSize.Small}
                  onPress={() => navigation.navigate('RecipeEditorScreen', {})}
                  variant={ButtonVariant.SecondaryLight}
                />
              </MeaTooltip>
            </View>
            <View style={tw`flex-row mt-sm justify-between`}>
              <SearchBar onTextChange={setSearchFilter} value={searchFilter} />
              <MeaTooltip
                uniqueId={TooltipOrderRecipeTypes.RECIPE_SCREEN_CHANGE_ORDER}
                text={t('tooltips.changeRecipesOrder')}
                position="left"
              >
                <Button
                  icon={sorting === SortingType.alphabetical ? IconNames.Language : IconNames.Clock}
                  label={isAscending ? '↑' : '↓'}
                  style={tw`ml-sm`}
                  iconToTheLeft
                  size={ButtonSize.Medium}
                  variant={ButtonVariant.Ghost}
                  onPress={() => {
                    if (sorting === SortingType.alphabetical) setIsAscending(!isAscending)
                    const next = getNextStringLiteralEnumValue(SortingType, sorting, true)
                    if (next) setSorting(next)
                  }}
                />
              </MeaTooltip>
            </View>
          </View>
        }
        data={filteredRecipes}
        extraData={[sorting, isAscending]}
        renderItem={({ item }) => (
          <RecipeRow
            recipe={item}
            onEditPress={() => navigation.navigate('RecipeEditorScreen', { recipeId: item._id })}
          />
        )}
      />
    </Screen>
  )
}
