import * as Localization from 'expo-localization'
import i18n, { ModuleType } from 'i18next'
import { initReactI18next } from 'react-i18next'
import de from './de.json'
import en from './en.json'
import es from './es.json'
import fr from './fr.json'
import it from './it.json'
import ja from './ja.json'
import ru from './ru.json'
import zh from './zh.json'

const resources = { en, it, zh, de, fr, es, ru, ja }

export const availableLanguages = Object.keys(resources)

export const LOCALE_PERSISTENCE_KEY = 'language'

const languageDetector = {
  type: 'languageDetector' as ModuleType,
  async: true,
  detect: async (language: any) => {
    const persistedLocale = localStorage.getItem(LOCALE_PERSISTENCE_KEY)
    if (!persistedLocale) {
      return language(Localization.locale.substring(0, 2))
    }
    language(persistedLocale)
  },
  init: () => {},
  cacheUserLanguage: (locale: any) => {
    localStorage.setItem(LOCALE_PERSISTENCE_KEY, locale)
  },
}

i18n.use(languageDetector).use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  // lng: 'en',
  nsSeparator: '.',
  keySeparator: ':',
  defaultNS: 'en',
  ns: availableLanguages,
  compatibilityJSON: 'v3',
  appendNamespaceToMissingKey: false,
})

declare module 'react-i18next' {
  interface CustomTypeOptions {
    defaultNS: 'en'
    resources: typeof en
    nsSeparator: '.'
  }
}
