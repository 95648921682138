import { colors, useTw } from '@mea-menu/components'
import { BlurView } from 'expo-blur'
import { ActivityIndicator, Text } from 'react-native'
import { SCREEN_AVAILABLE_HEIGHT } from '../theme/sizes'

export const LoadingIndicator = ({
  visible,
  label,
  blurIntensity,
}: {
  visible: boolean
  label?: string
  blurIntensity?: number
}) => {
  const { tw } = useTw()

  if (!visible) return null
  return (
    <BlurView
      style={tw`absolute top-0 h-[${SCREEN_AVAILABLE_HEIGHT}px] right-0 left-0 justify-center items-center z-10`}
      intensity={blurIntensity !== undefined ? blurIntensity : 50}
    >
      <ActivityIndicator size={'large'} color={colors.orange[400]} />
      {label && <Text style={tw`mt-md textMono text-lg`}>{label}</Text>}
    </BlurView>
  )
}
