import { BaseModal, Button, ButtonVariant, useTw } from '@mea-menu/components'
import { useTranslation } from 'react-i18next'
import { Text, TextInput, View } from 'react-native'
import { TimeSlice } from '../../types'

const TimeSlotTextInput = ({
  value,
  onChange,
  type,
}: {
  value: string
  onChange: (newValue: string) => void
  type: 'hours' | 'minutes'
}) => {
  const { tw, theme } = useTw()

  const cleanValue = (value: string) => {
    let result = Number(value.substring(0, 2).replace(/\D/g, '')) // max. 2 chars, only numbers
    if (type === 'hours' && result > 23) {
      result = 23
    } else if (type === 'minutes' && result > 59) {
      result = 59
    }
    return result
  }

  return (
    <TextInput
      style={[tw`textMono px-sm py-xs fillCard rounded-md w-[30%] mr-sm mt-xs`, { outlineStyle: 'none' }]}
      keyboardType="numeric"
      value={value}
      onChangeText={newValue => {
        onChange(cleanValue(newValue).toString())
      }}
    />
  )
}

export const AddTimeSliceModal = ({
  timeSlice,
  setTimeSlice,
  onSavePressed,
}: {
  timeSlice: TimeSlice | undefined
  setTimeSlice: (timeSlice: TimeSlice | undefined) => void
  onSavePressed: () => void
}) => {
  const { tw } = useTw()
  const { t } = useTranslation()

  return (
    <BaseModal visible={!!timeSlice} title={t('l.addTimeSlice')} onClose={() => setTimeSlice(undefined)}>
      <Text style={tw`textMono`}>{t('l.from')}</Text>
      <View style={tw`flex-row`}>
        <TimeSlotTextInput
          type="hours"
          value={timeSlice?.fromHour.toString() ?? '0'}
          onChange={newValue => {
            if (timeSlice) setTimeSlice({ ...timeSlice, fromHour: Number(newValue) })
          }}
        />
        <TimeSlotTextInput
          type="minutes"
          value={timeSlice?.fromMin.toString() ?? '0'}
          onChange={newValue => {
            if (timeSlice) setTimeSlice({ ...timeSlice, fromMin: Number(newValue) })
          }}
        />
      </View>
      <Text style={tw`mt-md textMono`}>{t('l.to')}</Text>
      <View style={tw`flex-row mb-md`}>
        <TimeSlotTextInput
          type="hours"
          value={timeSlice?.toHour.toString() ?? '0'}
          onChange={newValue => {
            if (timeSlice) setTimeSlice({ ...timeSlice, toHour: Number(newValue) })
          }}
        />
        <TimeSlotTextInput
          type="minutes"
          value={timeSlice?.toMin.toString() ?? '0'}
          onChange={newValue => {
            if (timeSlice) setTimeSlice({ ...timeSlice, toMin: Number(newValue) })
          }}
        />
      </View>
      <Button variant={ButtonVariant.Primary} onPress={onSavePressed} label={t('l.save')} />
    </BaseModal>
  )
}
