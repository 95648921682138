import { ButtonSize, ButtonVariant, IconButton, IconButtonShape, useTw } from '@mea-menu/components'
import { useState } from 'react'
import { InputLanguageModal } from '../modals/InputLanguageModal'

export const InputLanguageChooser = () => {
  const { tw } = useTw()
  const [inputLanguageModalOpen, setInputLanguageModalOpen] = useState<boolean>(false)

  return (
    <>
      <InputLanguageModal visible={inputLanguageModalOpen} onClose={() => setInputLanguageModalOpen(false)} />
      <IconButton
        style={tw`w-[40px]`}
        shape={IconButtonShape.Squircle}
        variant={ButtonVariant.Accent}
        size={ButtonSize.Small}
        onPress={() => setInputLanguageModalOpen(true)}
        icon={'Language'}
      />
    </>
  )
}
