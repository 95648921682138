import { colors, useTw } from '@mea-menu/components'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  NativeSyntheticEvent,
  StyleProp,
  Text,
  TextInput,
  TextInputKeyPressEventData,
  View,
  ViewStyle,
} from 'react-native'
import { useThemeStore } from '../../store'

export interface MeaNumberInputProps {
  label?: string
  placeHolder: string
  editable?: boolean
  initialValue?: number
  minValue?: number
  maxValue?: number
  autoFocus?: boolean
  decimals?: number
  onChangeValue: (value: number) => void
  onBlur?: () => void
  onFocus?: () => void
  style?: StyleProp<ViewStyle>
}

export const MeaNumberInput = ({
  label,
  placeHolder,
  editable = true,
  initialValue,
  minValue,
  maxValue,
  decimals,
  autoFocus,
  style,
  onChangeValue,
  onBlur,
  onFocus,
}: MeaNumberInputProps) => {
  const { tw } = useTw()
  const { colorMode } = useThemeStore()
  const castedInitialValue = useMemo(
    () => (initialValue !== undefined ? String(initialValue) : undefined),
    [initialValue]
  )
  const [value, setValue] = useState(castedInitialValue)
  const ref = useRef<TextInput>(null)

  const handleKeyPress = useCallback(
    (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      // Carattere digitato
      const char = event.nativeEvent.key

      // Espressione regolare per accettare solo numeri, punti e virgole
      const regex = /[0-9.,]/

      if (char === 'Backspace') return
      if (char === '0' && value === '0') event.preventDefault()
      if (!regex.test(char)) event.preventDefault()
      if ((char === ',' || char === '.') && (value?.includes('.') || value?.includes(','))) event.preventDefault()

      // max 2 or n decimali
      if (value?.includes('.') && value.length - value.indexOf('.') > (decimals ?? 2)) event.preventDefault()
      const valueNumber = Number(value)
      if (maxValue !== undefined && valueNumber > maxValue) event.preventDefault()
      if (minValue !== undefined && valueNumber < minValue) event.preventDefault()
    },
    [value]
  )

  return (
    <View style={style}>
      {label && <Text style={tw`textMono label`}>{label}</Text>}
      <View style={[tw`items-center p-xxs rounded-sm fillCard mt-xs`]}>
        <TextInput
          ref={ref}
          value={value !== undefined ? String(value) : undefined}
          editable={editable}
          defaultValue={value ? undefined : initialValue ? String(initialValue) : undefined}
          autoFocus={autoFocus || false}
          onBlur={onBlur}
          keyboardType="numeric"
          inputMode="numeric"
          onFocus={onFocus}
          style={[tw`textMono px-sm py-xs w-full`, { outlineStyle: 'none' }]}
          onKeyPress={handleKeyPress}
          onChangeText={text => {
            const sanitizedText = text.replace(',', '.')
            const numericValue = Number(sanitizedText)
            setValue(text.replace(',', '.'))
            onChangeValue(numericValue)
          }}
          placeholder={placeHolder}
          placeholderTextColor={colorMode === 'light' ? colors.grey[300] : colors.grey[200]}
        />
      </View>
    </View>
  )
}
