import { BaseModal } from '@mea-menu/components'
import { useTranslation } from 'react-i18next'
import { SelectLanguageFragment } from '../../fragments/SelectLanguageFragment'
import { useSessionStore } from '../../store'

export interface InputLanguageModalProps {
  visible: boolean
  onClose: () => void
}

export function InputLanguageModal({ onClose, visible }: InputLanguageModalProps) {
  const { inputLanguage, setInputLanguage } = useSessionStore()
  const { t } = useTranslation()

  return (
    <BaseModal onClose={() => onClose()} title={t('l.manageTranslations')} visible={visible}>
      <SelectLanguageFragment
        currentLanguage={inputLanguage}
        onLanguageSelected={langCode => {
          setInputLanguage(langCode)
        }}
        onClose={() => {
          onClose()
        }}
      />
    </BaseModal>
  )
}
