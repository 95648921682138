export class MeaError extends Error {
  constructor(message: string, localizedMessage?: string, fatal: boolean = false) {
    super(message)
    this.name = 'MeaError'
    // this.stack = message
    this.localizedMessage = localizedMessage
    this.fatal = fatal
  }
  localizedMessage?: string
  fatal: boolean
}

export class DomainError extends Error {
  httpStatus!: number
  internalCode!: number
  systemMessage?: string
  clientMessage?: string
  object?: any

  constructor(
    message: string,
    httpStatus: number,
    internalCode: number,
    systemMessage?: string,
    clientMessage?: string,
    object?: any
  ) {
    super(message)
    this.name = this.constructor.name
    this.httpStatus = httpStatus
    this.internalCode = internalCode
    this.systemMessage = systemMessage
    this.clientMessage = clientMessage
    this.object = object
    Error.captureStackTrace(this, this.constructor)
  }
}

export enum NotAllowedCode {
  RECIPE_HAS_DISHES = 4217,
  DISH_NOT_AVAILABLE = 4221,
  EXPIRED_ORDER = 4222,
  SUBMIT_NOT_DRAFT_ORDER = 4223,
}

export enum NotAuthorizedCode {
  INVALID_CREDENTIALS = 4101,
  INVALID_TOKEN = 4102,
  REFRESH_TOKEN_FAIL = 4106,
  SUDO_ONLY = 4108,
  FORCE_RESET_PASSWORD = 4111,
  CANNOT_DELETE_DISH_CATEGORY = 4226,
}

export enum AppFeature {}
// put here possible employee capabilities

export enum MeaNotificationType {
  CONFIRM,
  ALERT,
  NO_BUTTONS,
}

export type LanguageLink = {
  langName: string
  langFlag: string
  langCodes: string[]
}

export interface MeaBaseNotification {
  type: MeaNotificationType
  title: string
  description: string
  imageSource?: any
  confirmText?: string
  cancelText?: string
  onConfirm?: () => void
  onCancel?: () => void
}

export interface MeaNotification extends MeaBaseNotification {
  type: MeaNotificationType.ALERT | MeaNotificationType.CONFIRM | MeaNotificationType.NO_BUTTONS
}

export interface TranslatableName {
  nameEn: string
  nameIt: string
  nameEs: string
  nameFr: string
  nameDe: string
  nameRu: string
  nameZh: string
  nameJa: string
}

export interface TranslatableDescription {
  descriptionEn: string
  descriptionIt: string
  descriptionEs: string
  descriptionFr: string
  descriptionDe: string
  descriptionRu: string
  descriptionZh: string
  descriptionJa: string
}

export interface TranslatableCaption {
  captionEn: string
  captionIt: string
  captionEs: string
  captionFr: string
  captionDe: string
  captionRu: string
  captionZh: string
  captionJa: string
}

export type Restaurant = Partial<TranslatableDescription> & {
  _id: string
  name: string
  coverPrice: number
  priceCurrency: string
  cover?: Media
  menus: MenuPreview[]
  lat?: number
  lng?: number
  address?: string
  descriptionIt?: string
  descriptionEn?: string
  descriptionZh?: string
  email: string
  phone: string
  coverId: string
  status: string
  socialInfo: SocialInfo
}

export type SocialInfo = {
  websiteUrl?: string
  instagramUrl?: string
  facebookUrl?: string
  tripAdvisorUrl?: string
}

export type Recipe = Partial<TranslatableName> &
  Partial<TranslatableDescription> & {
    _id: string
    coverId?: string | null
    ingredientsId: string[]
    allergens: number
    system?: boolean
    restaurantId?: string
    parentId?: string
    likes?: number
    labels?: string[]
    // transient
    parent?: Recipe
    ingredients?: Ingredient[]
    cover?: Media
    /**
     * A key value object which maps an ingredient id to its (optional) brand.
     * Each key must be an id included in the ingredientsId array.
     */
    ingredientBrandMapping?: { [key: string]: string }
    available?: boolean
    updatedAt?: 'string'
  }

export enum RestaurantStatuses {
  EMAIL_PENDING = 'emailpending',
  DRAFT = 'draft',
  ACTIVE = 'active',
  BANNED = 'banned',
}

export const specialDishesKeys = ['favouriteDishes', 'featuredDishes', 'mustTryDishes', 'trendingDishes']

export type DishCategoryMap = { [dishCatKey: string]: DishCategory }
export type CategoryNodeMap = { [dishCatKey: string]: GroupedDishes }

export type IngredientsMap = { [ingredientId: string]: Ingredient }

export interface GroupedDishes {
  [categoryKey: string]: Dish[]
}

export interface SpecialDishesSectionData {
  title: string
  data: Dish[]
}

export interface SectionHeader {
  categoryKey: string
  hasDescription: boolean
  descriptionImageUrl?: string
}

export type MenuPreview = Partial<TranslatableName> &
  Partial<TranslatableDescription> & {
    _id: string
    restaurantId: string
    showInPriceList: boolean
    userCanOrder: boolean
    allYouCanEat?: boolean
    highlight?: boolean
    price?: number
    allDay: boolean
    timeSlices?: TimeSlice[]
    status?: MenuStatus
    akinatorEnabled: boolean
  }

export type Menu = Partial<TranslatableName> &
  Partial<TranslatableDescription> & {
    _id: string
    restaurantId: string
    showInPriceList: boolean
    userCanOrder: boolean
    allYouCanEat?: boolean
    highlight?: boolean
    price?: number
    allDay: boolean
    timeSlices?: TimeSlice[]
    status?: MenuStatus
    dishes: Dish[]
    akinatorEnabled: boolean
    dishCategories: DishCategoryMap
    ingredients: { [ingredientId: string]: Ingredient }
    unavailableRecipeIds: string[]
    recipeLikesMap: { [recipeId: string]: number }
  }

export enum UserEditableMenuStatus {
  ACTIVE = 'active',
  HIDDEN = 'hidden',
}

export enum AdminMenuStatus {
  DRAFT = 'draft',
}

export const MenuStatus = {
  ...UserEditableMenuStatus,
  ...AdminMenuStatus,
}

export type MenuStatus = UserEditableMenuStatus | AdminMenuStatus

export type TimeSlice = {
  _id?: string
  fromHour: number
  toHour: number
  fromMin: number
  toMin: number
}

export type Intolerance = TranslatableName & {
  _id: string
  value: number
  imageUri: any
}

export type Dish = Partial<TranslatableCaption> &
  Partial<TranslatableDescription> & {
    _id: string
    recipeId: string
    recipe: Recipe
    price?: number
    menuId?: string
    availability: number
    maxPerUser?: number
    defaultSort: number
    dishCategory: string //key
    dishSubCategory?: string //key
    number?: string
    restaurantId?: string
    suggested?: boolean
  }

export type DishCategory = Partial<TranslatableName> &
  Partial<TranslatableDescription> & {
    _id: string
    restaurantId?: string // mandatory if "system === false"
    key: string
    imageUrl?: string
    system: boolean
    parentId?: string
  }

export type Ingredient = Partial<TranslatableName> & {
  _id: string
  cover: Media
  allergens: number
  categoriesId?: string[]
}

export type Media = {
  _id: string
  url: string
  mediakey?: string
  name?: string
  mimetype?: string
  entity?: string
  entityId?: string
}

export class Currency {
  price: number
  priceCurrency: string
  constructor(price: number, priceCurrency: string) {
    this.price = price
    this.priceCurrency = priceCurrency
  }
}

export type ViewSize = {
  width: number
  height: number
}

export interface GetMenuIngredientsResponse {
  [ingredientId: string]: Ingredient
}

export interface Employee {
  _id: string
  restaurantId: string
  status: string
  email: string
  firstname: string
  lastname: string
}

export interface EmployeeLogin {
  accessToken: string
  refreshToken: string
  employee: Employee
}

export interface RefreshTokenResponse {
  accessToken: string
  refreshToken: string
}

export interface MeaMedia {
  _id: string
  name: string
  mimetype: string
  url: string
  mediakey: string
  createdAt: string
  updatedAt: string
  id: string
}

export enum RestaurantCurrencies {
  EUR = 'EUR',
  USD = 'USD',
  GBP = 'GBP',
}

export enum TooltipContextTypes {
  SPECIAL = 'special',
  MENU = 'menu',
  RECIPE = 'recipe',
  RESTAURANT = 'restaurant',
  MANAGE_MENU_SCREEN = 'manage_menu_screen',
}

export enum TooltipOrderSpecialTypes {
  SETTINGS = 'settings',
  TRANSLATE_SETTINGS_ICON = 'translate_settings_icon',
}

export enum TooltipOrderMenuTypes {
  MENU_CREATE = 'menu_create',
  MENU_OPTIONS = 'menu_options',
  MENU_ALWAYS_AVAILABLE = 'menu_always_available',
}

export enum TooltipOrderRecipeTypes {
  RECIPE_CREATE_ICON = 'recipe_create_icon',
  RECIPE_SCREEN_CHANGE_ORDER = 'recipe_screen_change_order',
  RECIPE_ADD_IMAGE = 'recipe_add_image',
  RECIPE_ADD_INGREDIENT = 'recipe_add_ingredient',
  RECIPE_REMOVE_INGREDIENT = 'recipe_remove_ingredient',
}

export enum TooltipOrderManageMenuScreenTypes {
  CATEGORY_ADD = 'category_add',
  CATEGORY_RENAME = 'category_rename',
  SUBCATEGORY_ADD = 'subcategory_add',
  DISH_ADD = 'dish_add',
  DISH_IS_SUGGESTED = 'dish_is_suggested',
  ORDER_DISHES_CATSUBCAT = 'order_dishes_catsubcat',
}

export enum TooltipOrderRestaurantTypes {
  RESTAURANT_INFO = 'restaurant_info',
  CONTACT_INFO = 'contact_info',
  CHANGE_PASSWORD = 'change_password',
}

export const TOOLTIP_TYPES = {
  [TooltipContextTypes.SPECIAL]: TooltipOrderSpecialTypes,
  [TooltipContextTypes.MENU]: TooltipOrderMenuTypes,
  [TooltipContextTypes.RECIPE]: TooltipOrderRecipeTypes,
  [TooltipContextTypes.MANAGE_MENU_SCREEN]: TooltipOrderManageMenuScreenTypes,
  [TooltipContextTypes.RESTAURANT]: TooltipOrderRestaurantTypes,
}

export const TOOLTIP_ORDER: string[] = Object.values(TooltipContextTypes).reduce<string[]>(
  (acc, type) => acc.concat(Object.values(TOOLTIP_TYPES[type])),
  []
)
