import { produce } from 'immer'
import { create } from 'zustand'
import { LOCALE_PERSISTENCE_KEY } from '../localization'
import { LANGUAGES } from '../utils/commonHelpers'

/**
 * Session Store should be used for non-persistant app-global state.
 * It will be deleted on app reload.
 */
interface SessionState {
  appInitialized: boolean
  inputLanguage: string
}

interface SessionStore extends SessionState {
  getAllOtherInputLanguages: () => string[]
  setInputLanguage: (language: string) => void
  clearInputLanguage: () => void
  clearStore: () => void
  setAppInitialized: (value: boolean) => void
}

const defaultLanguage = localStorage.getItem(LOCALE_PERSISTENCE_KEY) ?? 'en'
const initialState: SessionState = { inputLanguage: defaultLanguage, appInitialized: false }

export const useSessionStore = create<SessionStore>()((set, get) => ({
  ...initialState,
  getAllOtherInputLanguages: () => {
    const currentInputLang = get().inputLanguage
    return Object.keys(LANGUAGES).filter(l => l !== currentInputLang)
  },
  setInputLanguage: language => {
    set({ inputLanguage: language })
  },
  clearInputLanguage: () => {
    set(
      produce((state: SessionState) => {
        state.inputLanguage = defaultLanguage
      })
    )
  },
  setAppInitialized: value => {
    set(
      produce((state: SessionState) => {
        state.appInitialized = value
      })
    )
  },
  clearStore: () => set(initialState),
}))
